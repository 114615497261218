import React from "react";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { MembersService } from "services";
import { withRouter } from "react-router-dom";
import translate from "i18nProvider/translate";
import PropTypes from "prop-types";

const CustomAutocomplete = ({
  selectedItem,
  setSelectedItem,
  stateMembers,
  dispatch,
  match,
  options = [],
  label = null,
  name = "",
  optionLabel = "",
  disabled = false
}) => {
  const CHURCH_ID = match.params.churchId;

  const [members, setMembers] = React.useState([]);
  const [invalidMember, setIvalidMember] = React.useState(false);
  const defaultProps = {
    options: options.length > 0 ? options : members,
    getOptionLabel: (option) =>
      optionLabel
        ? option[optionLabel]
        : option.firstName + " " + option.lastName,
  };

  const handleInvalidSelect = (e) => {
    if (e.target.name === name) setIvalidMember(true);
    e.target.setCustomValidity(translate("selectItem"));
  };

  const fetchMembers = React.useCallback(() => {
    if (stateMembers.length <= 0 && options.length <= 0)
      dispatch(new MembersService(CHURCH_ID).getMembersByChurchId());
  }, [CHURCH_ID, dispatch, stateMembers, options]);

  React.useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  React.useEffect(() => {
    setMembers(stateMembers);
  }, [stateMembers]);

  return (
    <Autocomplete
      {...defaultProps}
      value={selectedItem}
      onChange={(event, newValue) => {
        setSelectedItem(newValue);
        setIvalidMember(false);
      }}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          label={translate(label ? label : "member")}
          variant="outlined"
          margin="dense"
          required
          name={name}
          disabled={disabled}
          onInvalid={handleInvalidSelect}
          onSelect={(event) => {
            if (!invalidMember) {
              event.target.setCustomValidity("");
            }
          }}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => ({
  stateMembers: state.members,
});

CustomAutocomplete.propTypes = {
  options: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

export default withRouter(connect(mapStateToProps)(CustomAutocomplete));
