import React, { useState, useEffect } from "react";
import { ProgramDetail } from "models";
import translate from "i18nProvider/translate";
import { ProgramsService } from "services";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  DialogTitle,
  Box,
} from "@material-ui/core";

import { connect } from "react-redux";
import { showAlertSuccess } from "redux/slices/alertsSlice";
import store from "redux/store";
import { Confirm, Select } from "components";

const CreateEditProgramDetail = ({
  open,
  setOpen,
  detailToEdit = new ProgramDetail(),
  addCreatedToDetailsList,
  addEditedToDetailsList,
  programParts = [],
  programId,
  match,
  members = [],
  details = [],
}) => {
  const programService = new ProgramsService(match.params.churchId);
  const GROUP_ID = match.params.groupId;
  const [programDetail, setProgramDetail] = useState(detailToEdit);
  const [memberList, setMemberList] = useState([{ id: "", name: "" }]);
  useState(() => {
    const list = members.map((member) => ({
      id: member.id,
      name: member.fullName,
    }));
    setMemberList(list);
  }, [members]);

  const handleChange = (e) => {
    setProgramDetail({
      ...programDetail,
      [e.target.name]: e.target.value,
    });
  };
  const handleMemberChange = (e) => {
    setProgramDetail({
      ...programDetail,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    if (detailToEdit.id) {
      editProgramDetail();
    } else {
      createProgramDetails();
    }
    setOpen(false);
  };

  const editProgramDetail = async () => {
    const programPart = programParts.find(
      (part) => part.id === Number(programDetail.programPartId)
    );
    const memberId = members.find(
      (member) => member.id === Number(programDetail.memberId)
    );
    if (programDetail.id > 0) {
      try {
        const created = await programService.editChurchProgramDetail(
          programId,
          programDetail
        );
        addEditedToDetailsList({
          ...created,
          programPartId: programPart,
          memberId: { id: memberId.id, name: memberId.fullName },
        });
        store.dispatch(showAlertSuccess(translate("programDetailSuccEdited")));
      } catch (error) {
        console.log(error);
      }
    } else {
      addEditedToDetailsList({
        ...programDetail,
        programPartId: programPart,
        memberId: { id: memberId.id, name: memberId.fullName },
      });
    }
  };

  const doesDetailAlreadyExist = async () => {
    const detail = details.find((detail) => {
      return (
        detail.programPartId.id === Number(programDetail.programPartId) &&
        detail.memberId.id === Number(programDetail.memberId)
      );
    });

    if (detail) {
      await Confirm.show({
        message: translate("detailAlreadyExist"),
        alertType: true,
      });
      return true;
    }
    return false;
  };

  const createProgramDetails = async () => {
    if (await doesDetailAlreadyExist()) return;
    try {
      if (!programId) {
        const randomNumberId = -Math.random();
        const programPart = programParts.find(
          (part) => part.id === Number(programDetail.programPartId)
        );
        const memberId = members.find(
          (member) => member.id === Number(programDetail.memberId)
        );
        if (programPart)
          addCreatedToDetailsList({
            ...programDetail,
            programPartId: programPart,
            memberId: { id: memberId.id, name: memberId.fullName },
            id: randomNumberId,
          });
      } else {
        let created;
        if (GROUP_ID) {
          created = await programService.createGroupProgramDetail(
            GROUP_ID,
            programId,
            programDetail
          );
        } else {
          created = await programService.createChurchProgramDetail(
            programId,
            programDetail
          );
        }

        const programPart = programParts.find(
          (part) => part.id === Number(programDetail.programPartId)
        );
        const memberId = members.find(
          (member) => member.id === Number(programDetail.memberId)
        );
        addCreatedToDetailsList({
          created,
          programPartId: programPart,
          memberId: { id: memberId.id, name: memberId.fullName },
          id: created.id
        });
        store.dispatch(showAlertSuccess(translate("programDetailSuccCreated")));
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const formatDetailToEdit = React.useCallback(() => {
    const { programPartId, memberId } = detailToEdit;
    const programDetail = {
      programPartId: programPartId.id,
      memberId: memberId.id,
    };
    setProgramDetail({ ...programDetail, id: detailToEdit.id });
  }, [detailToEdit]);

  useEffect(() => {
    if (detailToEdit.id) {
      formatDetailToEdit();
    }
  }, [formatDetailToEdit, detailToEdit.id]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box style={{ minWidth: "280px" }}>
        <DialogTitle id="alert-dialog-title">
          {detailToEdit.id
            ? translate("editProgramDetail")
            : translate("addProgramDetail")}
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <Select
              label="programPart"
              name="programPartId"
              onChange={handleChange}
              required
              value={programDetail.programPartId}
              options={programParts}
            />
          </Box>
          <Box mt={2}>
            <Select
              label="Member"
              name="memberId"
              onChange={handleMemberChange}
              required
              value={programDetail.memberId}
              options={memberList}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {translate("cancel")}
          </Button>
          <Button
            color="primary"
            disabled={
              programDetail.memberId === "" ||
              programDetail.programPartId === ""
            }
            autoFocus
            onClick={handleSubmit}
          >
            {detailToEdit.id ? translate("edit") : translate("add")}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const mapStateToProps = (state) => ({
  members: state.members,
});

export default withRouter(connect(mapStateToProps)(CreateEditProgramDetail));
