import jwtDecode from "jwt-decode";
const TOKEN_NAME = "churchJwt";
const REFRESH_TOKEN_NAME = "churchRefreshJwt";

export const saveToken = (token) => {
  sessionStorage.setItem(TOKEN_NAME, token);
};

export const decodeToken = (token) => {
  if (token) {
    try {
      return jwtDecode(token);
    } catch (error) {
      removeToken();
      return null;
    }
  }
  return null;
};

export const getToken = () => {
  return sessionStorage.getItem(TOKEN_NAME);
};

export const removeToken = () => {
  sessionStorage.removeItem(TOKEN_NAME);
};

export const saveRefreshToken = (refreshToken) => {
  sessionStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
};

export const getRefreshToken = () => {
  return sessionStorage.getItem(REFRESH_TOKEN_NAME);
};

export const removeRefreshToken = () => {
  sessionStorage.removeItem(REFRESH_TOKEN_NAME);
};

export const updateTokens = (data) => {
  saveToken(data.access_token);
  saveRefreshToken(data.refresh_token);
};