import React from "react";
import { Route } from "react-router-dom";
import PropTypes from "prop-types";
// import { ShowAlert, Loading } from "components";
// import { I18nPropvider } from "i18nProvider";
// import { connect } from "react-redux";

const RouteWithLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.string,
};

// const mapStateToProps = (state) => ({
//   language: state.language,
// });

export default RouteWithLayout;
