import { createSlice } from "@reduxjs/toolkit";
import { LOCALES } from "i18nProvider";

const languageSlice = createSlice({
  name: "language",
  initialState: LOCALES.SPANISH,
  reducers: {
    setLanguage(state, action) {
      return action.payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export default languageSlice.reducer;
