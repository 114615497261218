import { createSlice } from "@reduxjs/toolkit";

const rolesSlice = createSlice({
  name: "roles",
  initialState: [],
  reducers: {
    addRoles(state, action) {
      return action.payload;
    },
  },
});

export const { addRoles } = rolesSlice.actions;

export default rolesSlice.reducer;
