import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import UsersToolbar from "../UsersToolbar";

import { DynamicTable, Confirm } from "components";
import { User } from "models";
import { UsersService } from "services";
import { injectIntl } from "react-intl";
import translate from "i18nProvider/translate";
import store from "redux/store";
import { showAlertSuccess } from "redux/slices/alertsSlice";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      //  width: "90%",
      maxWidth: "1600px",
      margin: "0 auto",
    },
  },
}));

const headers = ["firstname", "lastname", "personalId", "email", "roles"];
const fields = ["firstName", "lastName", "personalId", "email", "roles"];

const RequestedUsers = injectIntl(({ churchId, roles = [] }) => {
  const classes = useStyles();

  const [user, setUser] = useState(new User());
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [usersToFilter, setUsersToFilter] = useState([]);
  const [deleteMode, setDeleteMode] = useState(false);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await new UsersService(
          churchId
        ).getUsersRequestsByChurchId();
        setFetchedUsers(users);
        setUsersToFilter(users);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUsers();
  }, [churchId]);

  const handleRowClick = (userr) => {
    let same = user.id === userr.id;
    setUser(same ? new User() : userr);
    setDeleteMode(!same);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleClickDeleteRequest = async () => {
    const result = await Confirm.show({
      message: translate("confirmDeleteUserRequest"),
    });
    if (!result) return;
    if (user.id) {
      try {
        await new UsersService(churchId).deleteUserRequest(user.id);
        refreshDataAfterDelete(user.id);
        store.dispatch(
          showAlertSuccess(translate("userRequestSuccesfullyDeleted"))
        );
        setDeleteMode(false);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const refreshDataAfterDelete = (id) => {
    const filteredUsers = [...usersToFilter].filter((element) => {
      return element.id !== id;
    });
    setFetchedUsers(filteredUsers);
    setUsersToFilter(filteredUsers);
  };

  return (
    <Grid container className={classes.content}>
      <UsersToolbar
        dataToSearch={usersToFilter}
        setDataOnSearch={setFetchedUsers}
        keysToSearch={fields}
        showDelete={deleteMode}
        onDeleteBtnClicked={handleClickDeleteRequest}
      />

      <DynamicTable
        data={fetchedUsers}
        headers={headers}
        fields={fields}
        selectedItem={user}
        count={fetchedUsers.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRowClick={handleRowClick}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
    </Grid>
  );
});

export default RequestedUsers;
