import {
  NotFoundError,
  NOT_FOUND_ERROR_CODE,
  ValidationError,
  UnexpectedError,
  UNEXPECTED_ERROR_CODE,
  InvalidCredentialsError,
  UNAUTHORIZED_ERROR_CODE,
  UnauthorizedError,
  FORBIDDEN_ERROR_CODE,
  NOT_ACCEPTABLE_ERROR_CODE,
  NotAcceptableError,
} from "./errors";

export const handleJsonResponse = (response) => {
  // console.log('response.status = ' + response.status);
  if (response.status === NOT_FOUND_ERROR_CODE) {
    return Promise.reject(new NotFoundError());
  }

  if (!response.ok) {
    return response.json().then((json) => {
      const errorMessage = json.error.message || "Server error";
      const messageType = json.messageType;
      console.log("ERROR JSON: ", JSON.stringify(json));

      if (json.error && json.error === "invalid_grant") {
        return Promise.reject(new InvalidCredentialsError(json.error));
      }

      let error;

      console.log("Status: ", response.status);

      if (
        response.status === UNAUTHORIZED_ERROR_CODE ||
        response.status === FORBIDDEN_ERROR_CODE
      ) {
        error = new UnauthorizedError(errorMessage, messageType);
        return Promise.reject(error);
      }

      if (response.status === NOT_ACCEPTABLE_ERROR_CODE) {
        error = new NotAcceptableError(errorMessage, messageType);
        return Promise.reject(error);
      }

      if (response.status !== UNEXPECTED_ERROR_CODE) {
        // FIXME: double check condition
        error = new ValidationError(errorMessage, messageType);
        return Promise.reject(error);
      }

      error = new UnexpectedError(errorMessage, messageType);
      return Promise.reject(error);

      // throw new Error(errorMessage);
      return Promise.reject(new UnexpectedError(errorMessage));
    });
  }
  return response.json();
};

export const handleTextResponse = (response) => {
  // console.log('response.status = ' + response.status);
  if (response.status === NOT_FOUND_ERROR_CODE) {
    let error = new NotFoundError();
    return Promise.reject(error);
  }

  // status 404 or 500 will set ok to false
  if (!response.ok) {
    // although the frontEnd expects a plain string, if serverSide error ocurrs, a json with a description of the error is being sent
    return response.json().then((json) => {
      var errorMessage =
        json.rootErrorMessage || json.message || "Server error";
      console.error(json.stackTrace);
      return Promise.reject(new UnexpectedError(errorMessage));
    });
  }
  return response.text();
};

export const parseError = (response) => {
  let error;

  const errorMessage = response.message || "Server error";
  const messageType = response.messageType || "error";

  console.log("Status: ", response.status);

  if (
    response.status === UNAUTHORIZED_ERROR_CODE ||
    response.status === FORBIDDEN_ERROR_CODE
  ) {
    error = new UnauthorizedError(errorMessage, messageType);
    return Promise.reject(error);
  }

  if (response.status === NOT_ACCEPTABLE_ERROR_CODE) {
    error = new NotAcceptableError(errorMessage, messageType);
    return Promise.reject(error);
  }

  if (response.status !== UNEXPECTED_ERROR_CODE) {
    // FIXME: double check condition
    error = new ValidationError(errorMessage, messageType);
    return Promise.reject(error);
  }

  error = new UnexpectedError(errorMessage, messageType);
  return Promise.reject(error);
};
