import React, { forwardRef } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { Slide } from "@material-ui/core";
import {
  Card,
  CardHeader,
  CardContent,
  // CardActions,
  Button,
  Dialog,
  Divider,
  AppBar,
  Toolbar,
  IconButton,
  Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: "auto",
    margin: "100px auto",
    width: "90%",
    maxWidth: "1300px",
  },

  appBar: {
    position: "fixed",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullScreenDialog = (props) => {
  const {
    title,
    subHeader,
    open,
    handleClose,
    children,
    className,
    onSubmit,
    saveBtnLabel,
    actionsDisabled = false,
  } = props;

  const classes = useStyles();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <form onSubmit={handleSubmit}>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.title}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {!actionsDisabled && (
              <Button
                disabled={actionsDisabled}
                autoFocus
                color="inherit"
                type="submit"
              >
                {saveBtnLabel}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Card className={clsx(classes.root, className)}>
          <CardHeader
            subheader={subHeader}
            title={
              <Box style={{ fontSize: "16px", fontWeight: "500" }}>
                {title}
              </Box>
            }
          />
          <Divider />
          <CardContent className={classes.cardContent}>{children}</CardContent>
        </Card>
      </form>
    </Dialog>
  );
};

FullScreenDialog.propTypes = {
  transition: PropTypes.any,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  className: PropTypes.any,
  onSubmit: PropTypes.func,
  saveBtnLabel: PropTypes.string,
  title: PropTypes.string,
  subHeader: PropTypes.string,
  actionsDisabled: PropTypes.bool,
  children: PropTypes.any,
};

export default FullScreenDialog;
