import { LOCALES } from "../constants";

export default {
  [LOCALES.ENGLISH]: {
    //home
    addNewChurch: "Add Church",
    noResourcesFound: "A role hasn't been assigned to you yet.",
    refresh: "Refresh",
    contactLeader: "Please, get in touch with your leader in order to get your role assigned",

    //signIn
    username: "Username",
    password: "Password",
    signIn: "Sign In",
    forgotPassword: "Forgot Password?",
    signUp: "Don't have an account? Sign Up",

    //create-account
    completeChurchInfo: "Complete your church info",
    completePersonalInfo: "Complete your personal information",
    completePaymentMethod: "Complete your payment method",
    churchInfo: "Church Information",
    personalInfo: "Personal Information",
    paymentInfo: "Payment Information",
    cardNumber: "Card Number",
    expirationDate: "Expiration Date",
    cvv: "CVV",
    cardInformation: "Card information",
    country: "Country",
    zipCode: "Zip Code",
    confirmPassword: "Confirm Password",
    confirmationLinkSent: "We have sent a confirmation link to your email",
    submit: "Submit",
    emailAlreadyHasUserRequest:
      "This email already has an user request, check your inbox",
    bothPasswordDidNotMatch: "Passwords did not match",
    readyToSignIn: "Ready to sign in with your email and password",

    //sideBarNav
    appTitle: "Church En Linea",
    members: "Members",
    users: "Users",
    groups: "Groups",
    programs: "Programs",
    transactions: "Transactions",
    reports: "Reports",
    settings: "Settings",

    //members table
    firstname: "Firstname",
    lastname: "Lastname",
    personalId: "Personal Id",
    phone: "Phone",
    phone2: "Phone 2",
    email: "Email",
    status: "Status",
    joiningDate: "Joining Date",

    //members
    membersformTitle: "Members Form",
    identification: "Identification",
    emailAddress: "Email Address",
    phoneNumber1: "Phone number 1",
    phoneNumber2: "Phone number 2",
    baptized: "Baptized",
    baptizedDate: "Baptized Date",
    dateOfBirth: "Date of Birth",
    member: "Member",
    memberSuccessfullyCreated: "Member successfully created",
    memberSuccessfullyEdited: "Member successfully edited",
    confirmDeleteMember: "Are you sure you want to delete this member?",
    memberSuccessfullyDeleted: "Member successfully deleted!",
    memberNotDeleted: "Member couldn't be deleted",
    memberAlreadyHasUser: "This user has already been requested!",
    mustAddEmailToMember:
      "You have to add an email to this member to be able to create a user",
    memberAlreadyRegisteredWithEmail:
      "A member has been found with this E-mail",
    memberHasUserAlreadyTitle: "Member has user already",
    memberHasUserAlreadyBody:
      "This email already has an user bound to it in our platform, would you like to add it as an user in your church?",

    //UserFom
    usersFormTitle: "Users Form",
    roleRequired: "You must select at least one role",
    requestUser: "Request user",
    requestSent: "User request successfully sent",
    userConfirmationPrefix: "To request a user for ",
    userConfirmationSuffix: " you must click on Request User",

    //Users View
    registeredUsers: "Registed Users",
    requestedUsers: "Requested Users",
    toggleUserActive: "Are you sure you want to disable this user ?",
    toggleUserInactive: "Are you sure you want to enable this user ?",
    removeUserRequest: "Remove user request",
    userSuccessfullyEdited: "User successfully edited!",
    editUser: "Edit User",
    deleteUser: "Delete User",
    userSuccessfullyDeleted: "User successfully deleted!",
    confirmDeleteUser: "Are you sure you want to delete this user ?",
    confirmDeleteUserRequest:
      "Are you sure you want to delete this requested user ?",
    userRequestSuccesfullyDeleted: "User request successfully deleted!",
    deleteUserRequest: "Delete user request",
    userAddedSuccessfully: "User added successfully!",

    //Groups
    groupsFormTitle: "Group Form",
    addNewGroup: "Add New Group",
    backToMembers: "Back To Groups",
    churchMembers: "Church Members",
    removeMembers: "Remove Selected Members",
    leader: "Leader",
    specifyName: "Please specify the name",
    specifyLeader: "Please specify the Leader",
    specifyDescription: "Please specify the Description",
    groupImage: "Group Image",
    removeSelectedMembers: "Remove selected members",
    editMember: "Edit Member",
    addNewMember: "Add New Member",
    addNewMembers: "Add New Members",
    confirmRemoveMember: "Are you sure you want to remove this members ?",
    memberSuccessfullyAdded: "Member successfully added",
    groupSuccessfullyCreated: "Group seccessfully created",
    groupSuccessfullyEdited: "Group seccessfully edited",
    groupName: "Group Name",
    groupSuccessfullyDeleted: "Group successfully deleted!",
    confirmDeleteGroup: "Are you sure you want to delete this group ?",

    //transactions
    cash: "Cash",
    creditCard: "Credit Card",
    debitCard: "Debit Card",
    bankTransaction: "Bank Transaction",
    deposit: "DEPOSIT",
    tithes: "Tithes",
    offerings: "Offerings",
    donations: "Donations",
    outgoing: "Outgoing",
    amount: "Amount",
    paymentMethod: "Payment Method",
    transactionType: "Transaction Type",
    date: "Date",
    addNewTransaction: "Add New Transaction",
    transactionsForm: "Transactions Form",
    transactionId: "Transaction ID",
    specifyAmount: "Please specify the amount",
    transactionDescription: "Transaction Description",
    confirmTransaction: "Are the following details rigth ?",
    specifyPaymentMethod: "Please specify the payment method",
    specifyTransactionType: "Please specify the transaction type",
    validateTransactionDate:
      "You cannot modify a transaction with an age of more than 24 hours",
    transactionSuccessfullyCreated: "Transaction successfully created",
    transactionSuccessfullyEdited: "Transaction successfully edited",
    transactionDetails: "Transaction Details",
    specifyTransactionDetails: "Specify the transaction details",
    transactionDate: "Transaction Date",

    //settings
    createProgranPart: "Create Progran Part",
    editProgramPart: "Edit Progran Part",
    programParts: "Program Parts",
    manageProgramParts: "Manage Program Parts",
    addNewProgramPart: "Add New Program Part",
    programPartSuccessfullyCreated: "Program successfully created",
    programPartSuccessfullyEdited: "Program successfully edited",

    //access manager
    role: "Role",
    roleAccessManager: "Roles access manager",
    accessManagement: "Access Management",
    modules: "Modules",
    accessTypes: "Access types",
    addModule: "Add module",
    readOnly: "Read only",
    readAndWrite: "Read and write",
    fullAccess: "Full access",
    addRole: "Add Role",
    editRole: "Edit Role",
    roleForm: "Role Form",
    readAccess: "Read Access",
    writeAccess: "Write Access",
    deleteAccess: "Delete Access",
    executeAccess: "Execute Access",
    roleSavedSuccessfully: "Role saved successfully",
    confirmRoleDelete: "Are you sure to delete this role?",
    roleDeletedSuccessfully: "Role deleted successfully",
    needToSelectModule: "You should select a module at least",
    notAuthorizedError: "You are not authorized to see this resource",

    //modules
    MEMBERS: "MEMBERS",
    USERS: "USERS",
    PROGRAMS: "PROGRAMS",
    TRANSACTIONS: "TRANSACTIONS",
    GROUP_MEMBERS: "GROUP MEMBERS",
    GROUP_PROGRAMS: "GROUP PROGRAMS",
    ACCESS_MANAGEMENT: "ACCESS MANAGEMENT",
    CHURCH_MANAGEMENT: "CHURCH MANAGEMENT",
    CHURCHES: "CHURCHES",
    CHURCH_MEMBERS: "CHURCH MEMBERS",
    CHURCH_USERS: "CHURCH USERS",
    CHURCH_PROGRAMS: "CHURCH PROGRAMS",
    CHURCH_TRANSACTIONS: "CHURCH TRANSACTIONS",
    

    //programs
    addProgram: "Create Program",
    editProgram: "Edit Program",
    specifyTitle: "Please specify the title",
    title: "Title",
    startDate: "Start Date",
    endDate: "End Date",
    programSuccessfullyCreated: "Program successfully created",
    programSuccessfullyEdited: "Program successfully edited",
    programDate: "Program Date",
    programName: "Program Name",

    //program details
    addNewProgramDetail: "Add new Program Detail",
    addProgramDetail: "Add Program Detail",
    editProgramDetail: "Edit Program Detail",
    programDetails: "Program Details",
    programPart: "Program Part",
    warningDeleteDetail: "Are you sure want to delete this detail ?",
    detailSuccDeleted: "Detail successfully deleted",
    detailName: "Detail Name",
    detailAlreadyExist: "This program detail already exist",

    //validations
    filloutfield: "Please fill out this field",
    writeValidEmail: "Please write a valid email",
    selectItem: "Please select an item of the list",
    minimalDate: "Date should not be before minimal date",
    programDetailSuccCreated: "Program Detail successfully created",
    programDetailSuccEdited: "Program Detail successfully edited",

    //Actions
    save: "Save",
    create: "Create",
    edit: "Edit",
    remove: "Remove",
    manage: "Manage",
    add: "Add",
    accept: "Accept",
    search: "Search",
    next: "Next",
    back: "Back",
    finish: "Finish",
    confirm: "Confirmß",

    //church
    church: "Church",
    toggleChurch: "Are you sure you want to disable this church ?",
    churchSeccessfullyCreated: "Church successfully created",
    churchSeccessfullyEdited: "Church successfully edited",
    churchForm: "Church Form",

    //churchCard
    churchImage: "Church Image",

    //sidebar
    myChurches: "MY CHURCHES",
    myGroups: "MY GROUPS",

    //======== Reports =====
    downloadData: "Download Data",
    filters: "Filters",
    clear: "Clear",
    applyFilters: "Apply Filters",
    //--- members--
    joiningDateRange: "Joining Date Range",
    baptizeRange: "Baptize Range",

    //common
    onlineChurch: "Online Church",
    churches: "Churches",
    goTo: "Go To",
    cancel: "Cancel",
    yes: "Yes",
    group: "Group",
    user: "User",
    active: "Active",
    name: "Name",
    address: "Address",
    description: "Description",
    dateCreated: "Date Created",
    successfullyCreated: "{entity} successfully created",
    successfullyEdited: "{entity} successfully edited",
    successfullyDeleted: "{entity} successfully deleted",
    signOut: "Are you sure you want to Sign Out ?",
    all: "ALL",
    roles: "Roles",
    id: "ID",
    delete: "Delete",
    viewInfo: "View Info",
    assignLeaders: "Assign Leaders",
    leaders: "Leaders",
    addLeaders: "Add Leaders",
    createdDate: "Creation Date",

    //Roles
    secretary: "Secretary",
    councilPresident: "Council President",
    groupLeader: "Group Leader",

    //status
    admonished: "Admonished",
    astray: "Astray",
    inactive: "Inactive",

    //Forgot password
    requestPasswordChangeEmailSent:
      "We have sent you an email with a link to change your password",
    passwordChangedSuccessfully: "Password changed successfully",

    // ==============HTTP MESSAGE=============
    USER_NOT_FOUND: "We don't have a registered user for this email",
    USER_DISABLED: "Your acces has been temporarily disabled",
    BAD_CREDENTIALS: "Wrong user or password",
    PASSWORD_LENGTH_IS_TOO_SHORT: "Password should be at least 6 characters",
    "Bad credentials": "Wrong user or password",
    pageNotFound: "404: The page you are looking for isn’t here",
    invalidCredentials: "Wrong user or password",
    theActionCouldNotBeComplete: "The action could not be completed",

    // ================ Form Errors ===============
    fieldIsRequired: "field is required",
  },
};
