import React, { useEffect, useState, forwardRef, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { Slide } from "@material-ui/core";
import MembersDialog from "./components/MemberDialog/MembersDialog";
import { MembersToolbar } from "./components";
import { DynamicTable, UserForm, Confirm } from "components";
import { Member } from "models";
import { MembersService } from "services";
import translate from "i18nProvider/translate";
import { injectIntl } from "react-intl";
import { membersTableHeaders, membersTablefields } from "helpers";
import HasUserDialog from "./components/HasUserDialog";
import { hideModal } from "redux/slices/modalSlice";
import { showAlertError, showAlertSuccess } from "redux/slices/alertsSlice";
import { hideLoading } from "redux/slices/loadingSlice";
import { useFetch } from "hooks/useFetch";
import { deleteMember } from "redux/slices/membersSlice";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1600px",
      margin: "0 auto",
    },
  },
  appBar: {
    position: "relative",
    marginBottom: theme.spacing(2),
  },
}));

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MemberList = injectIntl(({ dispatch, match, members = [], modal }) => {
  const history = useHistory();
  const location = useLocation();
  const CHURCH_ID = match.params.churchId;
  const classes = useStyles();

  const [fetchedMembers, setFetchedMembers] = useState(members);
  const [member, setMember] = useState(new Member());
  const [selectedRoles, setSelectedRoles] = useState([]);

  const [open, setOpen] = useState(false);
  const [openCreateUser, setOpenCreateUser] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { doFetch } = useFetch({ rootUrl: `/churches/${CHURCH_ID}/members` });

  const handleRowClick = (memb) => {
    let same = member.id === memb.id;
    setMember(same ? new Member() : memb);
    setEditMode(!same);
  };

  const onViewMember = () => {
    setOpen(true);
  };

  const resetSelected = () => {
    setMember(new Member());
    setEditMode(false);
  };

  const fetchMembers = React.useCallback((churchId) => {
    dispatch(new MembersService(churchId).getMembersByChurchId());
  }, []);

  useEffect(() => {
    setFetchedMembers(members);
  }, [members]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const onAddBtnClicked = () => {
    console.log('LOCATION: ', location);
    history.push(member.id ? `${location.pathname}/${member.id}/edit` : `${location.pathname}/new`)
  };

  const onCreateUserBtnClicked = async () => {
    if (!member.email) {
      await Confirm.show({
        message: translate("mustAddEmailToMember"),
        alertType: true,
      });
      return;
    }
    setSelectedRoles([]);
    setOpenCreateUser(true);
  };

  const handleCreateUserClose = () => {
    setOpenCreateUser(false);
  };

  const onCreateUserSubmit = async () => {
    await dispatch(
      new MembersService(CHURCH_ID).requestUser(
        {
          email: member.email,
          roles: selectedRoles,
        },
        member.id
      )
    );

    setMember(new Member());
    setOpenCreateUser(false);
    setEditMode(false);
  };

  const onDeleteBtnClicked = async () => {
    const result = await Confirm.show({
      message: translate("confirmDeleteMember"),
    });
    if (!result) return;
    doFetch({
      method: "DELETE",
      path: `/${member.id}`,
      successCallback: (resp) => {
        console.log("RESP: ", resp);
        dispatch(deleteMember(member.id));
        dispatch(showAlertSuccess(translate("memberSuccessfullyDeleted")));
        setEditMode(false);
      },
      errorCallback: () => {
        dispatch(showAlertError(translate("memberNotDeleted")));
      },
    });
  };

  const onUserAddedSuccess = useCallback(() => {
    dispatch(showAlertSuccess(translate("userAddedSuccessfully")));
    dispatch(hideLoading());
    dispatch(hideModal());
  }, []);

  const onUserAddedError = useCallback(() => {
    dispatch(showAlertError("theActionCouldNotBeComplete"));
    dispatch(hideLoading());
    dispatch(hideModal());
  }, []);

  useEffect(() => {
    fetchMembers(CHURCH_ID);
  }, [CHURCH_ID]);

  return (
    <div className={classes.content}>
      <MembersToolbar
        className={classes.appBar}
        onViewMember={onViewMember}
        onAddBtnClicked={onAddBtnClicked}
        onCreateUserBtnClicked={onCreateUserBtnClicked}
        editMode={editMode}
        dataToSearch={members}
        setDataOnSearch={setFetchedMembers}
        keysToSearch={membersTablefields}
        onDeleteBtnClicked={onDeleteBtnClicked}
      />

      <DynamicTable
        headers={membersTableHeaders}
        fields={membersTablefields}
        data={fetchedMembers}
        selectedItem={member}
        count={fetchedMembers.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRowClick={handleRowClick}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />

      {openCreateUser && (
        <UserForm
          handleClose={handleCreateUserClose}
          member={member}
          open={openCreateUser}
          transition={Transition}
          selectedRoles={selectedRoles}
          onSubmit={onCreateUserSubmit}
          labelAction={translate("requestUser")}
        />
      )}

      {open && (
        <MembersDialog
          opentDialgo={open}
          setOpenDialgo={setOpen}
          memberToEdit={member}
          clearMemberToEdit={() => resetSelected()}
        />
      )}

      {modal.show && (
        <HasUserDialog
          churchId={CHURCH_ID}
          dtoData={modal.data.user}
          memberId={modal.data.memberId}
          handleClose={() => dispatch(hideModal())}
          onSuccess={onUserAddedSuccess}
          onError={onUserAddedError}
        />
      )}
    </div>
  );
});

const mapStateToProps = (state) => ({
  members: state.members,
  modal: state.modal,
});

export default connect(mapStateToProps)(MemberList);
