import rules from "./rules";
import store from "redux/store";

export const authorize = (moduleName, accessType = null) => {
  const user = store.getState().session.user;

  if (!user) {
    window.location.href = "/sign-in";
  }

  if (user.superAdmin) {
    return true;
  }

  return handleDynamicAuthorization(moduleName, accessType);
};

const handleDynamicAuthorization = (moduleName, accessType) => {
  const { churchAccess, groupAccess } = store.getState().session;

  if (1 == 1) return true;
  const roles = [];
  return roles.some((item) => {
    if (accessType) {
      return item.moduleName === moduleName && item[accessType];
    }

    return item.moduleName === moduleName;
  });
};

export const getGlobalRules = (roles = []) => {
  let gRules = [];
  roles.forEach((element) => {
    if (rules[element]) {
      gRules.push(...rules[element].static);
    }
  });
  return gRules;
};

const superAdminResults = {
  canRead: true,
  canWrite: true,
  canDelete: true,
  canExecute: true,
};

export const getUserChurchAccess = (moduleName, churchId) => {
  const user = store.getState().session.user;

  if (!user) {
    window.location.href = "/sign-in";
  }

  if (user.superAdmin) {
    return superAdminResults;
  }

  const { churchAccess } = store.getState().session;

    if (churchId) {
      for (let i = 0; i < churchAccess?.length; i++) {
        const church = churchAccess[i].church;
        if (church.id == churchId) {
          const roles = churchAccess[i].roles;
          const role = roles?.find((role) => role.moduleName === moduleName);
          if (role) {
            const { canRead, canWrite, canDelete, canExecute } = role;

            return {
              canRead,
              canWrite,
              canDelete,
              canExecute,
            };
          }
        }
      }
    }
    return {
      canRead: false,
      canWrite: false,
      canDelete: false,
      canExecute: false,
    }
};

export const getUserGroupAccess = (moduleName, groupId) => {
  const user = store.getState().session.user;

  if (!user) {
    window.location.href = "/sign-in";
  }

  if (user.superAdmin) {
    return superAdminResults;
  }

  const { groupAccess } = store.getState().session;

  if (groupId) {
    for (let i = 0; i < groupAccess?.length; i++) {
      const group = groupAccess[i].group;
      if (group.id == groupId) {
        const roles = groupAccess[i].roles;
        const role = roles?.find((role) => role.moduleName === moduleName);
        if (role) {
          const { canRead, canWrite, canDelete, canExecute } = role;

          return {
            canRead,
            canWrite,
            canDelete,
            canExecute,
          };
        }
      }
    }
  }
  return {
    canRead: false,
    canWrite: false,
    canDelete: false,
    canExecute: false,
  }
};
