import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Fab, Breadcrumbs } from "@material-ui/core";
import { Link } from "react-router-dom";

import { SearchFilter } from "components";
import GroupAdd from "@material-ui/icons/GroupAdd";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import translate from "i18nProvider/translate";
import { authorize } from "auth";
import { injectIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  breadcrumb: {
    marginBottom: "20px",
  },
  iconBtn: {
    cursor: "pointer",
    color: '#00bfa5',
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: '#00897b',
    }
  },
}));

const Toolbar = injectIntl(
  ({
    className,
    removeMode = true,
    onAddBtnClick,
    onRemoveBtnClick,
    setDataOnSearch = () => {},
    dataToSearch = [],
    keysToSearch = [],
    churchId,
    urlAction = "",
    ...rest
  }) => {
    const classes = useStyles();

    return (
      <div {...rest} className={clsx(classes.root, className)}>
        {authorize("groups:write") && urlAction === "manage-members" && (
          <div className={classes.row}>
            <span className={classes.spacer} />

            {removeMode && (
              <Fab
                size="small"
                style={{ color: '#DC004E' }}
                title={translate("removeSelectedMembers")}
                onClick={onRemoveBtnClick}
                className={classes.iconBtn}
              >
                <DeleteIcon />
              </Fab>
            )}
            {!removeMode && (
              <Fab
                size="small"
                color="primary"
                title={translate("addNewMembers")}
                onClick={onAddBtnClick}
                className={classes.iconBtn}
              >
                <GroupAdd />
              </Fab>
            )}
          </div>
        )}
        <div className={classes.breadcrumb}>
          {authorize("groups:write") && urlAction === "manage-members" && (
            <GroupBreadcrumbs churchId={churchId} />
          )}
        </div>
        <div className={classes.row}>
          <SearchFilter
            dataToSearch={dataToSearch}
            setDataOnSearch={setDataOnSearch}
            keysToSearch={keysToSearch}
          />
        </div>
      </div>
    );
  }
);

function GroupBreadcrumbs({ churchId }) {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link to={`/churches/${churchId}/groups`}>
        {translate("backToMembers")}
      </Link>
    </Breadcrumbs>
  );
}

Toolbar.propTypes = {
  className: PropTypes.string,
  removeMode: PropTypes.bool,
  onAddBtnClick: PropTypes.func.isRequired,
  onRemoveBtnClick: PropTypes.func.isRequired,
};

export default Toolbar;
