import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box } from "@material-ui/core";

// import ProgramPartMaintenance from "./components/ProgramPartMaintenance";
import Scrollbars from "react-custom-scrollbars";
import { Link } from "react-router-dom";
import translate from "i18nProvider/translate";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
}));

const SettingsView = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} lg={5}>
          <Box mt={2}>
            <Scrollbars autoHide={true} style={{ height: 400, width: "auto" }}>
              {/* <ProgramPartMaintenance /> */}
            </Scrollbars>
            <ul>
              <li>
                <Link to={"access-management"}>
                  {translate("accessManagement")}
                </Link>
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default SettingsView;
