import { call } from "../tools/api";

/**
 * @class
 */
class ProgramsService {
  constructor(churchId) {
    this.churchId = churchId;
    this.basePath = `/churches/${this.churchId}/programs`;
  }

  getProgramsByChurch = async (fetchInfo) => {
    return await call(
      "get",
      `${this.basePath}?start=${fetchInfo.startStr}&end=${fetchInfo.endStr}`
    );
  };

  getProgramsByGroup = async (groupId, fetchInfo) => {
    return await call(
      "get",
      `/churches/${this.churchId}/groups/${groupId}/programs?start=${fetchInfo.startStr}&end=${fetchInfo.endStr}`
    );
  };


  getProgramById = async (programId) => {
    return await call("get", `${this.basePath}/${programId}`);
  };


  getGroupProgramById = async (groupId, programId) => {
    return await call("get", `/churches/${this.churchId}/groups/${groupId}/programs/${programId}`);
  };

  getProgramParts = async () => {
    return await call("get", `/churches/${this.churchId}/program-parts`);
  };

  getChurchProgramDetailsByProgramId = async (programId) => {
    return await call("get", `${this.basePath}/${programId}/details`);
  };

  getGroupProgramDetailsByProgramId = async (groupId, programId) => {
    return await call(
      "get",
      `/churches/${this.churchId}/groups/${groupId}/programs/${programId}/details`
    );
  };

  createProgramPart = async (programPart) => {
    return await call(
      "post",
      `/churches/${this.churchId}/program-parts`,
      programPart
    );
  };

  createChurchProgram = async (program) => {
    return await call("post", `${this.basePath}`, program);
  };

  createGroupProgram = async (groupId, program) => {
    return await call(
      "post",
      `/churches/${this.churchId}/groups/${groupId}/programs`,
      program
    );
  };

  createChurchProgramDetail = async (programId, programDetail) => {
    return await call(
      "post",
      `${this.basePath}/${programId}/details`,
      programDetail
    );
  };

  createGroupProgramDetail = async (groupId, programId, programDetail) => {
    return await call(
      "post",
      `/churches/${this.churchId}/groups/${groupId}/programs/${programId}/details`,
      programDetail
    );
  };

  editChurchProgram = async (program) => {
    return await call("put", `${this.basePath}/${program.id}`, program);
  };

  editGroupProgram = async (groupId, program) => {
    return await call(
      "put",
      `/churches/${this.churchId}/groups/${groupId}/programs/${program.id}`,
      program
    );
  };

  editProgramPart = async (programPart) => {
    return await call(
      "put",
      `/churches/${this.churchId}/program-parts/${programPart.id}`,
      programPart
    );
  };

  editChurchProgramDetail = async (programId, programDetail) => {
    return await call(
      "put",
      `${this.basePath}/${programId}/details/${programDetail.id}`,
      programDetail
    );
  };

  editGroupProgramDetail = async (groupId, programId, programDetail) => {
    return await call(
      "put",
      `/churches/${this.churchId}/groups/${groupId}/programs/${programId}/details`,
      programDetail
    );
  };

  deleteChurchProgramDetail = async (programId, programDetailId) => {
    return await call(
      "delete",
      `${this.basePath}/${programId}/details/${programDetailId}`
    );
  };

  deleteGroupProgramDetail = async (groupId, programId, programDetailId) => {
    return await call(
      "delete",
      `/churches/${this.churchId}/groups/${groupId}/programs/${programId}/details/${programDetailId}`
    );
  };
}

export default ProgramsService;
