/**
 * @class
 */
class Member {
  id = null;
  firstName = "";
  lastName = "";
  personalId = null;
  email = null;
  address = "";
  phone = "";
  phone2 = "";
  picture = "";
  baptismDate = "";
  baptized = false;
  statusId = 1;
  church = null;
  joiningDate = "";
  birthday = "";
  dateCreated = "";
}

/**
 * @class
 */
class Church {
  id = null;
  name = "";
  email = "";
  phone = "";
  active = true;
  address = "";
  picture = "";
  dateCreated = null;
  councilId = null;
}

/**
 * @class
 */
class User {
  id = null;
  firstName = "";
  lastName = "";
  phone = "";
  email = "";
  password = "";
  lastUpdate = "";
  dateCreated = "";
  roles = [];
  active = true;
}

/**
 * @class
 */
class Role {
  id = null;
  name = "";
  role = "";
  availability = "";
}

/**
 * @class
 */
class Status {
  id = null;
  name = "";
}

/**
 * @class
 */
class Group {
  id = null;
  name = "";
  leaderId = "";
  description = "";
  members = 0;
}

/**
 * @class
 */
class Program {
  id = null;
  name = "";
  startDate = new Date();
  endDate = new Date();
  description = "";
  programDetails = [];
}

/**
 * @class
 */
class ProgramPart {
  id = null;
  name = "";
}

/**
 * @class
 */
class ProgramDetail {
  id = "";
  memberId = "";
  programPartId = "";
}

/**
 * @class
 */
class Transaction {
  id = null;
  date = "";
  lastUpdate = "";
  amount = "";
  transactionType = "";
  paymentMethod = "";
  transactionDesc = "";
  churchId = "";
  churchName = "";
  memberId = "";
  memberFirstName = "";
  memberLastName = "";
  memberPersonalId = "";
  memberEmail = "";
  groupId = "";
  groupName = "";
  details = "";
}

/**
 * @class
 */
class TransactionType {
  id = null;
  type = "";
  description = "";
  availability = "";
}

/**
 * @class
 */
class PaymentMethod {
  id = null;
  name = "";
}

export {
  Member,
  Church,
  User,
  Role,
  Status,
  Group,
  Program,
  ProgramPart,
  ProgramDetail,
  Transaction,
  TransactionType,
  PaymentMethod,
};
