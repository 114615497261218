import React from "react";
import PropTypes from "prop-types";
import { Paper, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Edit, RemoveCircleOutline } from "@material-ui/icons";
import { yellow, red } from "@material-ui/core/colors";
import clsx from "clsx";
import translate from "i18nProvider/translate";

const iconStyle = {
  fontSize: 16,
  padding: 6,
  cursor: "pointer",
  "&:hover": {
    background: "#E0E0E0",
  },
};

const useStyles = makeStyles((theme) => ({
  root: {},
  container: {
    display: "flex",
  },
  item: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    flex: 1,
  },
  action: {
    display: "flex",
    flexDirection: "column",
    borderLeft: "solid #eeeeee 1px",
    flex: 0,
  },
  editIcon: {
    ...iconStyle,
    color: yellow[900],
  },
  removeIcon: {
    ...iconStyle,
    color: red[900],
  },
  disabled: {
    opacity: 0.3,
  },
  font: {
    fontSize: "14px",
    fontWeight: "500",
  },
}));

const ProgramPart = (props) => {
  const {
    programPart = {},
    member = {},
    onEditClick,
    onRemoveClick,
    className,
    showButtons = true,
    disableActions = false,
  } = props;
  const classes = useStyles();
  return (
    <Paper className={clsx(classes.container, className)}>
      <div className={classes.item}>
        <Typography variant="h5">{programPart.name}</Typography>
        <Typography variant="caption">{`${member.name}`}</Typography>
      </div>
      {showButtons && (
        <div className={classes.action}>
          <IconButton className={classes.editIcon} onClick={onEditClick}>
            <Edit style={{ opacity: disableActions ? "0.3" : "1" }} titleAccess={translate("edit")} />
          </IconButton>
          <IconButton className={classes.removeIcon} onClick={onRemoveClick}>
            <RemoveCircleOutline
              style={{ opacity: disableActions ? "0.3" : "1" }}
              titleAccess={translate("remove")}
            />
          </IconButton>
        </div>
      )}
    </Paper>
  );
};

ProgramPart.propTypes = {
  programPart: PropTypes.object.isRequired,
  member: PropTypes.object.isRequired,
  onEditClick: PropTypes.func,
  onRemoveClick: PropTypes.func,
  showButtons: PropTypes.bool,
};

export default ProgramPart;
