import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, Button } from "@material-ui/core";
import { GroupsToolbar, GroupCard } from "./components";
import { FullScreenDialog, Confirm, CTextField, Select } from "components";
import { Group } from "models";
import { connect } from "react-redux";
import { GroupsService } from "services";
import translate from "i18nProvider/translate";
import { injectIntl } from "react-intl";
import { useFetch } from "hooks/useFetch";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const GroupList = injectIntl(({ groups, session, dispatch, match }) => {
  const classes = useStyles();
  const CHURCHID = match.params.churchId;

  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [group, setGroup] = useState(new Group());
  const [leaders, setLeaders] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const { data: groupLeaders, fetchRoot: fetchUsers } = useFetch({
    rootUrl: `/churches/${CHURCHID}/users`,
    defaultValue: [{ id: 0, firstName: "---" }],
  });
  const { data: roles, fetchRoot: fetchRoles } = useFetch({
    rootUrl: `/access-management/roles`,
    defaultValue: [{ id: 0, name: "---" }],
  });

  const fetchGroups = React.useCallback(() => {
    dispatch(new GroupsService(CHURCHID).getGroupsByChurch());
    fetchUsers();
  }, [dispatch, CHURCHID]);

  useEffect(() => {
    fetchGroups();
    fetchRoles();
  }, [CHURCHID, fetchGroups]);

  useEffect(() => {
    setFilteredGroups(groups);
  }, [groups]);

  const handleClose = () => {
    setOpen(false);
  };

  const onAddBtnClick = () => {
    history.push(`/churches/${CHURCHID}/groups/new`);
  };

  const onSubmit = async () => {
    if (group.id) {
      handleClickEditGroup();
    } else {
      handleClickCreateGroup();
    }
  };

  const handleClickEditGroup = async () => {
    await dispatch(new GroupsService(CHURCHID).update({ ...group }));
    setOpen(false);
  };

  const handleClickCreateGroup = async () => {
    let leaderId = group.leaderId;
    await dispatch(
      new GroupsService(CHURCHID).create({ ...group, leaderId: leaderId })
    );
    setOpen(false);
  };

  const handleChange = (event) => {
    event.target.setCustomValidity("");
    setGroup({
      ...group,
      [event.target.name]: event.target.value,
    });
  };

  const onEditClick = (g) => {
    setGroup(g);
    setOpen(true);
  };

  const onDeleteBtnClicked = async (group) => {
    const result = await Confirm.show({
      message: translate("confirmDeleteGroup"),
    });
    if (!result) return;
    await dispatch(new GroupsService(CHURCHID).deleteGroupById(group.id));
  };

  const onLeaderSelectChange = (event, index) => {
    const selected = event.target.value;
    console.log('Selected Leader: ', selected);
    leaders[index].leaderId = selected;
    setLeaders([...leaders]);
  };

  const onRoleChange = (event, index) => {
    const selected = event.target.value;
    console.log('Selected Role: ', selected);
    leaders[index].roleId = selected;
    setLeaders([...leaders]);
  };

  const onAddLeaderClick = () => setLeaders([...leaders, {leaderId: 0, roleId: 0} ]);

  return (
    <div className={classes.root}>
      <GroupsToolbar
        onAddBtnClick={onAddBtnClick}
        dataToSearch={groups}
        setDataOnSearch={setFilteredGroups}
        keysToSearch={["name", "leaderName"]}
      />

      <div className={classes.content}>
        <Grid container spacing={3}>
          {filteredGroups
            .filter((gr) => !!gr)
            .map((group) => (
              <Grid item key={group.id} lg={3} md={4} sm={6} xs={12}>
                <GroupCard
                  group={group}
                  showAllOptions={session.user?.superAdmin}
                  onEditClick={onEditClick}
                  onDeleteBtnClicked={onDeleteBtnClicked}
                />
              </Grid>
            ))}
        </Grid>
      </div>

      {open && (
        <FullScreenDialog
          title={translate("groupsFormTitle")}
          handleClose={handleClose}
          open={open}
          onSubmit={onSubmit}
          saveBtnLabel={group.id ? "edit" : "create"}
        >
          <Box style={{ width: "90%", maxWidth: "1000px", margin: "0 auto" }}>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} xs={12}>
                <CTextField
                  helperText={translate("specifyName")}
                  label="name"
                  onChange={handleChange}
                  name="name"
                  value={group.name}
                />
                <CTextField
                  helperText={translate("specifyDescription")}
                  label="description"
                  onChange={handleChange}
                  name="description"
                  value={group.description || ""}
                  multiline
                  rows={5}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Box>
                  <h4>Leaders</h4>
                  {leaders.map((leader, index) => (
                    <Grid container spacing={3} key={`leaders-${index}`}>
                      <Grid item xs={6}>
                        <Select
                          label="leader"
                          onChange={(item) => onLeaderSelectChange(item, index)}
                          value={leader.leaderId}
                          options={groupLeaders}
                          name="leaderId"
                          helperText={translate("specifyLeader")}
                          optionLabelExpression="{option.firstName +' '+ option.lastName}"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Select
                          label="role"
                          onChange={(item) => onRoleChange(item, index)}
                          value={leader.roleId}
                          options={roles}
                          name="roleName"
                          helperText={translate("specifyLeader")}
                          optionLabelExpression="{option.name}"
                        />
                      </Grid>
                    </Grid>
                  ))}
                  <Button onClick={onAddLeaderClick} variant="outlined">+ Add Leader</Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </FullScreenDialog>
      )}
    </div>
  );
});

const mapStateToProps = (state) => ({
  groups: state.groups,
  session: state.session,
});

export default connect(mapStateToProps)(GroupList);
