import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPluging from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";
import enLocale from "@fullcalendar/core/locales/en-au";

import "./main.scss";
import { makeStyles } from "@material-ui/styles";

import { ProgramsService } from "services";
import { confirmDateOlder24Hours } from "helpers";
import { connect } from "react-redux";
import {getUserGroupAccess} from "../../auth";
import {useHistory, useParams} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  calendar: {
    maxWidth: 1024,
    fontSize: "0.8rem",
    marginTop: 5,
  },
  timePicker: {
    flex: 1,
  },
}));

const ProgramsView = ({ language }) => {
  const classes = useStyles();
  const calendarComponentRef = useRef(null);
  const isFirstRender = React.useRef(true);
  const history = useHistory();
  const {churchId, groupId} = useParams();

  const CHURCH_ID = churchId;
  const GROUP_ID = groupId;
  const [programs, setPrograms] = useState([]);

  const {
    canRead: groupCanRead,
    canWrite: groupCanWrite,
    canDelete: groupCanDelete,
    canExecute: groupCanExecute
  } = getUserGroupAccess("GROUP_PROGRAMS", GROUP_ID);


  const {
    canRead: churchCanRead,
    canWrite: churchCanWrite,
    canDelete: churchCanDelete,
    canExecute: churchCanExecute
  } = getUserGroupAccess("CHURCH_PROGRAMS", CHURCH_ID);

  const handleDateClick = (arg) => {
    if (!confirmDateOlder24Hours(arg.date) && (churchCanWrite || groupCanWrite)) {
      const dateParam = `date=${arg.dateStr}`;
      if (groupId) {
        history.push(`/churches/${CHURCH_ID}/groups/${GROUP_ID}/programs/new?${dateParam}`);
      } else {
        history.push(`/churches/${CHURCH_ID}/programs/new?${dateParam}`);
      }
    }
  };

  const handleEventClick = (obj) => {
    let event = obj.event;
    if (groupId) {
      history.push(`/churches/${CHURCH_ID}/groups/${GROUP_ID}/programs/${event.id}`);
    } else {
      history.push(`/churches/${CHURCH_ID}/programs/${event.id}`);
    }
  };

  const fetchEvents = async (fetchInfo) => {
    if (isFirstRender.current) {
      try {
        let res = [];
        if (GROUP_ID) {
          res = await new ProgramsService(CHURCH_ID).getProgramsByGroup(
            GROUP_ID,
            fetchInfo
          );
        } else {
          res = await new ProgramsService(CHURCH_ID).getProgramsByChurch(
            fetchInfo
          );
        }
        setPrograms(res);
      } catch (error) {
        console.log(error);
      }
    } else {
      return programs.map((ev) => ({
        id: ev.id,
        title: ev.name,
        start: ev.startDate,
        end: ev.endDate,
      }));
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  });

  return (
    <div className={classes.root}>
      <div className={classes.calendar}>
        <FullCalendar
          events={fetchEvents}
          defaultView="dayGridMonth"
          header={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
          }}
          plugins={[
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            listPluging,
          ]}
          ref={calendarComponentRef}
          weekends={true}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          locales={[esLocale, enLocale]}
          locale={language === "es-es" ? "es" : "us"}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  language: state.language,
});

export default connect(mapStateToProps)(ProgramsView);
