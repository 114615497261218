import React, { Component, useEffect } from "react";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Chart } from "react-chartjs-2";
import { ThemeProvider } from "@material-ui/styles";
import validate from "validate.js";

import { chartjs } from "./helpers";
import theme from "./theme";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./assets/scss/index.scss";
import validators from "./common/validators";
import Routes from "./Routes";
import { I18nPropvider } from "i18nProvider";
import { connect } from "react-redux";
import { ShowAlert, Loading } from "components";
import store from "redux/store";
import { setSession } from "redux/slices/sessionSlice";
import { useFetch } from "hooks/useFetch";
import { useLocation } from "react-router-dom";

const browserHistory = createBrowserHistory();

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
});

validate.validators = {
  ...validate.validators,
  ...validators,
};

class App extends Component {
  render() {
    return (
      <I18nPropvider locale={this.props.language}>
        <ThemeProvider theme={theme}>
          <ShowAlert />
          <Loading />
            <Router history={browserHistory}>
              <SessionProvider>
                <Routes />
              </SessionProvider>
            </Router>
        </ThemeProvider>
      </I18nPropvider>
    );
  }
}

const SessionProvider = ({children}) => {
  const session = store.getState()?.session || {};
  const location = useLocation();

  const { data, error, fetchRoot } = useFetch({
    rootUrl: "/my-resources",
    initialValue: null,
    v2: true,
  });

  useEffect(() => {
    const { churchAccess, groupAccess } = session;
    if (!churchAccess?.length && !groupAccess?.length
      && !location.pathname.includes('create-account')
      && !location.pathname.includes('sign-in')
      && !location.pathname.includes('members/sign-up')
      && !location.pathname.includes('users/sign-up')
      && !location.pathname.includes('users/change-password')
      && !location.pathname.includes('users/request-password-change')
      && !location.pathname.includes('check-request')
      && !location.pathname.includes('not-found')
    ) {
      fetchRoot();
    }
  }, []);

  useEffect(() => {
    if (data) {
      store.dispatch(setSession({ ...session, ...data}));
    }
  }, [data]);

  return <>{children}</>
};

const mapStateToProps = (state) => ({
  language: state.language,
});

export default connect(mapStateToProps)(App);
