import { call } from "../tools/api";
import {
  getMembers,
  addMember,
  updateMember,
  deleteMember,
} from "../redux/slices/membersSlice";
import {
  showAlertSuccess,
  showAlertWarning,
} from "../redux/slices/alertsSlice";
import translate from "i18nProvider/translate";
import { showModal } from "redux/slices/modalSlice";

/**
 * Members Service
 * @class
 */
class MembersService {
  /**
   * MembersService constructor
   * @param {number} churchId id of the church
   */
  constructor(churchId) {
    this.churchId = churchId;
    this.basePath = `/churches/${this.churchId}/members`;
  }

  /**
   * Returns all members of the given Church
   * @returns {Member[]} returns Member[]
   */
  getMembersByChurchId = () => async (dispatch) => {
    try {
      const res = await call("get", this.basePath);
      dispatch(getMembers(res));
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * Returns all members of the given Church
   * @returns {Member[]} returns Member[]
   */
  getMembersByGroupId = (groupId) => async (dispatch) => {
    try {
      const res = await call(
        "get",
        `/churches/${this.churchId}/groups/${groupId}/members`
      );
      dispatch(getMembers(res));
    } catch (error) {
      console.error(error);
    }
  };

  /**
   * @param {Member} member object of Member class
   */
  create = (member) => async (dispatch) => {
    try {
      const res = await call("post", this.basePath, member);
      if (res?.status === 208) {
        dispatch(
          showAlertWarning(translate("memberAlreadyRegisteredWithEmail"))
        );
      } else {
        dispatch(addMember({ ...res, status: member.status }));
        dispatch(showAlertSuccess(translate("memberSuccessfullyCreated")));
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @param {number} memberId
   * @param {Member} member object of Member class
   */
  updateMember = (memberId, member) => async (dispatch) => {
    try {
      const res = await call("put", `${this.basePath}/${memberId}`, member);
      dispatch(updateMember(res));
      dispatch(showAlertSuccess(translate("memberSuccessfullyEdited")));
    } catch (error) {
      console.log(error);
    }
  };

  requestUser = (user, memberId) => async (dispatch) => {
    const res = await call(
      "post",
      `${this.basePath}/${memberId}/users/request`,
      user
    );
    console.log("RES: ", res.message);
    if (res.status === 208) {
      dispatch(showAlertWarning(translate("memberAlreadyHasUser")));
    } else if (res.status === 202) {
      dispatch(showModal({ message: res.message, user, memberId }));
    } else {
      dispatch(showAlertSuccess(translate("requestSent")));
    }
  };

  deleteMember = (memberId) => async (dispatch) => {
    try {
      await call("delete", `${this.basePath}/${memberId}`);
      dispatch(deleteMember(memberId));
      dispatch(showAlertSuccess(translate("memberSuccessfullyDeleted")));
    } catch (error) {
      console.error(error);
    }
  };
}

export default MembersService;
