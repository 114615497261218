import { createIntl, createIntlCache } from "react-intl";
import messages from "./messages";
import store from "redux/store";

const cache = createIntlCache();

const translate = (id, value = {}) => {
  const locale = store.getState().language;
  const intl = createIntl(
    {
      locale: locale,
      messages: messages[locale],
    },
    cache
  );
  return intl.formatMessage({ id: id });
};

const unsubscribe = store.subscribe(translate);
unsubscribe();

export default translate;
