import React from "react";
import { Typography } from "@material-ui/core";
import { translateEnum } from "helpers";

const ConfirmContent = ({ translate, transaction, member = null }) => {
  return (
    <div style={{ marginTop: "15px" }}>
      {transaction.id && (
        <div>
          <Typography>
            {translate("transactionId")} {`: ${transaction.id}`}
          </Typography>
        </div>
      )}
      <div>
        <Typography>
          {translate("transactionType")} :{" "}
          {translateEnum(transaction.transactionDesc)}
        </Typography>
      </div>
      <div>
        <Typography>
          {translate("amount")} {`: ${transaction.amount}`}
        </Typography>
      </div>
      <div>
        <Typography>
          {translate("paymentMethod")}:{" "}
          {translateEnum(transaction.paymentMethod)}
        </Typography>
      </div>
      {member && (
        <div>
          <Typography>
            {translate("member")}{" "}
            {`: ${member.firstName + " " + member.lastName}`}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default ConfirmContent;
