import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { FullScreenDialog } from "components";
import {
  Grid,
  Avatar,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { getInitials } from "helpers";
import { makeStyles } from "@material-ui/styles";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { useFetch } from "hooks/useFetch";
import { authorize } from "auth";
import translate from "i18nProvider/translate";

const useStyles = makeStyles((theme) => ({
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
}));

const UserForm = injectIntl(
  ({
    member = {},
    open = false,
    transition,
    selectedRoles,
    handleClose = () => {},
    handleRoleChange = () => {},
    onSubmit = () => {},
    labelAction,
    availableRoles,
  }) => {
    const classes = useStyles();

    const { isLoading, error, data, fetchRoot } = useFetch({
      rootUrl: "/access-management/roles",
      initialValue: [],
    });

    useEffect(() => {
      fetchRoot();
    }, []);

    const canWrite = authorize("USERS", "canWrite");

    return (
      <div>
        <FullScreenDialog
          title={translate("usersFormTitle")}
          handleClose={handleClose}
          open={open}
          transition={transition}
          saveBtnLabel={labelAction ? labelAction : "save"}
          onSubmit={canWrite && onSubmit}
          actionsDisabled={!canWrite}
        >
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <div className={classes.nameContainer}>
                <Avatar className={classes.avatar} src={member.picture}>
                  {getInitials(member.firstName + " " + member.lastName)}
                </Avatar>
                <p>
                  {translate("userConfirmationPrefix")}<b>{member.firstName + " " + member.lastName}</b>{translate("userConfirmationSuffix")}
                </p>
              </div>
            </Grid>
          </Grid>
        </FullScreenDialog>
      </div>
    );
  }
);

UserForm.propTypes = {
  member: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  selectedRoles: PropTypes.array.isRequired,
  transition: PropTypes.any,
  handleClose: PropTypes.func.isRequired,
  handleRoleChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

const mapStateToProps = (state) => ({
  roles: state.roles,
  availableRoles: state.session.user.roles,
});

export default connect(mapStateToProps)(UserForm);
