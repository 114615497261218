import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import store from "redux/store";
import { injectIntl } from "react-intl";
import translate from "i18nProvider/translate";
import { makeStyles } from "@material-ui/styles";
import Toolbar from "./components";
import { DynamicTable, FullScreenDialog } from "components";

import { GroupsService } from "services";
import { Confirm, SearchFilter } from "components";
import { membersTablefields, membersTableHeaders } from "helpers";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  table: {
    marginTop: 15,
  },
  searchFilter: {
    maxWidth: "450px",
  },
}));

const ManageGroup = injectIntl(({ match, session }) => {
  const classes = useStyles();

  const groupId = match.params.groupId;
  const churchId = match.params.churchId;

  const urlAction = match.url.substring(match.url.lastIndexOf("/") + 1);

  const [groupMembers, setGroupMembers] = useState([]);
  const [filteredGmembers, setFilteredGmembers] = useState([]);
  const [membersNotInGroup, setMembersNotInGroup] = useState([]);
  const [filteredMembersNotIn, setFilteredMembersNotIn] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [selectedItems, setSelectedItems] = useState([]);
  const [open, setOpen] = useState(false);

  const fetchGroupMembers = React.useCallback(async () => {
    if (groupId && churchId) {
      try {
        const gMembers = await new GroupsService(churchId).getMembersByGroup(
          groupId
        );
        setGroupMembers(gMembers);
        setFilteredGmembers(gMembers);
      } catch (error) {
        console.log(error);
      }
    }
  }, [groupId, churchId]);

  const fetchMembersNotInGroup = React.useCallback(async () => {
    if (groupId && churchId) {
      try {
        const newMembers = await new GroupsService(
          churchId
        ).getMembersNotInGroup(groupId);

        setMembersNotInGroup(newMembers);
        setFilteredMembersNotIn(newMembers);
      } catch (error) {
        console.log(error);
      }
    }
  }, [groupId, churchId]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleSelectOne = (event, item) => {
    if (selectedItems.includes(item.id)) {
      setSelectedItems(selectedItems.filter((id) => id !== item.id));
    } else {
      setSelectedItems(selectedItems.concat(item.id));
    }
  };

  const handleSelectAll = () => {
    if (selectedItems.length === groupMembers.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(groupMembers.map((m) => m.id));
    }
  };

  const onAddBtnClick = () => {
    handleOpen();
  };

  const filterDeletedMembers = () => {
    const filtered = groupMembers.filter((element) => {
      return !selectedItems.includes(element.id);
    });
    setFilteredGmembers(filtered);
  };

  const onRemoveBtnClick = async () => {
    const result = await Confirm.show({
      message: translate("confirmRemoveMember"),
    });
    if (selectedItems.length > 0 && result) {
      try {
        await new GroupsService(churchId).deleteMembers(groupId, selectedItems);

        filterDeletedMembers();
        setSelectedItems([]);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleOpen = () => {
    setSelectedItems([]);
    setPage(0);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedItems([]);
  };

  const onSubmit = async (e) => {
    if (selectedItems.length > 0) {
      await store.dispatch(
        new GroupsService(churchId).addMembers(groupId, selectedItems)
      );
      fetchGroupMembers();
      setSelectedItems([]);
      setOpen(false);
    }
  };

  const authorize = (moduleName) => {
    const groupAccess = session.groupAccess;
    return (
      session?.user?.superAdmin ||
      groupAccess?.some((access) =>
        access?.roles?.some((role) => role.moduleName === moduleName)
      )
    );
  };

  useEffect(() => {
    fetchGroupMembers();
  }, [fetchGroupMembers]);

  useEffect(() => {
    if (open) {
      fetchMembersNotInGroup();
    }
  }, [fetchMembersNotInGroup, open]);

  return (
    <div className={classes.container}>
      <Toolbar
        setDataOnSearch={setFilteredGmembers}
        dataToSearch={groupMembers}
        keysToSearch={membersTablefields}
        removeMode={selectedItems.length ? true : false}
        onAddBtnClick={onAddBtnClick}
        onRemoveBtnClick={onRemoveBtnClick}
        churchId={churchId}
        urlAction={urlAction}
      />

      <DynamicTable
        className={classes.table}
        data={filteredGmembers}
        count={filteredGmembers?.length}
        fields={membersTablefields}
        headers={membersTableHeaders}
        page={page}
        rowsPerPage={rowsPerPage}
        selectedItems={selectedItems}
        multiselection={
          authorize("GROUP_MANAGEMENT") && urlAction === "manage-members"
        }
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        handleSelectOne={handleSelectOne}
        handleSelectAll={handleSelectAll}
      />

      {open && (
        <FullScreenDialog
          open={open}
          onSubmit={onSubmit}
          handleClose={handleClose}
          saveBtnLabel={"add"}
          title={translate("churchMembers")}
        >
          <div className={classes.searchFilter}>
            <SearchFilter
              setDataOnSearch={setFilteredMembersNotIn}
              dataToSearch={membersNotInGroup}
              keysToSearch={membersTablefields}
            />
          </div>
          <DynamicTable
            className={classes.table}
            data={filteredMembersNotIn}
            count={filteredMembersNotIn.length}
            fields={membersTablefields}
            headers={membersTableHeaders}
            page={page}
            rowsPerPage={rowsPerPage}
            selectedItems={selectedItems}
            multiselection={true}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handleSelectOne={handleSelectOne}
            handleSelectAll={handleSelectAll}
          />
        </FullScreenDialog>
      )}
    </div>
  );
});

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(ManageGroup);
