import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  Typography,
  CardHeader,
  IconButton,
  Avatar,
  CardMedia,
  Popover,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  CardActions,
} from "@material-ui/core";

import {
  MoreVert,
  People as PeopleIcon,
  AccountCircle as AccountCircleIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  MonetizationOn as MoneyIcon,
  PeopleOutline,
  EventNote as EventNoteIcon,
  Settings as SettingsIcon,
} from "@material-ui/icons";
import { red } from "@material-ui/core/colors";
import { getInitials } from "helpers";
import { useHistory } from "react-router-dom";
import translate from "i18nProvider/translate";
import { injectIntl } from "react-intl";

const defaultImg = process.env.REACT_APP_DEFAULT_CHURCH_IMG;

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    minWidth: 252,
  },
  media: {
    paddingTop: "56.25%", // 16:9
  },

  avatar: {
    backgroundColor: red[500],
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
    marginTop: "auto",
  },
  menuContainer: {
    padding: theme.spacing(2),
  },
  icons: {
    marginRight: theme.spacing(1),
  },
  address: {
    wordBreak: "break-all",
  },
  cardHeader: {
    maxHeight: "70px",
  },
}));

const ChurchCard = injectIntl(
  ({
    className,
    church,
    roles = [],
    showAllOptions = false,
    showMembersCount = true,
    ...rest
  }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const history = useHistory();

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setAnchorEl(null);
    };

    const handleListKeyDown = (event) => {
      if (event.key === "Tab") {
        event.preventDefault();
        handleClose();
      }
    };

    const onMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
      handleOpen();
    };

    const truncateString = (str, num) => {
      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    };

    const authorize = (moduleName) =>
      showAllOptions || roles.some((role) => role.moduleName === moduleName);

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {getInitials(church.name)}
            </Avatar>
          }
          action={
            <IconButton
              aria-label="settings"
              onClick={onMenuClick}
              aria-describedby={church.id}
            >
              <MoreVert />
            </IconButton>
          }
          title={truncateString(church.name, 35)}
          titleTypographyProps={{ variant: "h6" }}
          className={classes.cardHeader}
        />
        <CardMedia
          className={classes.media}
          image={church.picture || defaultImg}
          title={translate("churchImage")}
        />
        <CardContent>
          <Typography
            className={classes.address}
            variant="body1"
            color="textSecondary"
            component="p"
          >
            {church.address || ""}
          </Typography>
          <Popover
            id={church.id}
            anchorEl={anchorEl}
            onClose={handleClose}
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className={classes.menuContainer}
                  autoFocusItem={open}
                  onKeyDown={handleListKeyDown}
                >
                  {authorize("CHURCH_MEMBERS") && (
                    <MenuItem
                      onClick={() =>
                        history.push(`/churches/${church.id}/members`)
                      }
                    >
                      <PeopleIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("members")}
                      </Typography>
                    </MenuItem>
                  )}

                  {authorize("CHURCH_USERS") && (
                    <MenuItem
                      onClick={() =>
                        history.push(`/churches/${church.id}/users`)
                      }
                    >
                      <AccountCircleIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("users")}
                      </Typography>
                    </MenuItem>
                  )}

                  {authorize("GROUP_MANAGEMENT") && (
                    <MenuItem
                      onClick={() =>
                        history.push(`/churches/${church.id}/groups`)
                      }
                    >
                      <SupervisedUserCircleIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("groups")}
                      </Typography>
                    </MenuItem>
                  )}

                  {authorize("CHURCH_PROGRAMS") && (
                    <MenuItem
                      onClick={() =>
                        history.push(`/churches/${church.id}/programs`)
                      }
                    >
                      <EventNoteIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("programs")}
                      </Typography>
                    </MenuItem>
                  )}

                  {authorize("CHURCH_TRANSACTIONS") && (
                    <MenuItem
                      onClick={() =>
                        history.push(`/churches/${church.id}/transactions`)
                      }
                    >
                      <MoneyIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("transactions")}
                      </Typography>
                    </MenuItem>
                  )}

                  {authorize("CHURCH_LEADERS") && (
                    <MenuItem
                      onClick={() =>
                        history.push(`/churches/${church.id}/leaders`)
                      }
                    >
                      <MoneyIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("leaders")}
                      </Typography>
                    </MenuItem>
                  )}

                  {/* {authorize("ACCESS_MANAGEMENT") && (
                    <MenuItem
                      onClick={() =>
                        history.push(`/churches/${church.id}/access-management`)
                      }
                    >
                      <SettingsIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("accessManagement")}
                      </Typography>
                    </MenuItem>
                  )} */}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popover>
        </CardContent>

        {showMembersCount && (
          <CardActions className={classes.cardActions}>
            <PeopleOutline style={{ fontSize: 18 }} />
            <Typography variant="body2" color="textSecondary">
              {church.members} {translate("members")}
            </Typography>
          </CardActions>
        )}
      </Card>
    );
  }
);

ChurchCard.propTypes = {
  className: PropTypes.string,
  church: PropTypes.object.isRequired,
};

export default ChurchCard;
