const refreshListAfterEdit = (editedObject, list) => {
  const refreshed = [...list];
  const editedIndex = list.findIndex((element) => {
    return element.id === editedObject.id;
  });
  if (editedIndex > -1) {
    refreshed[editedIndex] = editedObject;
    return refreshed;
  }
  return list;
};

export default refreshListAfterEdit;
