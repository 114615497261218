import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

const DynamicModal = (props) => {
  const { open, handleClose, children } = props;
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={handleClose}>
      <div>{children}</div>
    </Modal>
  );
};

DynamicModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default DynamicModal;
