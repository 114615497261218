import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { CollapsibleTable } from "components";
import GroupsFilter from "./GroupsFilter";
import { withRouter } from "react-router-dom";
import { ReportsService } from "services";
import { connect } from "react-redux";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 15,
  },
}));

const firstHeaders = ["group", "leader"];
const subHeaders = ["personalId", "firstname", "lastname", "phone", "email"];
const firstHeadersFields = ["groupName", "leaderName"];
const subHeadersFields = [
  "personalId",
  "firstName",
  "lastName",
  "phone",
  "email",
];
const subTableName = "members";
const subTableDataFieldName = "members";

const GroupsReport = ({ match, members, groupsFromRedux = [], dispatch }) => {
  const classes = useStyles();

  const [groupsReport, setGroupsReport] = React.useState([]);
  const [groupsLeaders, setGroupLeaders] = React.useState([]);

  const CHURCH_ID = match.params.churchId;

  const getGroupsReport = React.useCallback(async () => {
    try {
      const gs = await new ReportsService(CHURCH_ID).getGroupsReport({
        leaderId: "",
        groupId: "",
        jrxmlFilename: "",
      });
      if (Array.isArray(gs)) {
        setGroupsReport(gs);
        const leaders = gs.filter(group => !!group.leaderId).map(group => ({ id: group.leaderId, firstName: group.leaderFirstname, lastName: group.leaderLastname }));
        setGroupLeaders(leaders);
      }
    } catch (err) {
      console.error(err);
    }
  }, [CHURCH_ID]);

  React.useEffect(() => {
    getGroupsReport();
  }, [getGroupsReport]);

  return (
    <Grid container justifyContent="center">
      <GroupsFilter
        setGroupsReport={setGroupsReport}
        groups={groupsReport}
        groupsLeaders={groupsLeaders}
        churchId={CHURCH_ID}
      />
      <Grid item xs={12} className={classes.tableContainer}>
        <CollapsibleTable
          data={groupsReport}
          firstHeaders={firstHeaders}
          subHeaders={subHeaders}
          firstHeadersFields={firstHeadersFields}
          subHeadersFields={subHeadersFields}
          subTableName={subTableName}
          subTableDataFieldName={subTableDataFieldName}

        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  members: state.members,
  groupsFromRedux: state.groups,
});

GroupsReport.propTypes = {};

export default withRouter(connect(mapStateToProps)(GroupsReport));
