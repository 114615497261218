import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { getToken, decodeToken, getGlobalRules } from "./auth";
import { setSession } from "./redux/slices/sessionSlice";
import { setLanguage } from "redux/slices/languageSlice";
import { lSlanguageName } from "config/config";
import { setAuthorizationToken } from "tools/api";

const token = getToken();
if (token) {
  setAuthorizationToken(token);
  const decoded = decodeToken(token);
  if (decoded) {
    const rules = getGlobalRules(decoded.authorities);
    store.dispatch(setSession({ ...decoded, rules: rules }));
  }
}

if (localStorage.getItem(lSlanguageName)) {
  store.dispatch(setLanguage(localStorage.getItem(lSlanguageName)));
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
