import React from "react";
import { Grid, Box, Typography, makeStyles } from "@material-ui/core";
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import {
  CTextField
} from "components";

import translate from "i18nProvider/translate";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "1000px",
    margin: "30px auto",
  },
}));

const UserSchema = Yup.object().shape({
  firstName: Yup.string().required(`Can not be empty.`),
  lastName: Yup.string().required(`Can not be empty.`),
  email: Yup.string().email('Invalid email').required('Can not be empty.'),
  phone: Yup.string().length(10, 'Debe ser de 10 digitos').required('Requerido')
});

const UserForm = ({ initialValues, handleNext, buttonsSection }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={UserSchema}
      onSubmit={handleNext}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur
      }) => (
        <Form>
          <Box className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <CTextField
                  label="firstname"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                  error={(errors.firstName && touched.firstName)}
                  helperText={errors.firstName && touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12}>
                <CTextField
                  label="lastname"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                  error={(errors.lastName && touched.lastName)}
                  helperText={errors.lastName && touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={12}>
                <CTextField
                  type="email"
                  label="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={(errors.email && touched.email)}
                  helperText={errors.email && touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12}>
                <CTextField
                  label="phone"
                  name="phone"
                  onChange={ev => !isNaN(ev.target.value) && handleChange(ev)}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={(errors.phone && touched.phone)}
                  helperText={errors.phone && touched.phone && errors.phone}
                />
              </Grid>
            </Grid>
          </Box>
          {buttonsSection}
        </Form>
      )}
    </Formik>
  );
};

export default withRouter(UserForm);
