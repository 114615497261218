import { call } from "../tools/api";

/**
 * @class
 */
class ReportsService {
  constructor(churchId) {
    this.churchId = churchId;
    this.basePath = `/churches/${this.churchId}`;
  }

  getMembersReport = async (filterData) => {
    return await call(
      "get",
      `${this.basePath}/reports/members?joiningstartdate=${filterData.joiningStartDate}&joiningenddate=${filterData.joiningEndDate}&statusid=${filterData.statusid}&baptized=${filterData.baptized}&jrxmlfilename=${filterData.jrxmlFilename}`,
      undefined,
      { responseType: filterData.jrxmlFilename ? "blob" : undefined }
    );
  };

  getGroupMembersReport = async (groupId, filterData = {}) => {
    return await call(
      "get",
      `${this.basePath}/groups/${groupId}/reports/members?joiningstartdate=${filterData.joiningStartDate}&joiningenddate=${filterData.joiningEndDate}&statusid=${filterData.statusid}&baptized=${filterData.baptized}&jrxmlfilename=${filterData.jrxmlFilename}`,
      undefined,
      { responseType: filterData.jrxmlFilename ? "blob" : undefined }
    );
  };

  getTransactionsReport = async (filterData) => {
    return await call(
      "get",
      `${this.basePath}/reports/transactions?transtartdate=${filterData.tranStartDate}&tranenddate=${filterData.tranEndDate}&trantype=${filterData.transactionType}&paymentmethod=${filterData.paymentMethod}&memberid=${filterData.memberId}&jrxmlfilename=${filterData.jrxmlFilename}&groupid=${filterData.groupId}`,
      undefined,
      { responseType: filterData.jrxmlFilename ? "blob" : undefined }
    );
  };

  getGroupTransactionsReport = async (groupId, filterData) => {
    return await call(
      "get",
      `${this.basePath}/groups/${groupId}/reports/transactions?transtartdate=${filterData.tranStartDate}&tranenddate=${filterData.tranEndDate}&trantype=${filterData.transactionType}&paymentmethod=${filterData.paymentMethod}&memberid=${filterData.memberId}&jrxmlfilename=${filterData.jrxmlFilename}`,
      undefined,
      { responseType: filterData.jrxmlFilename ? "blob" : undefined }
    );
  };

  getProgramsReport = async (filterData) => {
    return await call(
      "get",
      `${this.basePath}/reports/programs?programid=${filterData.programId}&programstartdate=${filterData.programStartDate}&programenddate=${filterData.programEndDate}&jrxmlfilename=${filterData.jrxmlFilename}&groupid=${filterData.groupId}`,
      undefined,
      { responseType: filterData.jrxmlFilename ? "blob" : undefined }
    );
  };

  getGroupProgramsReport = async (groupId, filterData) => {
    return await call(
      "get",
      `${this.basePath}/groups/${groupId}/reports/programs?programid=${filterData.programId}&programstartdate=${filterData.programStartDate}&programenddate=${filterData.programEndDate}&jrxmlfilename=${filterData.jrxmlFilename}`,
      undefined,
      { responseType: filterData.jrxmlFilename ? "blob" : undefined }
    );
  };

  getGroupsReport = async (filterData) => {
    return await call(
      "get",
      `${this.basePath}/reports/groups?groupid=${filterData.groupId}&leaderid=${filterData.leaderId}&jrxmlfilename=${filterData.jrxmlFilename}`,
      undefined,
      { responseType: filterData.jrxmlFilename ? "blob" : undefined }
    );
  };
}

export default ReportsService;
