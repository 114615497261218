import {
  addChurches,
  updateChurch,
} from "redux/slices/churchesSlice";
import { call } from "../tools/api";
import { showAlertSuccess } from "../redux/slices/alertsSlice";
import translate from "i18nProvider/translate";
import { addChurchAccess } from 'redux/slices/sessionSlice';

/**
 * @class
 */
class ChurchesService {
  /**
   * constructor of ChurchesService
   */
  constructor() {
    this.basePath = "/churches";
  }

  /**
   * @returns {Church[]} returns Church[]
   */
  getAllChurches = () => async (dispatch) => {
    try {
      const res = await call("get", this.basePath);
      dispatch(addChurches(res));
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @param {Church} church object of Church class
   */
  create = (church) => async (dispatch) => {
    try {
      const res = await call("post", this.basePath, church);
      const newAccess = {
        church: res,
        roles: []
      };
      dispatch(addChurchAccess(newAccess));
      dispatch(showAlertSuccess(translate("churchSeccessfullyCreated")));
    } catch (error) {
      console.log(error);
    }
  };

  editChurch = (church) => async (dispatch) => {
    try {
      await call("put", `${this.basePath}/${church.id}`, church);
      dispatch(updateChurch(church));
      dispatch(showAlertSuccess(translate("churchSeccessfullyEdited")));
    } catch (error) {
      console.log(error);
    }
  };

  toggleChurchStatus = (church) => async (dispatch) => {
    try {
      const active = !church.active;
      await call("post", `${this.basePath}/${church.id}/status/toggle`);
      dispatch(updateChurch({ ...church, active: active }));
      dispatch(showAlertSuccess(translate("churchSeccessfullyEdited")));
    } catch (error) {
      console.log(error);
    }
  };
}

export default ChurchesService;
