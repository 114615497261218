import React, { useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles, Box } from "@material-ui/core";
import { connect } from "react-redux";
import { removeAlert } from "../../redux/slices/alertsSlice";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

const ShowAlert = ({ alert, dispatch }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (alert.show) {
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        dispatch(removeAlert());
      }, 5000);
    }
  }, [alert, dispatch]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Box className={classes.root}>
      <Snackbar
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Alert onClose={handleClose} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  alert: state.alerts,
});

export default connect(mapStateToProps)(ShowAlert);
