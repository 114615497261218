import { call } from "../tools/api";
import { getStatus } from "../redux/slices/statusSlice";

/**
 * @class
 */
class StatusService {
  constructor() {
    this.basePath = `/status`;
  }

  getStatus = () => async (dispatch) => {
    try {
      const res = await call("get", this.basePath);
      dispatch(getStatus(res));
    } catch (error) {
      console.log(error);
    }
  };
}

export default StatusService;
