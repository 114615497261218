import React, { Suspense } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";
import { CheckRequest } from "./auth";

import { SignUp as SignUpView, NotFound as NotFoundView } from "./views";
import CreateEditProgram from "./views/ProgramsView/components/CreateEditPrograms";
const ForgotPassword = React.lazy(() => import("./views/ForgotPassword"));
const CreateEditChurch = React.lazy(() =>
  import("./views/Church/CreateEditChurch")
);
const MembersView = React.lazy(() => import("./views/MembersView"));
const MemberEdit = React.lazy(() => import("./views/MembersView/MemberEdit"));
const UsersView = React.lazy(() => import("./views/UsersView"));
const GroupsView = React.lazy(() => import("./views/GroupsView"));
const GroupForm = React.lazy(() => import("./views/GroupsView/GroupForm"));
const ProgramsView = React.lazy(() => import("./views/ProgramsView"));
const TransactionsView = React.lazy(() => import("./views/TransactionsView"));
const ReportsView = React.lazy(() => import("./views/ReportsView"));
// const SettingsView = React.lazy(() => import("./views/SettingsView"));
const AccessManager = React.lazy(() => import("./views/AccessManager"));
const SignInView = React.lazy(() => import("./views/SignInView"));
const HomeView = React.lazy(() => import("./views/HomeView"));
const ManageGroup = React.lazy(() => import("./views/ManageGroup"));
const ProgramPart = React.lazy(() => import("./views/ProgramsPart"));
const LeadersGroupView = React.lazy(() =>
  import("./views/GroupsView/components/Leaders")
);
const LeadersChurchView = React.lazy(() => import("./views/Church/components/Leaders"));
const CreateAccount = React.lazy(() =>
  import("./views/SignUpView/CreateAccount/CreateAccount")
);

const Routes = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Redirect exact from="/" to="/home" />
        <RouteWithLayout
          component={HomeView}
          exact
          layout={MainLayout}
          path="/home"
        />
        <RouteWithLayout
          component={CreateEditChurch}
          exact
          layout={MainLayout}
          path="/churches/add"
        />
        <RouteWithLayout
          component={AccessManager}
          exact
          layout={MainLayout}
          path="/access-management"
        />
         <RouteWithLayout
          component={ProgramPart}
          exact
          layout={MainLayout}
          path="/Program-Part"
        />
        <RouteWithLayout
          component={MembersView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/members"
        />
        <RouteWithLayout
          component={MemberEdit}
          layout={MainLayout}
          path="/churches/:churchId/members/:memberId/edit"
        />
        <RouteWithLayout
          component={MemberEdit}
          layout={MainLayout}
          path="/churches/:churchId/members/new"
        />
        <RouteWithLayout
          component={UsersView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/users"
        />
        <RouteWithLayout
          component={GroupsView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups"
        />
        <RouteWithLayout
          component={GroupForm}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups/:groupId"
        />
        <RouteWithLayout
          component={GroupForm}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups/new"
        />
        <RouteWithLayout
          component={ManageGroup}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups/:groupId/manage-members"
        />

        <RouteWithLayout
          component={ManageGroup}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups/:groupId/view-members"
        />

        <RouteWithLayout
          component={TransactionsView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups/:groupId/transactions"
        />

        <RouteWithLayout
          component={ProgramsView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/programs"
        />

        <RouteWithLayout
          component={CreateEditProgram}
          exact
          layout={MainLayout}
          path="/churches/:churchId/programs/:programId"
        />


        <RouteWithLayout
          component={ProgramsView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups/:groupId/programs"
        />

        <RouteWithLayout
          component={CreateEditProgram}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups/:groupId/programs/:programId"
        />

        <RouteWithLayout
          component={TransactionsView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/transactions"
        />

        <RouteWithLayout
          component={ReportsView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups/:groupId/reports"
        />

        <RouteWithLayout
          component={ReportsView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/reports"
        />
        <RouteWithLayout
          component={SignUpView}
          exact
          layout={MinimalLayout}
          path="/members/sign-up/:reqId"
        />
        <RouteWithLayout
          component={SignUpView}
          exact
          layout={MinimalLayout}
          path="/users/sign-up/:reqId"
        />
        <RouteWithLayout
          component={SignUpView}
          exact
          layout={MinimalLayout}
          path="/users/change-password/:reqId"
        />
        <RouteWithLayout
          component={ForgotPassword}
          exact
          layout={MinimalLayout}
          path="/users/request-password-change"
        />
        <RouteWithLayout
          component={SignInView}
          exact
          layout={MinimalLayout}
          path="/sign-in"
        />
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path="/not-found"
        />

        <RouteWithLayout
          component={CreateAccount}
          exact
          layout={MinimalLayout}
          path="/create-account"
        />

        <RouteWithLayout
          component={LeadersGroupView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/groups/:groupId/leaders"
        />

        <RouteWithLayout
          component={LeadersChurchView}
          exact
          layout={MainLayout}
          path="/churches/:churchId/leaders"
        />

        <Route component={CheckRequest} exact path="/check-request" />
        <Redirect to="/not-found" />
      </Switch>
    </Suspense>
  );
};

export default Routes;
