import React from "react";
import { TextField } from "@material-ui/core";
import PropTypes from "prop-types";
import translate from "i18nProvider/translate";

const CTextField = ({ label, name, onChange = () => {}, value, ...rest }) => {
  const handleInvalidInput = (e) => {
    e.target.setCustomValidity(translate("filloutfield"));
  };

  return (
    <TextField
      fullWidth
      label={translate(label)}
      margin="dense"
      name={name}
      onChange={onChange}
      value={value || ""}
      variant="outlined"
      onInvalid={handleInvalidInput}
      {...rest}
    />
  );
};

CTextField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CTextField;
