import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Divider, Drawer, Typography, colors } from "@material-ui/core";
import { Add as AddIcon, Settings as SettingsIcon } from "@material-ui/icons";
import { connect } from "react-redux";

import { Profile, SidebarNavChurches, SidebarNavGroups } from "./components";
import translate from "i18nProvider/translate";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 280,
    [theme.breakpoints.up("lg")]: {
      marginTop: 64,
      height: "calc(100% - 64px)",
    },
    background: "linear-gradient(0deg, #ecf6ff 65%, rgba(181,241,224,1) 100%)",
  },
  root: {
    backgroundColor: "inherit",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: theme.spacing(2),
    marginTop: 30,
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: theme.palette.icon,
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
  sidebarTitles: {
    marginLeft: 12,
    marginTop: 12,
  },
  addChurchLabel: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 42,
    color: colors.blueGrey[800],
    textTransform: "none",
    letterSpacing: 0,
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    fontSize: 14,
    paddingLeft: 10,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(38, 50, 56, 0.04)",
    },
  },
  accessManagement: {
    marginTop: 5,
  },
}));

const Sidebar = (props) => {
  const {
    open,
    variant,
    onClose,
    className,
    session: { user, churchAccess, groupAccess },
  } = props;

  const classes = useStyles();

  return (
    <>
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawer }}
        onClose={onClose}
        open={open}
        variant={variant}
      >
        <div className={clsx(classes.root, className)}>
          <Profile user={user} />
          <Divider className={classes.divider} />

          {user?.superAdmin && (
            <>
              <Link to="/churches/add" className={classes.addChurchLabel}  onClick={onClose}>
                <AddIcon />
                <span style={{ marginLeft: 9 }}>
                  {translate("addNewChurch")}
                </span>
              </Link>
              <Link to="/access-management" className={classes.addChurchLabel}  onClick={onClose}>
                <SettingsIcon />
                <span style={{ marginLeft: 9 }}>
                  {translate("accessManagement")}
                </span>
              </Link>
              <Link to="/Program-Part" className={classes.addChurchLabel} onClick={onClose}>
                <SettingsIcon />
                <span style={{ marginLeft: 9 }}>
                  {translate("programPart")}
                </span>
              </Link>
            </>
          )}

          {churchAccess && churchAccess.length > 0 && (
            <>
              <Typography variant="h5" className={classes.sidebarTitles}>
                {translate("myChurches")}
              </Typography>
              <SidebarNavChurches
                className={classes.nav}
                churchAccess={churchAccess}
                onClose={onClose}
                variant={variant}
                showAllOptions={user?.superAdmin}
              />
            </>
          )}
          {groupAccess && groupAccess.length > 0 && (
            <>
              <Typography variant="h5" className={classes.sidebarTitles}>
                {translate("myGroups")}
              </Typography>
              <SidebarNavGroups
                className={classes.nav}
                groupAccess={groupAccess}
                onClose={onClose}
                variant={variant}
              />
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(Sidebar);
