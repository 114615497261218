export const CHURCH_MEMBERS_COUNT_KEY = 'church_members_count';

const getMapfromSessionStorage = (key) => {
    const item = sessionStorage.getItem(key);
    
    if (item) {
        return new Map(JSON.parse(item));
    }

    return null;
};

const saveMapToSessionStorage = (key, map) => {
    const asString = JSON.stringify(Array.from(map.entries()));
    sessionStorage.setItem(key, asString);
};

export const updateChurchMembersCount = (churchId, adding = true) => {
    const id = parseInt(`${churchId}`);
    let membersCountCache = getMapfromSessionStorage(CHURCH_MEMBERS_COUNT_KEY);

    const number = adding ? 1 : -1;

    if (membersCountCache) {
        const count = membersCountCache.get(id);
        
        if (count) {
            membersCountCache.set(id, count + number);
        } else {
            membersCountCache.set(id, number);
        }
    } else {
        membersCountCache = new Map();
        membersCountCache.set(id, number);
    }

    saveMapToSessionStorage(CHURCH_MEMBERS_COUNT_KEY, membersCountCache);
};

export const getChurchMembersCountMap = () => getMapfromSessionStorage(CHURCH_MEMBERS_COUNT_KEY);