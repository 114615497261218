import axios from "axios";
import { addUsers, updateUser, deleteUser } from "../redux/slices/usersSlice";
import { call } from "../tools/api";
import { showAlertSuccess } from "../redux/slices/alertsSlice";
import translate from "i18nProvider/translate";
/**
 * @class
 */
class UsersService {
  /**
   * UsersService constructor
   * @param {number} churchId id of the church
   */
  constructor(churchId) {
    this.churchId = churchId;
    this.basePath = `/churches/${this.churchId}/users`;
  }

  /**
   * returns all user for given church
   * @returns {User[]} returns User[]
   */
  getUsersByChurchId = () => async (dispatch) => {
    try {
      const res = await call("get", this.basePath);
      dispatch(addUsers(res));
    } catch (error) {
      console.log(error);
    }
  };

  getUsersRequestsByChurchId = async () => {
    return await call("get", `${this.basePath}/users-requests`);
  };

  deleteUserRequest = async (userId) => {
    return await call("delete", `${this.basePath}/users-requests/${userId}`);
  };

  checkRequest = async (reqId) => {
    const path = `/churches/users/check-request?reqId=${reqId}`;
    return await call("get", path);
  };

  requestPasswordChange = async (email) => {
    const path = `/users/request-password`;
    return await call("post", path, { email }, {
      'Content-Type': 'text'
    });
  };

  checkPasswordChangeRequest = async (reqId) => {
    const path = `/users/request-password?requestId=${reqId}`;
    return await call("get", path);
  };

  registerUser = async (reqId, user) => {
    const path = `/churches/users/register?reqId=${reqId}`;
    return await call("post", path, user);
  };

  changePassword = async (user) => {
    const path = `/users/change-password`;
    return await call("post", path, user);
  };

  /**
   * @param {number} churchId
   * @param {User} user object of User class
   */
  create = async (user) => {
    return await axios.post(this.basePath, user);
  };

  /**
   * @param {number} userId
   * @param {User} user object of User class
   */
  update = (userId, user) => async (dispatch) => {
    try {
      const res = await call("put", `${this.basePath}/${userId}`, user);
      dispatch(updateUser(res));
      dispatch(showAlertSuccess(translate("userSuccessfullyEdited")));
    } catch (error) {
      console.log(error);
    }
  };

  toggleUserStatus = (userId, user) => async (dispatch) => {
    try {
      await call("put", `${this.basePath}/${userId}/togglestatus`);
      dispatch(updateUser(user));
      dispatch(showAlertSuccess(translate("userSuccessfullyEdited")));
    } catch (error) {
      console.log(error);
    }
  };

  deleteUser = (userId) => async (dispatch) => {
    try {
      await call("delete", `${this.basePath}/${userId}`);
      dispatch(deleteUser(userId));
      dispatch(showAlertSuccess(translate("userSuccessfullyDeleted")));
    } catch (error) {
      console.log(error);
    }
  };
}

export default UsersService;
