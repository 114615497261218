import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Fab } from "@material-ui/core";

import { SearchFilter } from "components";
import AddIcon from "@material-ui/icons/Add";
import { injectIntl } from "react-intl";
import translate from "i18nProvider/translate";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
  },
  spacer: {
    flexGrow: 1,
  },
  importButton: {
    marginRight: theme.spacing(1),
  },
  exportButton: {
    marginRight: theme.spacing(1),
  },
  searchInput: {
    marginTop: theme.spacing(1),
    maxWidth: 400,
  },
  iconBtn: {
    cursor: "pointer",
    color: '#00bfa5',
    backgroundColor: '#ffffff',
    marginRight: 10
  },
}));

const GroupsToolbar = injectIntl(
  ({
    props,
    className,
    onAddBtnClick,
    dataToSearch = [],
    setDataOnSearch = () => {},
    keysToSearch = [],
    ...rest
  }) => {
    const classes = useStyles();

    return (
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.row}>
          <span className={classes.spacer} />
          <Fab
            size="small"
            color="primary"
            onClick={onAddBtnClick}
            title={translate("addNewGroup")}
            className={classes.iconBtn}
          >
            <AddIcon />
          </Fab>
        </div>
        <div className={classes.searchInput}>
          <SearchFilter
            dataToSearch={dataToSearch}
            setDataOnSearch={setDataOnSearch}
            keysToSearch={keysToSearch}
          />
        </div>
      </div>
    );
  }
);

GroupsToolbar.propTypes = {
  className: PropTypes.string,
};

export default GroupsToolbar;
