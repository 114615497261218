import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Grid, Typography, colors } from "@material-ui/core";
import { Add as AddIcon } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { injectIntl } from "react-intl";

import { ChurchCard, GroupCard } from "components";
import translate from "i18nProvider/translate";
import { setSession } from "redux/slices/sessionSlice";
import { useFetch } from "hooks/useFetch";
import EmptyPage from "./components/EmptyStateView/EmptyPage";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4),
  },
  groupsContainer: {
    marginTop: 25,
  },
  churchesContainer: {},
  addChurchLabel: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: 42,
    color: colors.blueGrey[800],
    textTransform: "none",
    fontWeight: theme.typography.fontWeightMedium,
    fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
    fontSize: 16,
    paddingLeft: 10,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "rgba(38, 50, 56, 0.04)",
    },
  },
}));

const HomeView = injectIntl(({ session }) => {
  const classes = useStyles();
  const { churchAccess, groupAccess } = session;

  return (
    <div className={classes.content}>
      {session.user?.superAdmin && churchAccess && churchAccess.length < 1 && (
        <Link to="/churches/add" className={classes.addChurchLabel}>
          <AddIcon />
          <span style={{ marginLeft: 9 }}>{translate("addNewChurch")}</span>
        </Link>
      )}
      {churchAccess && churchAccess.length > 0 && (
        <Grid container spacing={3} className={classes.churchesContainer}>
          <Grid item sm={12}>
            <Typography variant="h4">{translate("myChurches")}</Typography>
          </Grid>
          {churchAccess.map((access) => (
            <Grid item key={access.church.id} lg={3} md={4} sm={6} xs={12}>
              <ChurchCard
                church={access.church}
                roles={access.roles}
                showAllOptions={session.user?.superAdmin}
                showMembersCount={false}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {groupAccess && groupAccess.length > 0 && (
        <Grid container spacing={3} className={classes.groupsContainer}>
          <Grid item sm={12}>
            <Typography variant="h4">{translate("myGroups")}</Typography>
          </Grid>
          {groupAccess.map((access) => (
            <Grid item key={access.group.id} lg={3} md={4} sm={6} xs={12}>
              <GroupCard
                group={access.group}
                roles={access.roles}
                showAllOptions={session.user?.superAdmin}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {!session.user?.superAdmin && !churchAccess?.length && !groupAccess?.length && (
        <EmptyPage />
      )}
    </div>
  );
});

const mapStateToProps = (state) => ({
  session: state.session,
});

export default connect(mapStateToProps)(HomeView);
