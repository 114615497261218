import { createSlice } from "@reduxjs/toolkit";

const alertsState = {
  show: false,
  message: "",
  severity: "",
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState: alertsState,
  reducers: {
    showAlertSuccess(state, action) {
      state.show = true;
      state.message = action.payload;
      state.severity = "success";
    },
    showAlertError(state, action) {
      state.show = true;
      state.message = action.payload;
      state.severity = "error";
    },
    showAlertInfo(state, action) {
      state.show = true;
      state.message = action.payload;
      state.severity = "info";
    },
    showAlertWarning(state, action) {
      state.show = true;
      state.message = action.payload;
      state.severity = "warning";
    },

    removeAlert(state, action) {
      state.show = false;
      state.message = "";
    },
  },
});

export const {
  showAlertSuccess,
  showAlertError,
  removeAlert,
  showAlertInfo,
  showAlertWarning
} = alertsSlice.actions;

export default alertsSlice.reducer;
