const INVALID_CREDENTIALS_CODE = 400;
const UNAUTHORIZED_ERROR_CODE = 401;
const FORBIDDEN_ERROR_CODE = 403;
const NOT_FOUND_ERROR_CODE = 404;
const NOT_ACCEPTABLE_ERROR_CODE = 406;
const VALIDATION_ERROR_CODE = 422;
const UNEXPECTED_ERROR_CODE = 500;

class ChurchEnLineaError extends Error {
  constructor(message, messageType) {
    super(message);
    this.name = "ChurchEnLineaError";
    this.type = UNEXPECTED_ERROR_CODE;
    this.messageType = messageType;
  }

  getMessage() {
    return this.message;
  }

  getMessageType() {
    return this.messageType;
  }

  getType() {
    return this.type;
  }

  getName() {
    return this.name;
  }
}

class InvalidCredentialsError extends ChurchEnLineaError {
  constructor(message) {
    super(message);
    this.name = "InvalidCredentialsError";
    this.type = INVALID_CREDENTIALS_CODE;
  }
}

class UnauthorizedError extends ChurchEnLineaError {
  constructor(message, messageType) {
    super(message);
    this.name = "UnauthorizedError";
    this.type = UNAUTHORIZED_ERROR_CODE;
    this.messageType = messageType;
  }
}

class ValidationError extends ChurchEnLineaError {
  constructor(message, messageType) {
    super(message);
    this.name = "ValidationError";
    this.type = VALIDATION_ERROR_CODE;
    this.messageType = messageType;
  }
}

class NotFoundError extends ChurchEnLineaError {
  constructor(message) {
    super(message);
    this.name = "NotFoundError";
    this.type = NOT_FOUND_ERROR_CODE;
    this.messageType = "error";
  }
}

class UnexpectedError extends ChurchEnLineaError {
  constructor(message, messageType) {
    super(message);
    this.name = "UnexpectedError";
    this.type = UNEXPECTED_ERROR_CODE;
    this.messageType = messageType;
  }
}

class ForbiddenError extends ChurchEnLineaError {
  constructor(message, messageType) {
    super(message);
    this.name = "ForbiddenError";
    this.type = FORBIDDEN_ERROR_CODE;
    this.messageType = messageType;
  }
}

class NotAcceptableError extends ChurchEnLineaError {
  constructor(message, messageType) {
    super(message);
    this.name = "NotAcceptableError";
    this.type = NOT_ACCEPTABLE_ERROR_CODE;
    this.messageType = messageType;
  }
}

export {
  InvalidCredentialsError,
  UnauthorizedError,
  ValidationError,
  NotFoundError,
  UnexpectedError,
  ForbiddenError,
  NotAcceptableError,
  UNAUTHORIZED_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
  UNEXPECTED_ERROR_CODE,
  VALIDATION_ERROR_CODE,
  FORBIDDEN_ERROR_CODE,
  NOT_ACCEPTABLE_ERROR_CODE,
  INVALID_CREDENTIALS_CODE,
};
