/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, Button, colors } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  MonetizationOn as MoneyIcon,
  EventNote as EventNoteIcon,
  Equalizer as EqualizerIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  People as PeopleIcon,
  AccountCircle as AccountCircleIcon,
  Settings,
  Accessibility as AccessibilityIcon
} from "@material-ui/icons";
import translate from "i18nProvider/translate";

const useStyles = makeStyles((theme) => ({
  root: {},
  rootItem: {
    display: "inline-block",
    width: "90%",
    textAlign: "left",
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    justifyContent: "space-between",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  truncateText: {
    display: "flex",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
    backgroundColor: "#BDBDBD",
    "& div.label": {
      color: theme.palette.primary.main,
    },
  },
  nested: {
    paddingLeft: theme.spacing(3),
    paddingTop: 3,
    paddingBottom: 3,
  },
  nestedButton: {
    display: "flex",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    color: colors.blueGrey[700],
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNavChurches = (props) => {
  const {
    churchAccess = [],
    showAllOptions = false,
    className,
    onClose,
    variant,
    ...rest
  } = props;

  const classes = useStyles();

  const [currentMenu, setCurrentMenu] = React.useState("");

  const handleClick = (menu) => {
    setCurrentMenu(menu === currentMenu ? "" : menu);
  };

  const handleSubMenuClick = () => {
    if (variant === "temporary") {
      onClose();
    }
  };

  const authorize = (roles, moduleName) =>
    showAllOptions || roles.some((role) => role.moduleName === moduleName);

  const churchOptions = (churchId, roles) => {
    const options = [];
    if (authorize(roles, "CHURCH_MEMBERS")) {
      options.push({
        label: translate("members"),
        ref: `/churches/${churchId}/members`,
        icon: <PeopleIcon />,
      });
    }
    if (authorize(roles, "CHURCH_USERS")) {
      options.push({
        label: translate("users"),
        ref: `/churches/${churchId}/users`,
        icon: <AccountCircleIcon />,
      });
    }
    if (authorize(roles, "GROUP_MANAGEMENT")) {
      options.push({
        label: translate("groups"),
        ref: `/churches/${churchId}/groups`,
        icon: <SupervisedUserCircleIcon />,
      });
    }
    if (authorize(roles, "CHURCH_PROGRAMS")) {
      options.push({
        label: translate("programs"),
        ref: `/churches/${churchId}/programs`,
        icon: <EventNoteIcon />,
      });
    }
    if (authorize(roles, "CHURCH_TRANSACTIONS")) {
      options.push({
        label: translate("transactions"),
        ref: `/churches/${churchId}/transactions`,
        icon: <MoneyIcon />,
      });
    }
    if (authorize(roles, "CHURCH_REPORTS")) {
      options.push({
        label: translate("reports"),
        ref: `/churches/${churchId}/reports`,
        icon: <EqualizerIcon />,
      });
    }
    if (authorize(roles, "CHURCH_LEADERS")) {
      options.push({
        label: translate("leaders"),
        ref: `/churches/${churchId}/leaders`,
        icon: <AccessibilityIcon />,
      });
    }
    // if (authorize(roles, "ACCESS_MANAGEMENT")) {
    //   options.push({
    //     label: translate("accessManagement"),
    //     ref: `/churches/${churchId}/access-management`,
    //     icon: <Settings />,
    //   });
    // }
    return options;
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {churchAccess.map((access) => {
        const { church, roles } = access;
        const open = currentMenu === church.name || churchAccess.length === 1;
        return (
          <div key={church.name}>
            <ListItem className={classes.item} disableGutters>
              <Button
                //activeClassName={classes.active}
                className={classes.button}
                onClick={() => handleClick(church.name)}
                title={church.name}
              >
                <div className={classes.rootItem}>
                  <div className={classes.truncateText}>
                    <span style={{ marginRight: "10px" }}>{access.icon}</span>
                    {church.name}
                  </div>
                </div>
                {open ? <ExpandLess /> : <ExpandMore />}
              </Button>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {churchOptions(church.id, roles).map((option, i) => (
                  <ListItem className={classes.nested} key={i}>
                    <Button
                      onClick={handleSubMenuClick}
                      //activeClassName={classes.active}
                      className={classes.nestedButton}
                      component={CustomRouterLink}
                      to={option.ref}
                    >
                      <div className={classes.icon}>{option.icon}</div>
                      <div className="label">{option.label}</div>
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        );
      })}
    </List>
  );
};

SidebarNavChurches.propTypes = {
  className: PropTypes.string,
  churchAccess: PropTypes.array.isRequired,
};

export default SidebarNavChurches;
