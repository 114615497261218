import React from "react";
import translate from "i18nProvider/translate";
import './NotFound.css';


const EmptyPage = () => {
  return (
    <div id="notfound">
      <div className="notfound">
        <div className="notfound-404">
          <h1>Oops!</h1>
          <h2>{translate("noResourcesFound")}</h2>
        </div>
        <h3>{translate("contactLeader")}</h3>
      </div>
    </div>
  );
};

export default EmptyPage;
