import React, { useState, forwardRef, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { Slide, Grid } from "@material-ui/core";

import UsersToolbar from "../UsersToolbar";
import { DynamicTable, UserForm, Confirm } from "components";
import { User } from "models";
import { connect } from "react-redux";
import { UsersService } from "services";
import translate from "i18nProvider/translate";
import { injectIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  content: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      // width: "90%",
      maxWidth: "1600px",
      margin: "0 auto",
    },
  },
}));

const headers = [
  "active",
  "firstname",
  "lastname",
  "personalId",
  "email",
  "roles",
];
const fields = ["firstName", "lastName", "persanalId", "email", "roles"];

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RegisteredUsers = injectIntl(
  ({ dispatch, churchId, users = [], session = {}, roles = [] }) => {
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);

    const [user, setUser] = useState(new User());
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [fetchedUsers, setFetchedUsers] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const fetchUsers = React.useCallback(() => {
      dispatch(new UsersService(churchId).getUsersByChurchId());
    }, [churchId, dispatch]);

    const handleClose = () => {
      setOpen(false);
    };

    const handleRowClick = (userParam) => {
      let same = user.id === userParam.id;

      const userParamRoles = userParam.roles || "";
      const roles = userParamRoles.split(",");
      for (let i = 0; i < roles.length; i++) {
        roles[i] = roles[i].replace(/\s/g, "");
      }
      setUser(same ? new User() : userParam);
      setSelectedRoles(roles);
      setEditMode(!same);
    };

    const handlePageChange = (event, page) => {
      setPage(page);
    };
    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(event.target.value);
    };

    const handleRoleChange = (event) => {
      let role = event.target.value;
      let nRoles = [];

      if (selectedRoles.includes(role)) {
        nRoles = selectedRoles.filter((r) => r !== role);
      } else {
        nRoles = selectedRoles.concat([role]);
      }
      setSelectedRoles(nRoles);
    };

    const handleChangeUserStatus = async (e, item) => {
      const alertMessage = item.active
        ? "toggleUserActive"
        : "toggleUserInactive";
      const result = await Confirm.show({
        message: translate(alertMessage),
      });

      if (result) {
        const active = !item.active;
        await dispatch(
          new UsersService(churchId).toggleUserStatus(item.id, {
            ...item,
            active: active,
          })
        );
      }
    };

    const onSubmit = async () => {
      if (selectedRoles.length < 1) {
        window.alert("You must select at least one role");
        return;
      }
      await dispatch(
        new UsersService(churchId).update(user.id, {
          ...user,
          roles: selectedRoles,
        })
      );
      setOpen(false);
    };

    const onDeleteBtnClicked = async () => {
      const result = await Confirm.show({
        message: translate("confirmDeleteUser"),
      });
      if (!result) return;
      await dispatch(new UsersService(churchId).deleteUser(user.id));
      setEditMode(false);
    };

    useEffect(() => {
      fetchUsers();
    }, [fetchUsers]);

    useEffect(() => {
      setFetchedUsers(users);
    }, [users]);

    return (
      <Grid container className={classes.content}>
        <UsersToolbar
          dataToSearch={users}
          setDataOnSearch={setFetchedUsers}
          keysToSearch={fields}
          className={classes.appBar}
          showDelete={editMode}
          showEdit={editMode}
          onDeleteBtnClicked={onDeleteBtnClicked}
          onEditBtnClick={() => setOpen(true)}
        />

        <DynamicTable
          selectedItem={user}
          data={fetchedUsers}
          headers={headers}
          fields={fields}
          count={users.length}
          page={page}
          rowsPerPage={rowsPerPage}
          handleRowClick={handleRowClick}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          showActiveCheckBox={true}
          handleToggleActive={handleChangeUserStatus}
        />

        {open && (
          <UserForm
            handleClose={handleClose}
            member={{ firstName: user.firstName, lastName: user.lastName }}
            open={open}
            transition={Transition}
            selectedRoles={selectedRoles}
            handleRoleChange={handleRoleChange}
            onSubmit={onSubmit}
          />
        )}
      </Grid>
    );
  }
);

const mapStateToProps = (state) => ({
  users: state.users,
  session: state.session,
});

export default connect(mapStateToProps)(RegisteredUsers);
