import { combineReducers } from "@reduxjs/toolkit";
import membersReducer from "./slices/membersSlice";
import statusReducer from "./slices/statusSlice";
import alertsReducer from "./slices/alertsSlice";
import usersReducer from "./slices/usersSlice";
import rolesReducer from "./slices/rolesSlice";
import sessionReducer from "./slices/sessionSlice";
import loadingReducer from "./slices/loadingSlice";
import groupsReducer from "./slices/groupsSlice";
import languageReducer from "./slices/languageSlice";
import churchesReducer from "./slices/churchesSlice";
import modalReducer from "./slices/modalSlice";

const appReducer = combineReducers({
  members: membersReducer,
  status: statusReducer,
  alerts: alertsReducer,
  users: usersReducer,
  roles: rolesReducer,
  session: sessionReducer,
  loading: loadingReducer,
  groups: groupsReducer,
  language: languageReducer,
  churches: churchesReducer,
  modal: modalReducer
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    const { routing } = state;
    state = { routing };
  }
  return appReducer(state, action);
};

export default rootReducer;
