import React from "react";
import {
  Grid,
  Typography,
  Input,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Select,
  Chip,
  Divider,
  Card,
  CardContent,
  IconButton,
  FormGroup,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import translate from "i18nProvider/translate";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  iconBtn: {
    color: "red",
  },
}));

function getStyles(name, list, theme) {
  return {
    marginRight: 20,
    fontWeight:
      list.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ModulesView = ({
  module,
  modulesForDropdown = [],
  selectedModules,
  setSelectedModules,
  handleAccessTypeSelect = () => {},
  handleDelete = () => {},
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleModuleSelect = (event) => {
    setSelectedModules(event.target.value);
  };

  const renderAccessTypes = () => {
    if (module.isNew && !selectedModules.length) {
      return null;
    }

    return (
      <>
        <Divider variant="middle" style={{ margin: 10 }} />
        <Typography variant="subtitle2" style={{ marginBottom: 5 }}>
          {translate("accessTypes")}
        </Typography>
        <FormGroup component="fieldset">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={module.canRead}
                    onChange={(ev) =>
                      handleAccessTypeSelect(ev, module, selectedModules)
                    }
                    name="canRead"
                    color="primary"
                  />
                }
                label={translate("readAccess")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={module.canWrite}
                    onChange={(ev) =>
                      handleAccessTypeSelect(ev, module, selectedModules)
                    }
                    name="canWrite"
                    color="primary"
                  />
                }
                label={translate("writeAccess")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={module.canDelete}
                    onChange={(ev) =>
                      handleAccessTypeSelect(ev, module, selectedModules)
                    }
                    name="canDelete"
                    color="primary"
                  />
                }
                label={translate("deleteAccess")}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={module.canExecute}
                    onChange={(ev) =>
                      handleAccessTypeSelect(ev, module, selectedModules)
                    }
                    name="canExecute"
                    color="primary"
                  />
                }
                label={translate("executeAccess")}
              />
            </Grid>
          </Grid>
        </FormGroup>
      </>
    );
  };

  return (
    <Card>
      <CardContent>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item>
            <Typography variant="subtitle1">
              {translate(module.moduleName)}
            </Typography>
          </Grid>
          <Grid item>
            {/* <Fab
              color="primary"
              title={translate("delete")}
              onClick={() => handleDelete(module)}
              size="small"
              style={{ color: "#DC004E" }}
              className={classes.iconBtn}
            >
              <DeleteIcon />
            </Fab> */}
            <IconButton
              aria-label="delete"
              title={translate("delete")}
              onClick={() => handleDelete(module)}
              className={classes.iconBtn}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        {module.isNew && (
          <Select
            labelId="demo-mutiple-name-label"
            id="demo-mutiple-name"
            multiple
            value={selectedModules}
            onChange={handleModuleSelect}
            input={<Input />}
            renderValue={(selectedModules) => (
              <div className={classes.chips}>
                {selectedModules.map((item, idx) => (
                  <Chip
                    key={`selected-module-${idx}`}
                    label={translate(item.moduleName)}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            style={{ width: "100%" }}
          >
            <MenuItem disabled value="">
              <em>{translate("modules")}</em>
            </MenuItem>
            {modulesForDropdown.map((item) => (
              <MenuItem
                key={item.moduleName}
                value={item}
                style={getStyles(item.moduleName, selectedModules, theme)}
              >
                {translate(item.moduleName)}
              </MenuItem>
            ))}
          </Select>
        )}
        {renderAccessTypes()}
      </CardContent>
    </Card>
  );
};

export default ModulesView;
