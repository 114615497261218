import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  CardHeader,
  IconButton,
  Avatar,
  CardMedia,
  Popover,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";

import {
  MoreVert,
  PeopleOutline,
  EditOutlined,
  People as PeopleIcon,
  MonetizationOn as MoneyIcon,
  EventNote as EventNoteIcon,
  Accessibility as AccessibilityIcon
} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";

import { red } from "@material-ui/core/colors";

import { getInitials } from "helpers";
import { useHistory, withRouter, useParams } from "react-router-dom";
import translate from "i18nProvider/translate";
import { injectIntl } from "react-intl";

const defaultImg = process.env.REACT_APP_DEFAULT_GROUP_IMG;

const useStyles = makeStyles((theme) => ({
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundSize: "cover",
    minWidth: 252,
  },
  media: {
    paddingTop: "56.25%", // 16:9
  },

  avatar: {
    backgroundColor: red[500],
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
    marginTop: "auto",
  },
  menuContainer: {
    padding: theme.spacing(2),
  },
  icons: {
    marginRight: theme.spacing(1),
    backgroundColor: "#ffffff",
  },
  deleteIcon: {
    marginRight: theme.spacing(1),
    backgroundColor: "#ffffff",
    color: 'red'
  },
  description: {
    wordBreak: "break-all",
  },
}));

const GroupCard = injectIntl(
  ({
    className,
    match,
    group,
    roles = [],
    showAllOptions = false,
    onEditClick,
    onDeleteBtnClicked = () => {},
    ...rest
  }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const history = useHistory();
    const churchId = group.churchId;
    

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setAnchorEl(null);
    };

    const handleListKeyDown = (event) => {
      if (event.key === "Tab") {
        event.preventDefault();
        handleClose();
      }
    };

    const onMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
      handleOpen();
    };

    const authorize = (moduleName) =>
      showAllOptions || roles.some((role) => role.moduleName === moduleName);

    return (
      <Card className={classes.card}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              {getInitials(group.name)}
            </Avatar>
          }
          action={
            <IconButton
              aria-label="settings"
              onClick={onMenuClick}
              aria-describedby={group.id}
            >
              <MoreVert />
            </IconButton>
          }
          title={group.name}
          subheader={group.leaderName}
          titleTypographyProps={{ variant: "h6" }}
        />
        <CardMedia
          className={classes.media}
          image={group.picture || defaultImg}
          title={translate("groupImage")}
        />
        <CardContent>
          <Typography
            className={classes.description}
            variant="body1"
            color="textSecondary"
            component="p"
          >
            {group.description || ""}
          </Typography>
          <Popover
            id={group.id}
            anchorEl={anchorEl}
            onClose={handleClose}
            open={open}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  className={classes.menuContainer}
                  autoFocusItem={open}
                  onKeyDown={handleListKeyDown}
                >
                  {authorize("GROUP_MEMBERS") && (
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/churches/${churchId}/groups/${group.id}/view-members`
                        )
                      }
                    >
                      <PeopleIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("members")}
                      </Typography>
                    </MenuItem>
                  )}

                  {authorize("GROUP_PROGRAMS") && (
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/churches/${churchId}/groups/${group.id}/programs`
                        )
                      }
                    >
                      <EventNoteIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("programs")}
                      </Typography>
                    </MenuItem>
                  )}

                  {authorize("GROUP_TRANSACTIONS") && (
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `/churches/${churchId}/groups/${group.id}/transactions`
                        )
                      }
                    >
                      <MoneyIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("transactions")}
                      </Typography>
                    </MenuItem>
                  )}

                  {authorize("GROUP_MANAGEMENT") && (
                    <MenuItem
                      onClick={() =>
                        history.push(
                          `${history.location.pathname}/${group.id}/manage-members`
                        )
                      }
                    >
                      <PeopleIcon className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("manage")}
                      </Typography>
                    </MenuItem>
                  )}

                  {authorize("GROUP_MANAGEMENT") && (
                    <MenuItem onClick={() => history.push(
                      `/churches/${churchId}/groups/${group.id}`
                    )}>
                      <EditOutlined className={classes.icons} />
                      <Typography variant="subtitle1">
                        {translate("edit")}
                      </Typography>
                    </MenuItem>
                  )}

                  {/*{authorize("GROUP_LEADERS") && (*/}
                  {/*  <MenuItem*/}
                  {/*    onClick={() =>*/}
                  {/*      history.push(*/}
                  {/*        `/churches/${churchId}/groups/${group.id}/leaders`*/}
                  {/*      )*/}
                  {/*    }*/}
                  {/*  >*/}
                  {/*    <AccessibilityIcon className={classes.icons} />*/}
                  {/*    <Typography variant="subtitle1">*/}
                  {/*      {translate("leaders")}*/}
                  {/*    </Typography>*/}
                  {/*  </MenuItem>*/}
                  {/*)}*/}

                  {authorize("GROUP_MANAGEMENT") && (
                    <MenuItem onClick={() => onDeleteBtnClicked(group)}>
                      <DeleteIcon className={classes.deleteIcon} />
                      <Typography variant="subtitle1">
                        {translate("delete")}
                      </Typography>
                    </MenuItem>
                  )}
                  
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Popover>
        </CardContent>

        <CardActions className={classes.cardActions}>
          <PeopleOutline style={{ fontSize: 18 }} />
          <Typography variant="body2" color="textSecondary">
            {group.members} {translate("members")}
          </Typography>
        </CardActions>
      </Card>
    );
  }
);

GroupCard.propTypes = {
  className: PropTypes.string,
  group: PropTypes.object.isRequired,
  // onEditClick: PropTypes.func.isRequired,
};

export default withRouter(GroupCard);
