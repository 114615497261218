import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import translate from "i18nProvider/translate";
import { useFetch } from "hooks/useFetch";
import { hideLoading, showLoading } from "redux/slices/loadingSlice";
import store from "redux/store";

export default function HasUserDialog({
  churchId,
  memberId,
  dtoData,
  handleClose = () => {},
  onSuccess,
  onError,
}) {
  const { isLoading, error, save } = useFetch({
    rootUrl: `/churches/${churchId}/members/${memberId}/import-user`,
    initialValue: {},
  });

  useEffect(() => {
    isLoading ? store.dispatch(showLoading()) : store.dispatch(hideLoading());
  }, [isLoading]);

  const onConfirm = () => {
    save(dtoData, onSuccess);
  };

  useEffect(() => {
    if (error) {
      onError();
    }
  }, [error]);

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate("memberHasUserAlreadyTitle")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate("memberHasUserAlreadyBody")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {translate("cancel")}
          </Button>
          <Button onClick={onConfirm} color="primary" autoFocus>
            {translate("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
