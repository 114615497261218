import { createSlice } from "@reduxjs/toolkit";

const usersSlice = createSlice({
  name: "users",
  initialState: [],
  reducers: {
    addUsers(state, action) {
      return action.payload;
    },
    addUser(state, action) {
      state.push(action.payload);
    },
    updateUser(state, action) {
      const index = state.findIndex((user) => user.id === action.payload.id);
      if (index > -1) {
        state[index] = action.payload;
      }
    },
    deleteUser(state, action) {
      const index = state.findIndex((user) => user.id === action.payload);
      if (index > -1) {
        state = state.splice(index, 1);
      }
    },
  },
});

export const { addUsers, addUser, updateUser, deleteUser } = usersSlice.actions;

export default usersSlice.reducer;
