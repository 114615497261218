import { createSlice } from "@reduxjs/toolkit";

const statusSlice = createSlice({
  name: "status",
  initialState: [{ id: 1, name: "ACTIVE" }],
  reducers: {
    getStatus(state, action) {
      return action.payload;
    },
  },
});

export const { getStatus } = statusSlice.actions;

export default statusSlice.reducer;
