import React, { useCallback, useEffect, useState } from "react";
import { NOT_FOUND_ERROR_CODE } from "../tools/errors";
import { getToken, removeToken } from "auth";
import {
  handleJsonResponse,
  handleTextResponse,
  parseError,
} from "tools/fetchUtils";
import store from "redux/store";
import { showAlertError } from "redux/slices/alertsSlice";
import translate from "i18nProvider/translate";

const JSON_CONTENT_TYPE = "application/json";

const ID_IS_REQUIRED_MSG = `'id' is required`;
const ID_MUST_BE_POSITIVE_MSG = `'id' must be a positive number`;

const BASE_PATH = process.env.REACT_APP_BASE_URL;

function dispatchAlertError(errorStr) {
  store.dispatch(showAlertError(translate(errorStr)));
}

export const useFetch = ({
  rootUrl,
  initialValue,
  initialLoadingValue = false,
  v2 = false,
}) => {
  const [isLoading, setLoading] = useState(initialLoadingValue);
  const [data, setData] = useState(initialValue);
  const [error, setError] = useState(null);

  if (!rootUrl) {
    throw Error("rootUrl is required!");
  }

  const updateData = (resp) => {
    if (v2) {
      if (resp.error) {
        setError(resp.error);
      } else {
        setData(resp.data);
      }
    } else {
      setData(resp);
    }
  };

  useEffect(() => {
    if (error) {
      console.log("Error: ", error);
      let message;

      if (error.getMessage) {
        message = error.getMessage();
      } else {
        message = error.message;
      }

      if (message === "invalid_token" || message === "token_expired") {
        removeToken();
        clearErrors();
        //eslint-disable-next-line
        location.href = `/sign-in`;
      } else {
        dispatchAlertError("theActionCouldNotBeComplete");
      }
    }
  }, [error]);

  const doFetch = useCallback(
    (
      options = {
        path: "",
        method: "GET",
        headers: {},
        otherConfigs: {},
        successCallback: null,
        errorCallback: null,
      }
    ) => {
      options.method = options.method || "GET";
      options.headers = options.headers || {};
      options.otherConfigs = options.otherConfigs || {};
      options.path = options.path || "";
      if (options.body) {
        options.otherConfigs.body = options.body;
      }

      const config = {
        method: options.method,
        headers: {
          "Content-Type": JSON_CONTENT_TYPE,
          Accept: JSON_CONTENT_TYPE,
          Authorization: `Bearer ${getToken()}`,
          ...options.headers,
        },
        ...options.otherConfigs,
      };

      setLoading(true);

      const resp = fetch(`${BASE_PATH}${rootUrl}${options.path}`, config);

      if (options.method !== "DELETE") {
        return resp
          .then(handleJsonResponse)
          .then((resp) => {
            if (options.successCallback) {
              options.successCallback(resp);
            }
            setData(resp);
            clearErrors();
          })
          .catch((error) => {
            if (options.errorCallback) {
              options.errorCallback(error);
            }
            setError(error);
          })
          .finally(() => setLoading(false));
      }

      return resp
        .then((resp) => {
          if (resp.ok) {
            options.successCallback && options.successCallback(resp);
          } else {
            return parseError(resp);
          }
        })
        .catch(options.errorCallback || (() => {}));
    },
    [rootUrl]
  );

  const fetchRoot = useCallback(
    (options) => {
      options = options || {
        headers: {
          "Content-Type": JSON_CONTENT_TYPE,
          Authorization: `Bearer ${getToken()}`,
        },
      };
      setLoading(true);
      return fetch(`${BASE_PATH}${rootUrl}`, options)
        .then(handleJsonResponse)
        .then(updateData)
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [rootUrl]
  );

  const fetchById = useCallback(
    (id, options) => {
      options = options || {
        headers: {
          "Content-Type": JSON_CONTENT_TYPE,
          Authorization: `Bearer ${getToken()}`,
        },
      };

      if (!id) {
        setError({ message: ID_IS_REQUIRED_MSG, type: NOT_FOUND_ERROR_CODE });
        return;
      }

      if (isNaN(id) || id < 1) {
        setError({
          message: ID_MUST_BE_POSITIVE_MSG,
          type: NOT_FOUND_ERROR_CODE,
        });
        return;
      }

      setLoading(true);
      return fetch(`${BASE_PATH}${rootUrl}/${id}`, options)
        .then(handleJsonResponse)
        .then(updateData)
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [rootUrl]
  );

  const search = useCallback(
    (queryParams, options) => {
      options = options || {
        headers: {
          "Content-Type": JSON_CONTENT_TYPE,
          Authorization: `Bearer ${getToken()}`,
        },
      };

      let params;

      if (queryParams) {
        const urlBuilder = new URLSearchParams(queryParams);
        params = urlBuilder.toString();
      }

      params = params && `?${params}`;

      setLoading(true);

      return fetch(`${BASE_PATH}${rootUrl}${params}`, options)
        .then(handleJsonResponse)
        .then(updateData)
        .catch(setError)
        .finally(() => setLoading(false));
    },
    [rootUrl]
  );

  const save = useCallback(
    (data = {}, successCallback, path = "") => {
      let url = rootUrl + path;
      let method = "POST";
      if (data.id) {
        if (isNaN(data.id) || data.id < 1) {
          setError({
            message: ID_MUST_BE_POSITIVE_MSG,
            type: NOT_FOUND_ERROR_CODE,
          });
          return;
        }
        method = "PUT";
        url = `${url}/${data.id}`;
      }

      setLoading(true);
      return fetch(`${BASE_PATH}${url}`, {
        method: method,
        headers: {
          "Content-Type": JSON_CONTENT_TYPE,
          Authorization: `Bearer ${getToken()}`,
        },
        body: JSON.stringify(data),
      })
        .then(handleJsonResponse)
        .then((resp) => {
          if (successCallback) {
            successCallback(resp);
          } else {
            updateData(resp);
          }
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    },
    [rootUrl]
  );

  const remove = useCallback(
    (id, successCallback) => {
      if (!id) {
        setError({ message: ID_IS_REQUIRED_MSG, type: NOT_FOUND_ERROR_CODE });
        return;
      }

      if (isNaN(id) || id < 1) {
        setError({
          message: ID_MUST_BE_POSITIVE_MSG,
          type: NOT_FOUND_ERROR_CODE,
        });
        return;
      }

      setLoading(true);
      return fetch(`${BASE_PATH}${rootUrl}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": JSON_CONTENT_TYPE,
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then(handleTextResponse)
        .then((resp) => {
          if (successCallback) {
            successCallback(resp);
          } else {
            updateData(resp);
          }
          setLoading(false);
        })
        .catch((err) => {
          setError(err);
          setLoading(false);
        });
    },
    [rootUrl]
  );

  const clearErrors = useCallback(() => setError(null), []);

  return {
    isLoading,
    error,
    data,
    clearErrors,
    setData,
    doFetch,
    fetchRoot,
    fetchById,
    search,
    save,
    remove,
  };
};
