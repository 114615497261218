import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Fab } from "@material-ui/core";

import { SearchFilter } from "components";
import AddIcon from "@material-ui/icons/Add";
import { injectIntl } from "react-intl";
import translate from "i18nProvider/translate";

const useStyles = makeStyles((theme) => ({
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
  },
  spacer: {
    flexGrow: 1,
  },

  searchInput: {
    marginTop: theme.spacing(1),
    maxWidth: 400,
  },
}));

const TransactionsToolbar = injectIntl(
  ({
    className,

    onAddBtnClick,
    dataToSearch = [],
    setDataOnSearch = () => {},
    keysToSearch = [],
    showActions = false,
    ...rest
  }) => {
    const classes = useStyles();

    return (
      <div {...rest} className={clsx(className)}>
        {showActions && (
          <div className={classes.row}>
            <span className={classes.spacer} />
            <Fab
              size="small"
              title={translate("addNewTransaction")}
              color="primary"
              onClick={onAddBtnClick}
            >
              <AddIcon />
            </Fab>
          </div>
        )}
        <div className={classes.searchInput}>
          <SearchFilter
            dataToSearch={dataToSearch}
            setDataOnSearch={setDataOnSearch}
            keysToSearch={keysToSearch}
          />
        </div>
      </div>
    );
  }
);

TransactionsToolbar.propTypes = {
  className: PropTypes.string,
};

export default TransactionsToolbar;
