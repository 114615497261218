import React, { Component } from "react";
import ReactDOM from "react-dom";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import { DialogActions, DialogContent, Box } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { render } from "react-dom";
import { I18nPropvider } from "i18nProvider";
import translate from "i18nProvider/translate";

import store from "redux/store";

let resolve;
const modalRoot = document.getElementById("modal-root");
class Confirm extends Component {
  static create(props = {}) {
    const containerElement = document.createElement("div");
    modalRoot.appendChild(containerElement);

    return render(<Confirm createConfirmProps={props} />, containerElement);
  }

  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      showConfirmProps: {},
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
    this.show = this.show.bind(this);
  }

  handleCancel() {
    this.setState({ isOpen: false });
    resolve(false);
  }

  handleConfirm() {
    this.setState({ isOpen: false });
    resolve(true);
  }

  show(props = {}) {
    const showConfirmProps = { ...this.props.createConfirmProps, ...props };
    this.setState({ isOpen: true, showConfirmProps });
    return new Promise((res) => {
      resolve = res;
    });
  }

  render() {
    const { isOpen, showConfirmProps } = this.state;
    const { message, content, alertType = false } = showConfirmProps;

    return ReactDOM.createPortal(
      <Box>
        <I18nPropvider locale={store.getState().language}>
          <Dialog open={isOpen} onClose={this.handleCancel}>
            <DialogContent>
              <Alert severity="warning">{message}</Alert>
              <Box> {content} </Box>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.handleCancel} color="primary">
                {translate(alertType ? "accept" : "cancel")}
              </Button>
              {!alertType && (
                <Button onClick={this.handleConfirm} color="primary" autoFocus>
                  {translate("yes")}
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </I18nPropvider>
      </Box>,
      modalRoot
    );
  }
}

export default Confirm;
