import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import { TransactionsToolbar, CreateEditTransaction } from "./components";
import { DynamicTable } from "components";
import { Transaction } from "models";
import { TransactionsService, MembersService } from "services";
import { connect } from "react-redux";
import translate from "i18nProvider/translate";
import { showAlertSuccess } from "redux/slices/alertsSlice";
import { injectIntl } from "react-intl";
import { transactionTableFields, transactionTableHeaders } from "helpers";
import { getUserGroupAccess, getUserChurchAccess } from "auth";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const TransactionsView = injectIntl(
  ({ match, members = [], dispatch, session }) => {
    const classes = useStyles();
    const {churchId, groupId} = useParams();
    const { user, groupAccess } = session;

    const [open, setOpen] = useState(false);
    const [transaction, setTransaction] = useState(new Transaction());
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { canWrite } = getUserChurchAccess("CHURCH_TRANSACTIONS", churchId);
    const { canWrite: canWriteGroup } = getUserGroupAccess("GROUP_TRANSACTIONS", groupId);

    const getMembers = React.useCallback(() => {
      if (groupId) {
        dispatch(new MembersService(churchId).getMembersByGroupId(groupId));
      } else {
        dispatch(new MembersService(churchId).getMembersByChurchId());
      }
    }, [dispatch, churchId, groupId]);

    const getTransactions = React.useCallback(async () => {
      try {
        const trans = await new TransactionsService(
          churchId
        ).getTransactions();
        setTransactions(trans);
        setFilteredTransactions(trans);
      } catch (error) {
        console.log(error);
      }
    }, [churchId]);

    const getGroupTransactions = React.useCallback(async () => {
      try {
        const trans = await new TransactionsService(
          churchId
        ).getGroupTransactions(groupId);
        setTransactions(trans);
        setFilteredTransactions(trans);
      } catch (error) {
        console.log(error);
      }
    }, [churchId, groupId]);

    const onAddBtnClick = () => {
      setTransaction(new Transaction());
      setOpen(true);
    };

    const handleRowClick = (trans) => {
      setTransaction(trans);
      setOpen(true);
    };

    const handlePageChange = (event, page) => {
      setPage(page);
    };

    const handleRowsPerPageChange = (event) => {
      setRowsPerPage(event.target.value);
    };

    const updateAfterCreate = (newTransaction) => {
      setTransactions([newTransaction, ...transactions]);
      setFilteredTransactions([newTransaction, ...transactions]);
      dispatch(showAlertSuccess(translate("transactionSuccessfullyCreated")));
    };

    const updatedAfterEdit = (editedTransaction) => {
      const updatedTransactions = [...transactions];
      const transactionIndex = transactions.findIndex((transaction) => {
        return transaction.id === editedTransaction.id;
      });
      if (transactionIndex > -1) {
        updatedTransactions[transactionIndex] = editedTransaction;
        setTransactions(updatedTransactions);
        setFilteredTransactions(updatedTransactions);
      }
      dispatch(showAlertSuccess(translate("transactionSuccessfullyEdited")));
    };

    const authorize = (moduleName) => {
      const roles = groupAccess?.roles || [];
      return (
        user?.superAdmin || roles.some((role) => role.moduleName === moduleName)
      );
    };

    useEffect(() => {
      if (groupId) {
        getGroupTransactions();
      } else {
        getTransactions();
      }
    }, [getGroupTransactions, getTransactions, groupId]);

    useEffect(() => {
      getMembers();
    }, [getMembers]);

    return (
      <div className={classes.root}>
        <TransactionsToolbar
          onAddBtnClick={onAddBtnClick}
          dataToSearch={transactions}
          setDataOnSearch={setFilteredTransactions}
          keysToSearch={transactionTableFields}
          showActions={canWriteGroup || canWrite}
        />
        <div className={classes.content} >
          <DynamicTable
            data={filteredTransactions}
            selectedItem={transaction}
            headers={transactionTableHeaders}
            fields={transactionTableFields}
            count={filteredTransactions.length}
            page={page}
            rowsPerPage={rowsPerPage}
            handleRowClick={handleRowClick}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <CreateEditTransaction
              open={open}
              setOpen={setOpen}
              transactionToEdit={transaction}
              members={members}
              match={match}
              setTransactions={setTransactions}
              updateAfterCreate={updateAfterCreate}
              updatedAfterEdit={updatedAfterEdit}
              showActions={canWriteGroup || canWrite}
            />
          )}
        </div>
      </div>
    );
  }
);

const mapStateToProps = (state) => ({
  members: state.members,
  session: state.session,
});

export default connect(mapStateToProps)(TransactionsView);
