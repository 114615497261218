import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Fab } from "@material-ui/core";

import { SearchFilter } from "components";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },
}));

const AdminToolbar = (props) => {
  const {
    className,
    onAddBtnClicked = () => {},
    setDataOnSearch = () => {},
    dataToSearch = [],
    keysToSearch = [],
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <Fab
          title={"Add new church"}
          onClick={onAddBtnClicked}
          color="primary"
          size="small"
        >
          <AddIcon />
        </Fab>
      </div>
      <div className={classes.row}>
        <SearchFilter
          dataToSearch={dataToSearch}
          setDataOnSearch={setDataOnSearch}
          keysToSearch={keysToSearch}
        />
      </div>
    </div>
  );
};

AdminToolbar.propTypes = {
  className: PropTypes.string,
  onAddBtnClicked: PropTypes.func.isRequired,
};

export default AdminToolbar;
