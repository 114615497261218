import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";

import { DynamicTable, Confirm } from "components";
import { useFetch } from "hooks/useFetch";
import { hideLoading, showLoading } from "redux/slices/loadingSlice";

import Toolsbar from "./Toolsbar";
import RoleManagerDialog from "./RoleManagerDialog";
import RoleForm from "./RoleForm";
import store from "redux/store";
import { showAlertError, showAlertSuccess } from "redux/slices/alertsSlice";
import translate from "i18nProvider/translate";
import { UnauthorizedError } from "tools/errors";

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      maxWidth: "1600px",
      margin: "0 auto",
    },
  },
  appBar: {
    position: "relative",
    marginBottom: theme.spacing(2),
  },
}));

const AccessManager = () => {
  const classes = useStyles();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [item, setItem] = useState({});

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const { isLoading, data, error, fetchRoot, save, setData, remove } = useFetch(
    {
      rootUrl: "/access-management/roles",
      initialValue: [],
    }
  );

  useEffect(() => {
    if (error) {
      if (error instanceof UnauthorizedError) {
        store.dispatch(showAlertError(translate("notAuthorizedError")));
        history.push("/");
      }
    }
  }, [error]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleRowClick = (item) => {
    setItem(item);
    handleOpen();
  };

  const handleSubmit = (formData) => {
    save(formData, (resp) => {
      setData((oldValues) => [...oldValues, resp]);
      setShowForm(false);
      store.dispatch(showAlertSuccess(translate("roleSavedSuccessfully")));
    });
  };

  const handleDelete = async () => {
    const result = await Confirm.show({
      message: translate("confirmRoleDelete"),
    });
    if (!result) return;
    remove(item.id, () => {
      store.dispatch(showAlertSuccess(translate("roleDeletedSuccessfully")));
      setData((oldValues) => [...oldValues.filter((it) => it.id !== item.id)]);
      setItem({});
      setOpen(false);
    });
  };

  useEffect(() => {
    fetchRoot();
  }, []);

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading]);

  return (
    <div className={classes.content}>
      <Toolsbar
        className={classes.appBar}
        onAddBtnClicked={() => setShowForm(true)}
        onCreateBtnClicked={() => {}}
        editMode={editMode}
        dataToSearch={[]}
        setDataOnSearch={[]}
        keysToSearch={[]}
        onDeleteBtnClicked={() => {}}
      />
      <DynamicTable
        headers={["role"]}
        fields={["name"]}
        data={data}
        selectedItem={item}
        count={data.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRowClick={handleRowClick}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
      />
      {item && item.id && (
        <RoleManagerDialog
          role={item}
          opentDialgo={open}
          setOpenDialgo={setOpen}
          onDeleteBtnClicked={handleDelete}
        />
      )}
      {showForm && <RoleForm onSubmit={handleSubmit} />}
    </div>
  );
};

export default AccessManager;
