import React from "react";
import { KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import { DateFormater } from "helpers";
import translate from "i18nProvider/translate";

const CKeyboardDatePicker = ({ label, name, onChange = () => {}, value, ...rest }) => {
  return (
    <KeyboardDatePicker
      fullWidth
      margin="dense"
      label={translate(label)}
      format={DateFormater.getCurrentLanguagetFormat()}
      value={value || Date.now()}
      onChange={(e) => onChange(e, name)}
      KeyboardButtonProps={{
        "aria-label": "change date",
      }}
      {...rest}
    />
  );
};

CKeyboardDatePicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CKeyboardDatePicker;
