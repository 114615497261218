import React, { useState, useEffect } from "react";
import {
  FullScreenDialog,
  CTextField,
  Select,
  MuiPickersUtils,
  CKeyboardDatePicker,
} from "components";
import { Grid, Box, TextareaAutosize } from "@material-ui/core";
import { Member } from "models";
import { StatusService, MembersService } from "services";
import { connect } from "react-redux";
import translate from "i18nProvider/translate";
import { withRouter, useHistory } from "react-router-dom";
import { baptizedOptions } from "helpers";
import { useFetch } from "hooks/useFetch";
import { getUserChurchAccess } from "auth/rulesHandler"

const MembersDialog = ({
  dispatch,
  status,
  match,
}) => {
  const [member, setMember] = useState(new Member());
  const history = useHistory();
  const { churchId: CHURCH_ID, memberId } = match.params;
  const { canWrite } = getUserChurchAccess("CHURCH_MEMBERS",CHURCH_ID );

  const { doFetch } = useFetch({ rootUrl: `/churches/${CHURCH_ID}/members` });

  const fetchStatus = React.useCallback(async () => {
    await dispatch(new StatusService().getStatus());
  }, [dispatch]);

  useEffect(() => {
    if (memberId) {
        doFetch({
            path: `/${memberId}`,
            successCallback: (resp) => setMember(resp)
        });
    }

    fetchStatus();
  }, [memberId]);

  const handleChange = (event) => {
    event.target.setCustomValidity("");
    setMember({
      ...member,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickSave = () => {
    if (member.joiningDate === "") {
      member.joiningDate = new Date();
    }
    const memberInfo = {
      ...member,
      email: member.email ? member.email : null,
      personalId: member.personalId ? member.personalId : null,
      status: status.filter(item => item.id === member.statusId)[0]
    };

    if (member.id) {
      dispatch(
        new MembersService(CHURCH_ID).updateMember(member.id, memberInfo)
      );
    } else {
      dispatch(new MembersService(CHURCH_ID).create(memberInfo));
    }
    history.goBack();
  };

  const handleDateChange = (date, name) => {
    setMember({
      ...member,
      [name]: date,
    });
  };

  return (
    <FullScreenDialog
      title={translate("membersformTitle")}
      handleClose={() => history.goBack()}
      open={true}
      onSubmit={canWrite ? handleClickSave : null}
      saveBtnLabel={"save"}
      actionsDisabled={!canWrite}
    >
      <Box style={{ width: "90%", maxWidth: "1000px", margin: "0 auto" }}>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label="firstname"
              name="firstName"
              onChange={handleChange}
              value={member.firstName}
              disabled={!canWrite}
              required
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label="lastname"
              name="lastName"
              onChange={handleChange}
              value={member.lastName}
              disabled={!canWrite}
              required
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label="identification"
              name="personalId"
              onChange={handleChange}
              value={member.personalId || ""}
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              type="email"
              label="email"
              name="email"
              onChange={handleChange}
              value={member.email || ""}
              onInvalid={(e) =>
                e.target.setCustomValidity(translate("writeValidEmail"))
              }
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label="phoneNumber1"
              name="phone"
              onChange={handleChange}
              type="number"
              value={member.phone}
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label={"phoneNumber2"}
              name="phone2"
              onChange={handleChange}
              type="number"
              value={member.phone2}
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Select
              label="status"
              onChange={handleChange}
              required
              value={member.statusId || ""}
              options={status}
              name="statusId"
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Select
              label="baptized"
              onChange={handleChange}
              value={member.baptized}
              options={baptizedOptions}
              name="baptized"
              disabled={!canWrite}
            />
          </Grid>
          <MuiPickersUtils>
            <Grid item lg={4} md={4} xs={12}>
              <CKeyboardDatePicker
                label="baptizedDate"
                value={member.baptismDate}
                onChange={handleDateChange}
                disabled={!canWrite || String(member.baptized) === "false"}
                name="baptismDate"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <CKeyboardDatePicker
                label="joiningDate"
                value={member.joiningDate}
                onChange={handleDateChange}
                name="joiningDate"
                disabled={!canWrite}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <CKeyboardDatePicker
                label="dateOfBirth"
                value={member.birthday}
                onChange={handleDateChange}
                name="birthday"
                disabled={!canWrite}
              />
            </Grid>
            <Grid item xs={12}>
              <CTextField
                label="address"
                type="textarea"
                name="address"
                value={member.address}
                onChange={handleChange}
                disabled={!canWrite}
                rows={3}
                style={{ width: '100%' }}
              />
            </Grid>
          </MuiPickersUtils>
        </Grid>
      </Box>
    </FullScreenDialog>
  );
};

const mapStateToProps = (state) => ({
  status: state.status,
});

export default withRouter(connect(mapStateToProps)(MembersDialog));
