import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Grid,
  TextField,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { Church } from "models";
import translate from "i18nProvider/translate";
import { ChurchesService } from "services";
import store from "redux/store";
import { useHistory } from "react-router-dom";

const CreateEditChurch = () => {
  const [church, setChurch] = useState(new Church());
  const history = useHistory();

  const handleInputChange = (e) => {
    setChurch({
      ...church,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeActive = (e) => {
    setChurch({
      ...church,
      active: e.target.checked,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (church.id) {
      handleClickEditChurch();
    } else {
      handleClickCreateChurch();
    }
  };

  const handleClickEditChurch = () => {
    store.dispatch(new ChurchesService().editChurch(church));
    history.goBack();
  };

  const handleClickCreateChurch = () => {
    store.dispatch(new ChurchesService().create(church));
    history.goBack();
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={() => history.goBack()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleFormSubmit}>
          <DialogTitle id="alert-dialog-title">
            {church.id ? "Edit Church" : "Create Church"}
          </DialogTitle>
          <DialogContent dividers>
            <Box>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    required
                    name="name"
                    label={translate("name")}
                    onChange={handleInputChange}
                    value={church.name}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    required
                    name="email"
                    type="email"
                    label={translate("email")}
                    onChange={handleInputChange}
                    value={church.email}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    required
                    name="phone"
                    type="numeric"
                    label={translate("phone")}
                    onChange={handleInputChange}
                    value={church.phone}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    required
                    name="address"
                    label={translate("address")}
                    onChange={handleInputChange}
                    value={church.address}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={church.active}
                        onChange={handleChangeActive}
                        name="active"
                      />
                    }
                    label="Active"
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => history.goBack()} color="primary">
              {translate("cancel")}
            </Button>
            <Button type="submit" color="primary" autoFocus>
              {church.id ? translate("edit") : translate("create")}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default CreateEditChurch;
