import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { DynamicTable } from "components";
import { connect } from "react-redux";
import { MembersService, GroupsService } from "services";
import { withRouter } from "react-router-dom";
import MembersFilter from "./MembersFilter";
import { injectIntl } from "react-intl";
import { membersTableHeaders, membersTablefields } from "helpers";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 15,
  },
  popover: {
    "& > div": {
      display: "flex",
      justifyContent: "center",
      padding: 12,
    },
  },
}));

const MembersReports = injectIntl(({ members, dispatch }) => {
  const classes = useStyles();
  const {churchId, groupId} = useParams();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [filteredMembers, setFilteredMembers] = useState([]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const fetchMembers = React.useCallback(() => {
    if (members.length <= 0)
      dispatch(new MembersService(churchId).getMembersByChurchId());
  }, [churchId, dispatch, members]);

  const fetchGroupMembers = React.useCallback(async () => {
    if (groupId && churchId) {
      try {
        const gMembers = await new GroupsService(churchId).getMembersByGroup(
          groupId
        );
        if (Array.isArray(gMembers)) setFilteredMembers(gMembers);
      } catch (error) {
        console.log(error);
      }
    }
  }, [groupId, churchId]);

  useEffect(() => {
    if (!groupId) fetchMembers();
  }, [fetchMembers, groupId]);

  useEffect(() => {
    if (groupId) fetchGroupMembers();
  }, [fetchGroupMembers, groupId]);

  useEffect(() => {
    if (!groupId) setFilteredMembers(members);
  }, [members, groupId]);

  return (
    <Grid container justifyContent="center">
      <MembersFilter
        setFilteredMembers={setFilteredMembers}
        groupId={groupId}
      />
      <Grid item xs={12} className={classes.tableContainer}>
        <DynamicTable
          headers={membersTableHeaders}
          fields={membersTablefields}
          data={filteredMembers}
          count={filteredMembers.length}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Grid>
    </Grid>
  );
});

const mapStateToProps = (state) => ({
  members: state.members,
});

export default withRouter(connect(mapStateToProps)(MembersReports));
