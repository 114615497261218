import React from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { CTextField } from 'components';
import translate from 'i18nProvider/translate';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: "1000px",
    margin: "30px auto",
  },
}));

const ChurchSchema = Yup.object().shape({
  name: Yup.string().required(`Can not be empty.`),
  address: Yup.string().required(`Can not be empty.`),
  email: Yup.string().email('Invalid email').required('Can not be empty.'),
  phone: Yup.string().length(10, 'Debe ser de 10 digitos')
});

const ChurchForm = ({initialValues, handleNext, buttonsSection }) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ChurchSchema}
      onSubmit={handleNext}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur
      }) => (
        <Form>
          <Box className={classes.container}>
            <Box mb={2}>
              <Typography variant="h4" align="center">
                {translate('churchInfo')}
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <CTextField
                  name="name"
                  label={'name'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  error={(errors.name && touched.name)}
                  helperText={errors.name && touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CTextField
                  name="email"
                  type="email"
                  label={'email'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  error={(errors.email && touched.email)}
                  helperText={errors.email && touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CTextField
                  name="phone"
                  label={'phone'}
                  onChange={ev => !isNaN(ev.target.value) && handleChange(ev)}
                  onBlur={handleBlur}
                  value={values.phone}
                  error={(errors.phone && touched.phone)}
                  helperText={errors.phone && touched.phone && errors.phone}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CTextField
                  multiline
                  name="address"
                  label={'address'}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.address}
                  error={(errors.address && touched.address)}
                  helperText={errors.address && touched.address && errors.address}
                />
              </Grid>
            </Grid>
          </Box>
          {buttonsSection}
        </Form>
      )}
    </Formik>
  );
};

export default ChurchForm;
