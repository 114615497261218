import { createSlice } from "@reduxjs/toolkit";

const groupsSlice = createSlice({
  name: "groups",
  initialState: [],
  reducers: {
    addGroups(state, action) {
      return action.payload;
    },
    addGroup(state, action) {
      state.push(action.payload);
    },
    updateGroup(state, action) {
      const index = state.findIndex((group) => group.id === action.payload.id);
      if (index > -1) {
        state[index] = action.payload;
      }
    },
    deleteGroup(state, action) {
      const index = state.findIndex((group) => group.id === action.payload);
      if (index > -1) {
        state = state.splice(index, 1);
      }
    },
  },
});

export const {
  addGroups,
  addGroup,
  updateGroup,
  deleteGroup,
} = groupsSlice.actions;

export default groupsSlice.reducer;
