import React from "react";
import { Grid } from "@material-ui/core";
import { CustomAutocomplete, ReportsFilter } from "components";
import { ReportsService } from "services";
import PropTypes from "prop-types";
import store from "redux/store";
import { LOCALES } from "i18nProvider";
import { showLoading, hideLoading } from "redux/slices/loadingSlice";

const GroupsFilter = ({
  groupsLeaders = [],
  groups = [],
  setGroupsReport,
  churchId,
}) => {
  const [selectedLeader, setSelectedLeader] = React.useState(null);
  const [selectedGroup, setSelectedGroup] = React.useState(null);

  const onFilterBtnClick = async (clear = false, print = null) => {
    try {
      store.dispatch(showLoading());
      const res = await new ReportsService(churchId).getGroupsReport({
        leaderId:
          (selectedLeader !== null) & (clear === false)
            ? selectedLeader.id
            : "",
        groupId:
          (selectedGroup !== null) & (clear === false) ? selectedGroup.id : "",
        jrxmlFilename: print ? print : "",
      });
      if (Array.isArray(res)) {
        setGroupsReport(res);
      } else {
        const file = new Blob([res], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    } catch (err) {
      console.error(err);
    } finally {
      store.dispatch(hideLoading());
    }
  };
  const onClearBtnClick = () => {
    setSelectedLeader(null);
    setSelectedGroup(null);
    onFilterBtnClick(true);
  };
  const onPrintPdfBtnClick = () => {
    onFilterBtnClick(
      false,
      store.getState().language === LOCALES.ENGLISH
        ? "groups_english"
        : "groups_spanish"
    );
  };

  return (
    <ReportsFilter
      onFilterBtnClick={onFilterBtnClick}
      onClearBtnClick={onClearBtnClick}
      onPrintPdfBtnClick={onPrintPdfBtnClick}
    >
      <Grid item lg={12} md={12} xs={12}>
        <CustomAutocomplete
          selectedItem={selectedLeader}
          setSelectedItem={setSelectedLeader}
          options={groupsLeaders}
          label="leader"
          name="member"
        />
        <CustomAutocomplete
          selectedItem={selectedGroup}
          setSelectedItem={setSelectedGroup}
          options={groups}
          label="groupName"
          name="groupName"
          optionLabel="groupName"
        />
      </Grid>
    </ReportsFilter>
  );
};

GroupsFilter.propTypes = {
  groups: PropTypes.array.isRequired,
  groupsLeaders: PropTypes.array.isRequired,
  churchId: PropTypes.string.isRequired,
};

export default GroupsFilter;
