import React, { useState, useEffect } from "react";
import { Transaction } from "models";
import { Grid, TextField, Box } from "@material-ui/core";
import {
  FullScreenDialog,
  Confirm,
  CTextField,
  Select,
  CustomAutocomplete,
} from "components";
import { injectIntl } from "react-intl";
import translate from "i18nProvider/translate";
import { TransactionsService } from "services";
import ConfirmContent from "./ConfirmContent";
import { authorize } from "auth";
import {
  confirmDateOlder24Hours,
  paymentMethods,
  transactionsTypes,
  translateEnum,
} from "helpers";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const CreateEditTransaction = injectIntl(
  ({
    open,
    setOpen,
    members = [],
    transactionToEdit = new Transaction(),
    match,
    updateAfterCreate,
    updatedAfterEdit,
    showActions,
  }) => {
    const [transaction, setTransaction] = useState(transactionToEdit);
    const [selectedMember, setSelectedMember] = useState(null);
    const { churchId, groupId } = useParams();

    const handleChange = (event) => {
      event.target.setCustomValidity("");
      setTransaction({
        ...transaction,
        [event.target.name]: event.target.value,
      });
    };

    const onSubmit = () => {
      if (isEdit()) {
        editTransaction();
      } else {
        createTransaction();
      }
    };

    const setCurrentTransactionMember = React.useCallback(() => {
      const member = members.find((member) => {
        return member.id === transactionToEdit.memberId;
      });
      if (member) setSelectedMember(member);
    }, [members, transactionToEdit.memberId]);

    const editTransaction = async () => {
      if (await confirmTransactionDetails()) {
        try {
          let memberId = getSelectedMemberId();
          const editedTrans = await new TransactionsService(
            churchId
          ).editTransaction({ ...transaction, memberId: memberId });
          const filteredMember = members.filter(item => item.id === editedTrans.memberId);
          if (filteredMember.length > 0) {
            editedTrans.memberName = filteredMember[0].fullName;
          }
          updatedAfterEdit(editedTrans);
          setOpen(false);
        } catch (error) {
          console.log(error);
        }
      }
    };

    const confirmTransactionMore24 = () => {
      if (isEdit()) return confirmDateOlder24Hours(transaction.date);
      return false;
    };

    const createTransaction = async () => {
      if (await confirmTransactionDetails()) {
        try {
          let memberId = getSelectedMemberId();
          const newTrans = await new TransactionsService(
            churchId
          ).createTransaction({
            ...transaction,
            memberId: memberId,
            groupId: groupId,
          });
          const filteredMember = members.filter(item => item.id === newTrans.memberId);
          if (filteredMember.length > 0) {
            newTrans.memberName = filteredMember[0].fullName;
          }
          updateAfterCreate(newTrans);
          setOpen(false);
        } catch (error) {
          console.log(error);
        }
      }
    };

    const getSelectedMemberId = () => {
      if (selectedMember) {
        const member = members.find((member) => {
          return member.id === selectedMember.id;
        });
        return member.id;
      }
      return null;
    };

    const confirmTransactionDetails = async () => {
      const myComponent = (
        <ConfirmContent
          translate={translate}
          transaction={transaction}
          member={selectedMember}
        />
      );
      const result = await Confirm.show({
        message: translate("confirmTransaction"),
        content: myComponent,
      });

      return result;
    };

    const isEdit = React.useCallback(() => {
      return transactionToEdit.id;
    }, [transactionToEdit.id]);

    const handleClose = () => {
      setOpen(false);
    };

    const handleInvalidSelect = (e) => {
      e.target.setCustomValidity(translate("selectItem"));
    };

    useEffect(() => {
      if (isEdit()) {
        setCurrentTransactionMember();
      }
    }, [isEdit, setCurrentTransactionMember]);

    return (
      <FullScreenDialog
        title={translate("transactionsForm")}
        open={open}
        onSubmit={onSubmit}
        handleClose={handleClose}
        saveBtnLabel={
          !confirmTransactionMore24() && showActions
            ? isEdit()
              ? "edit"
              : "create"
            : ""
        }
      >
        <Box style={{ width: "90%", maxWidth: "1000px", margin: "0 auto" }}>
          <Grid container spacing={3}>
            {transactionToEdit.id && (
              <Grid item lg={4} md={4} xs={12}>
                <CTextField
                  helperText={translate("transactionId")}
                  label="id"
                  name="id"
                  onChange={handleChange}
                  value={transaction.id}
                  disabled
                />
              </Grid>
            )}
            <Grid item lg={4} md={4} xs={12}>
              <CTextField
                helperText={translate("specifyAmount")}
                label="amount"
                name="amount"
                onChange={handleChange}
                value={transaction.amount}
                disabled={confirmTransactionMore24()}
                type="number"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <Select
                label="paymentMethod"
                helperText={translate("specifyPaymentMethod")}
                onChange={handleChange}
                required
                value={transaction.paymentMethod}
                options={paymentMethods}
                name="paymentMethod"
                disabled={confirmTransactionMore24()}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <TextField
                fullWidth
                helperText={translate("specifyTransactionType")}
                label={translate("transactionType")}
                margin="dense"
                name="transactionDesc"
                onChange={handleChange}
                required
                value={transaction.transactionDesc}
                variant="outlined"
                select
                SelectProps={{ native: true }}
                onInvalid={handleInvalidSelect}
                disabled={confirmTransactionMore24()}
              >
                <option value="" />
                {transactionsTypes.map((option) =>
                  option.value !== "TITHES" ? (
                    <option key={option.value} value={option.value}>
                      {translateEnum(option.label)}
                    </option>
                  ) : (
                    authorize("tithes:read") &&
                    match.url.indexOf("groups") === -1 && (
                      <option key={option.value} value={option.value}>
                        {translateEnum(option.label)}
                      </option>
                    )
                  )
                )}
              </TextField>
            </Grid>
            {transaction.transactionDesc === "TITHES" && (
              <Grid item lg={4} md={4} xs={12}>
                <CustomAutocomplete
                  selectedItem={selectedMember}
                  setSelectedItem={setSelectedMember}
                  options={members}
                  label="member"
                  name="member"
                  disabled={confirmTransactionMore24()}
                />
              </Grid>
            )}
            <Grid item lg={4} md={4} xs={12}>
              <Box style={{ paddingTop: "6px" }}>
                <CTextField
                  helperText={translate("specifyTransactionDetails")}
                  label="transactionDetails"
                  name="details"
                  onChange={handleChange}
                  value={transaction.details}
                  disabled={confirmTransactionMore24()}
                  multiline
                  rows={3}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </FullScreenDialog>
    );
  }
);

export default CreateEditTransaction;
