import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TablePagination,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableContainer,
} from "@material-ui/core";
import translate from "i18nProvider/translate";
import { translateEnum, DateFormater } from "helpers";
import store from "redux/store";
import { LOCALES } from "i18nProvider";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: "flex-end",
  },
  selectedRow: {
    backgroundColor: "#E0E0E0",
  },
  cursorPointer: {
    cursor: "pointer",
  },
  container: {
    maxHeight: 440,
  },
  cell: {
    overflowWrap: "normal",
  },
}));

const DynamicTable = (props) => {
  const {
    className,
    data = [],
    headers,
    fields,
    selectedItem = { id: 0 },
    count = 0,
    rowsPerPage = 10,
    rowsPerPageOptions = [5, 10, 25],
    page = 0,
    handlePageChange = () => {},
    handleRowsPerPageChange = () => {},
    handleRowClick = () => {},
    multiselection = false,
    selectedItems = [],
    handleSelectOne = (e, item) => {},
    handleSelectAll = () => {},
    showActiveCheckBox = false,
    handleToggleActive = (e, item) => {},
    CustomIconsCell = null,
    ...rest
  } = props;

  const classes = useStyles();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <TableContainer className={classes.container}>
              <Table>
                <TableHead>
                  <TableRow>
                    {multiselection && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedItems.length === data.length}
                          color="primary"
                          indeterminate={
                            selectedItems.length > 0 &&
                            selectedItems.length < data.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                    )}

                    {headers.map((header, i) => (
                      <TableCell className={classes.cell} key={i}>
                        {translate(header)}
                      </TableCell>
                    ))}

                    {CustomIconsCell && (
                      <TableCell>{translate("goTo")}</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item) => (
                      <TableRow
                        key={item.id}
                        className={clsx(
                          classes.cursorPointer,
                          item.id === selectedItem.id && classes.selectedRow
                        )}
                        onClick={() => handleRowClick(item)}
                      >
                        {multiselection && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedItems.includes(item.id)}
                              color="primary"
                              onChange={(event) => handleSelectOne(event, item)}
                              value="true"
                            />
                          </TableCell>
                        )}

                        {showActiveCheckBox && (
                          <TableCell
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Checkbox
                              checked={item.active}
                              color="primary"
                              onChange={(event) =>
                                handleToggleActive(event, item)
                              }
                              value="true"
                            />
                          </TableCell>
                        )}

                        {fields.map((field, i) => (
                          <CustomCell key={i} field={field} item={item} />
                        ))}
                        {CustomIconsCell && (
                          <CustomIconsCell churchId={item.id} />
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </PerfectScrollbar>
      </CardContent>

      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={count}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </CardActions>
    </Card>
  );
};

const CustomCell = ({ item, field }) => {
  if (field === "status") {
    return <TableCell key={field}>{translateEnum(item[field].name)}</TableCell>;
  } else if (field.toUpperCase().includes("DATE")) {
    return (
      <TableCell key={field}>
        {store.getState().language === LOCALES.ENGLISH
          ? DateFormater.formatDateAsMMDDYYYY(item[field])
          : DateFormater.formatDateAsDDMMYYYY(item[field])}
      </TableCell>
    );
  } else {
    return <TableCell key={field}>{translateEnum(item[field])}</TableCell>;
  }
};

DynamicTable.propTypes = {
  className: PropTypes.string,
  headers: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  selectedItem: PropTypes.object,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  page: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  handleRowsPerPageChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func,
  multiselection: PropTypes.bool,
  selectedItems: PropTypes.array,
  handleSelectOne: PropTypes.func,
  handleSelectAll: PropTypes.func,
};

export default DynamicTable;
