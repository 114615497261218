import { createSlice } from "@reduxjs/toolkit";

const modalState = {
  show: false,
  data: null
};

const modalSlice = createSlice({
  name: "modal",
  initialState: modalState,
  reducers: {
    showModal(state, action) {
      state.show = true;
      state.data = action.payload;
    },
    hideModal(state, action) {
      state.show = false;
      state.data = null;
    }
  },
});

export const {
  showModal,
  hideModal
} = modalSlice.actions;

export default modalSlice.reducer;
