import React, { useEffect } from "react";
import queryString from "query-string";
import { UsersService } from "services";
import SignUpService from "services/SignUpService";

const CheckRequest = ({ location, history }) => {
  const validateRequestId = React.useCallback(
    async (reqId) => {
      try {
        const res = await new UsersService().checkRequest(reqId);
        if (res === "USER_REQUEST_OK") {
          history.replace(`members/sign-up/${reqId}`);
        } else {
          history.replace("/not-found");
        }
      } catch (error) {
        history.replace("/not-found");
        console.log(error);
      }
    },
    [history]
  );
  
  const validatePasswordChangeRequestId = React.useCallback(
    async (reqId) => {
      try {
        const res = await new UsersService().checkPasswordChangeRequest(reqId);
        if (res === "USER_REQUEST_OK") {
          history.replace(`users/change-password/${reqId}`);
        } else {
          history.replace("/not-found");
        }
      } catch (error) {
        history.replace("/not-found");
        console.log(error);
      }
    },
    [history]
  );

  const validateSignUpRequestId = React.useCallback(
    async (reqId) => {
      try {
        const res = await new SignUpService().checkRequest(reqId);
        if (res === "USER_REQUEST_OK") {
          history.replace(`users/sign-up/${reqId}`);
        } else {
          history.replace("/not-found");
        }
      } catch (error) {
        history.replace("/not-found");
        console.log(error);
      }
    },
    [history]
  );

  useEffect(() => {
    const checkQueryString = () => {
      const values = queryString.parse(location.search);
      if (values.reqId) {
        validateRequestId(values.reqId);
      } else if (values.requestId) {
        validateSignUpRequestId(values.requestId);
      } else if (values.pwdRequestId) {
        validatePasswordChangeRequestId(values.pwdRequestId);
      } else {
        history.replace("/not-found");
      }
    };

    checkQueryString();
  }, [history, location.search, validateRequestId, validateSignUpRequestId]);

  return <></>;
};

export default CheckRequest;
