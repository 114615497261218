import { createSlice } from "@reduxjs/toolkit";

const churchesSlice = createSlice({
  name: "churches",
  initialState: [],
  reducers: {
    addChurches(state, action) {
      return action.payload;
    },
    addChurch(state, action) {
      state.push(action.payload);
    },
    updateChurch(state, action) {
      const index = state.findIndex(
        (church) => church.id === action.payload.id
      );
      if (index > -1) {
        state[index] = action.payload;
      }
    },
  },
});

export const { addChurches, addChurch, updateChurch } = churchesSlice.actions;

export default churchesSlice.reducer;
