import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Paper, Tabs, Tab } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  People,
  EventNote,
  MonetizationOn,
  SupervisedUserCircle,
} from "@material-ui/icons";
import {
  MembersReports,
  ProgramsReports,
  TransactionsReports,
  GroupsReports,
} from "./components";
import translate from "i18nProvider/translate";
import { injectIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  tabs: {
    marginTop: "10px",
  },
  tab: {
    flexGrow: 1,
    fontSize: 12,
    minHeight: "68px !important",
  },
  tabPanel: {
    padding: "10px 16px",
    display: "flex",
    // justifyContent: 'center',
  },
}));

const ReportsView = injectIntl(({ match }) => {
  const classes = useStyles();

  const GROUP_ID = match.params.groupId;

  const [view, setView] = React.useState(0);

  const handleChange = (event, newVal) => {
    setView(newVal);
  };

  return (
    <div>
      <Paper className={classes.tabs}>
        <Tabs
          value={view}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={translate("members")}
            icon={<People />}
            className={classes.tab}
          />
          <Tab
            label={translate("programs")}
            icon={<EventNote />}
            className={classes.tab}
          />
          <Tab
            label={translate("transactions")}
            icon={<MonetizationOn />}
            className={classes.tab}
          />
          {/* {!GROUP_ID && (
            <Tab
              label={translate("groups")}
              icon={<SupervisedUserCircle />}
              className={classes.tab}
            />
          )} */}
        </Tabs>

        <div className={classes.tabPanel}>
          <TabPanel view={view} />
        </div>
      </Paper>
    </div>
  );
});

function TabPanel({ view }) {
  switch (view) {
    case 0:
      return <MembersReports />;
    case 1:
      return <ProgramsReports />;
    case 2:
      return <TransactionsReports />;
    // case 3:
    //   return <GroupsReports />;
    default:
      return "";
  }
}

ReportsView.propTypes = {
  className: PropTypes.string,
};

export default ReportsView;
