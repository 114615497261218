import React, { useEffect, useMemo, useState } from "react";
import { Grid, Box, Button, Typography, Fab } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { makeStyles } from "@material-ui/styles";

import translate from "i18nProvider/translate";
import { useFetch } from "hooks/useFetch";
import { hideLoading, showLoading } from "redux/slices/loadingSlice";
import { FullScreenDialog } from "components";
import ModulesView from "../ModulesView";
import store from "redux/store";
import { showAlertError, showAlertSuccess } from "redux/slices/alertsSlice";

function mapModules(item) {
  return { moduleName: item, canRead: false, canWrite: false, canDelete: false, canExecute: false };
}

const isPresent = (a, excludes = []) => {
  const res = excludes.filter((item) => a.moduleName === item.moduleName);
  return res.length > 0;
};

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    cursor: "pointer",
    color: "#00bfa5",
    backgroundColor: "#ffffff",
    marginLeft: 20,
  },
}));

const RoleManagerDialog = ({
  opentDialgo,
  setOpenDialgo,
  role = {},
  onDeleteBtnClicked,
}) => {
  const [newModulesHolder, setNewModulesHolder] = useState(null);
  const [selectedModules, setSelectedModules] = useState([]);
  const [showAddBtn, setShowAddBtn] = useState(true);
  const classes = useStyles();
  const {
    isLoading,
    data: roleModules,
    error,
    setData: setRoleModules,
    fetchRoot,
  } = useFetch({
    rootUrl: `/access-management/roles/${role.id}/module-access`,
    initialValue: [],
  });

  const bulkHandler = useFetch({
    rootUrl: `/access-management/roles/${role.id}/module-access-bulk`,
    initialValue: [],
  });

  const moduleHandler = useFetch({
    rootUrl: `/access-management/modules`,
    initialValue: [],
  });

  useEffect(() => {
    moduleHandler.fetchRoot();
  }, []);

  useEffect(() => {
    fetchRoot();
  }, [role]);

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading]);

  useEffect(() => {
    if (error) {
      store.dispatch(showAlertError(translate("theActionCouldNotBeComplete")));
    }
  }, [error]);

  const modulesForDropdown = useMemo(
    () => moduleHandler.data.map(mapModules).filter((item) => !isPresent(item, roleModules)),
    [roleModules, moduleHandler.data]
  );

  const handleAccessTypeSelect = (event, module) => {
    const updatedModules = roleModules.map((item) => {
      if (item.moduleName === module.moduleName) {
        item[event.target.name] = event.target.checked;
      }
      return item;
    });
    setRoleModules(updatedModules);
  };

  const handleAccessTypeSelectForNewModules = (event, module, newModules) => {
    const accessType = event.target.name;
    const accessTypeValue = event.target.checked;
    module[accessType] = accessTypeValue;
    setNewModulesHolder({
      ...module,
      selectedModules: newModules.map((item) => {
        item[accessType] = accessTypeValue;
        return item;
      }),
    });
  };

  const handleClickSave = () => {
    if (newModulesHolder) {
      const { canRead, canWrite, canDelete, canExecute } = newModulesHolder;
      if (newModulesHolder && selectedModules.length) {
        // call api and pass the following as callback
        const toSave = [
          ...selectedModules.map((item) => {
            return {
              ...item,
              canRead,
              canWrite,
              canDelete,
              canExecute,
            };
          }),
          ...roleModules,
        ];

        bulkHandler.save(toSave, (resp) => {
          console.log("RESP: ", resp);
          setRoleModules(resp);
          setNewModulesHolder(null);
          setSelectedModules([]);
          setShowAddBtn(true);
          store.dispatch(showAlertSuccess(translate("roleSavedSuccessfully")));
        });
      } else {
        store.dispatch(showAlertError(translate("needToSelectModule")));
        return;
      }
    } else {
      bulkHandler.save(roleModules, () => {
        store.dispatch(showAlertSuccess(translate("roleSavedSuccessfully")));
      });
    }
  };

  const onAddNewModule = () => {
    setNewModulesHolder({
      isNew: true,
      moduleName: "addModule",
      canRead: false,
      canWrite: false,
      canDelete: false,
      canExecute: false,
    });
    setShowAddBtn(false);
  };

  const handleDelete = (module) => {
    if (module.isNew) {
      setNewModulesHolder(null);
      setSelectedModules([]);
      setShowAddBtn(true);
    } else {
      setRoleModules(
        roleModules.filter((el) => el.moduleName !== module.moduleName)
      );
    }
  };

  return (
    <FullScreenDialog
      title={translate("roleAccessManager")}
      handleClose={() => setOpenDialgo(false)}
      open={opentDialgo}
      onSubmit={handleClickSave}
      saveBtnLabel={role.id ? "save" : "create"}
    >
      <Box style={{ maxWidth: "1000px", margin: "0 auto" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
              <Typography variant="h3">
                {translate("role")} {role.name}
              </Typography>
              <Fab
                color="primary"
                title={translate("delete")}
                onClick={onDeleteBtnClicked}
                size="small"
                style={{ color: "#DC004E" }}
                className={classes.iconBtn}
              >
                <DeleteIcon />
              </Fab>
            </Grid>
          </Grid>
          {showAddBtn && modulesForDropdown.length > 0 && (
            <Grid item xs={12}>
              <Grid container justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onAddNewModule}
                >
                  {translate("addModule")}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={3}>
          {newModulesHolder && (
            <Grid item xs={12}>
              <ModulesView
                module={newModulesHolder}
                modulesForDropdown={modulesForDropdown}
                selectedModules={selectedModules}
                setSelectedModules={setSelectedModules}
                handleAccessTypeSelect={handleAccessTypeSelectForNewModules}
                handleDelete={handleDelete}
              />
            </Grid>
          )}
          {roleModules.map((item) => (
            <Grid key={item.moduleName} item xs={12}>
              <ModulesView
                module={item}
                handleAccessTypeSelect={handleAccessTypeSelect}
                handleDelete={handleDelete}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </FullScreenDialog>
  );
};

export default React.memo(RoleManagerDialog);
