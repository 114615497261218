import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ReportsFilter } from "components";
import { StatusService } from "services";
import { connect } from "react-redux";
import translate from "i18nProvider/translate";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Grid, Box, makeStyles } from "@material-ui/core";
import { injectIntl } from "react-intl";
import { baptizedOptions, DateFormater } from "helpers";
import { Select, MuiPickersUtils } from "components";
import { withRouter } from "react-router-dom";
import { ReportsService } from "services";
import { LOCALES } from "i18nProvider";
import { showLoading, hideLoading } from "redux/slices/loadingSlice";

const useStyles = makeStyles((theme) => ({
  datesBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));

const filters = {
  joiningStartDate: "",
  joiningEndDate: "",
  statusid: "",
  baptized: "",
  jrxmlFilename: "",
  groupid: "",
};

const MembersFilter = injectIntl(({ dispatch, status, setFilteredMembers, language }) => {
    const { churchId, groupId } = useParams();

    const classes = useStyles();
    const [filterData, setFilterData] = useState({
      ...filters,
    });

    const onFilterBtnClick = async (filtrs = null) => {
      const fi = filtrs ? filtrs : filterData;
      try {
        dispatch(showLoading());
        let res;
        if (groupId) {
          res = await new ReportsService(churchId).getGroupMembersReport(groupId, {
            ...fi,
          });
        } else {
          res = await new ReportsService(churchId).getMembersReport({
            ...fi
          });
        }
        if (Array.isArray(res)) setFilteredMembers(res);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(hideLoading());
      }
    };

    const onPrintPdfBtnClick = async () => {
      try {
        dispatch(showLoading());

        let res;
        if (groupId) {
          res = await new ReportsService(churchId).getGroupMembersReport(groupId, {
            ...filterData,
            jrxmlFilename:
              language === LOCALES.ENGLISH
                ? "members_english"
                : "members_spanish",
          });
        } else {
          res = await new ReportsService(churchId).getMembersReport({
            ...filterData,
            jrxmlFilename:
              language === LOCALES.ENGLISH
                ? "members_english"
                : "members_spanish",
          });
        }

        const file = new Blob([res], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(hideLoading());
      }
    };

    const fetchStatus = React.useCallback(async () => {
      if (status.length <= 1) await dispatch(new StatusService().getStatus());
    }, [dispatch, status]);

    const handleSelectChange = (e) => {
      setFilterData({
        ...filterData,
        [e.target.name]: e.target.value,
      });
    };

    const handleDates = (date, fieldName) => {
      setFilterData({
        ...filterData,
        [fieldName]: Date.parse(date),
      });
    };

    const onClearBtnClick = () => {
      setFilterData({ ...filters });
      onFilterBtnClick(filters);
    };

    const isNotEmptySt = (date) => {
      return date !== "" ? date : null;
    };

    useEffect(() => {
      fetchStatus();
    }, [fetchStatus]);

    return (
      <ReportsFilter
        onFilterBtnClick={onFilterBtnClick}
        onClearBtnClick={onClearBtnClick}
        onPrintPdfBtnClick={onPrintPdfBtnClick}
      >
        <MuiPickersUtils>
          <Box mt={3} style={{ width: "100%", maxWidth: "500px" }}>
            <Grid container spacing={3}>
              <Grid item lg={6} md={6} xs={12}>
                <Select
                  label="status"
                  onChange={handleSelectChange}
                  value={filterData.statusid || ""}
                  options={status}
                  name="statusid"
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <Select
                  label="baptized"
                  onChange={handleSelectChange}
                  value={filterData.baptized || ""}
                  options={baptizedOptions}
                  name="baptized"
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Box className={classes.datesBox}>
                  <Box>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      label={translate("joiningDate")}
                      format={DateFormater.getCurrentLanguagetFormat()}
                      emptyLabel={translate("startDate")}
                      value={isNotEmptySt(filterData.joiningStartDate)}
                      onChange={(date) => handleDates(date, "joiningStartDate")}
                    />
                  </Box>
                  <Box>
                    <KeyboardDatePicker
                      fullWidth
                      margin="normal"
                      label={translate("joiningDate")}
                      emptyLabel={translate("endDate")}
                      format={DateFormater.getCurrentLanguagetFormat()}
                      value={isNotEmptySt(filterData.joiningEndDate)}
                      onChange={(date) => handleDates(date, "joiningEndDate")}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </MuiPickersUtils>
      </ReportsFilter>
    );
  }
);

const mapStateToProps = (state) => ({
  status: state.status,
  language: state.language,
});

export default withRouter(connect(mapStateToProps)(MembersFilter));
