import React, {useEffect, useState} from "react";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { FullScreenDialog, MuiPickersUtils, CTextField } from "components";

import ProgramDetails from "../ProgramDetails/ProgramDetails";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import translate from "i18nProvider/translate";
import { ProgramsService } from "services";
import {useHistory, useLocation, withRouter} from "react-router-dom";
import store from "redux/store";
import { showAlertSuccess } from "redux/slices/alertsSlice";
import { confirmDateOlder24Hours } from "helpers";
import { useFetch } from "hooks/useFetch";
import { useParams } from "react-router-dom";
import {getUserGroupAccess} from "../../../../auth";
import {parse} from "date-fns";

const useStyles = makeStyles((theme) => ({
  timePicker: {
    flex: 1,
  },
  centerBox: {
    width: "90%",
    maxWidth: "1300px",
    margin: "0 auto",
    marginTop: theme.spacing(12),
  },
  programDetails: {
    marginTop: 25,
  },
}));

const CreateEditProgram = () => {
  const classes = useStyles();
  const history = useHistory();
  const { churchId, groupId, programId: programIdParam } = useParams();
  const programId = programIdParam === 'new' ? null : programIdParam;
  const location = useLocation();

  const { save: saveProgram } = useFetch({
    rootUrl: `/churches/${churchId}`,
    defaultValue: [],
  });

  const [program, setProgram] = useState(() => {
    let dateParam = location.search.split('=')[1];
    const startDate = parse(`${dateParam} 19:00`, 'yyyy-MM-dd HH:mm', new Date());
    const endDate = parse(`${dateParam} 21:30`, 'yyyy-MM-dd HH:mm', new Date());
    return { startDate: startDate, endDate: endDate };
  });
  const [showEditButton, setShowEditButton] = useState(true);
  const [programDetails, setProgramDetails] = useState([]);

  const programService = new ProgramsService(churchId);

  const {
    canRead: groupCanRead,
    canWrite: groupCanWrite,
    canDelete: groupCanDelete,
    canExecute: groupCanExecute
  } = getUserGroupAccess("GROUP_PROGRAMS", groupId);


  const {
    canRead: churchCanRead,
    canWrite: churchCanWrite,
    canDelete: churchCanDelete,
    canExecute: churchCanExecute
  } = getUserGroupAccess("CHURCH_PROGRAMS", churchId);

  const handleChange = (event) => {
    setShowEditButton(true);
    event.target.setCustomValidity("");
    setProgram({ ...program, [event.target.name]: event.target.value });
  };


  const onSubmit = () => {
    if (program.id) {
      editProgram();
    } else {
      createProgram();
    }
    history.goBack();
  };

  const createProgram = async () => {
    try {
      let created;
      const programData = {
        ...program,
        details: programDetails,
      };
      if (groupId) {
        created = saveProgram(programData, (data) => {
          // refreshProgramsAfterCreate(data);
          setProgram(data);
        }, `/groups/${groupId}/programs`);
      } else {
        created = saveProgram(programData, (data) => {
          // refreshProgramsAfterCreate(data);
          setProgram(data);
        }, "/programs");
      }
      store.dispatch(showAlertSuccess(translate("programSuccessfullyCreated")));
      setShowEditButton(false);
    } catch (error) {
      console.log(error);
    }
  };

  const editProgram = async () => {
    try {
      let edited;
      if (groupId) {
        edited = await programService.editGroupProgram(groupId, program);
      } else {
        edited = await programService.editChurchProgram(program);
      }
      // refreshProgramsAfterEdit(edited);
      store.dispatch(showAlertSuccess(translate("programSuccessfullyEdited")));
      // setOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (date, fieldName) => {
    setShowEditButton(true);
    setProgram({ ...program, [fieldName]: date });
  };

  const endDateIsOlder24Hours = () => {
    return confirmDateOlder24Hours(program.endDate);
  };

  const startDateIsOlder24Hours = () => {
    return confirmDateOlder24Hours(program.startDate);
  };

  useEffect(() => {
    if (groupId && programId) {
      new ProgramsService(churchId).getGroupProgramById(groupId, programId)
        .then(resp => {
          setProgram(resp);
        }).catch(err => {
          console.log(err);
        });
    } else if (programId){
      new ProgramsService(churchId).getProgramById(programId)
        .then(resp => {
          setProgram(resp);
        }).catch(err => {
          console.log(err);
        });
    }
  }, [programId]);

  const disabled = endDateIsOlder24Hours() || (!churchCanWrite && !groupCanWrite);

  return (
    <FullScreenDialog
      open={true}
      handleClose={() => history.goBack()}
      title={translate(program.id ? "editProgram" : "addProgram")}
      onSubmit={onSubmit}
      saveBtnLabel={
        !endDateIsOlder24Hours() && showEditButton ? translate("save") : ""
      }
      actionsDisabled={disabled}
      className={classes.centerBox}
    >
      <MuiPickersUtils>
        <Grid spacing={3} container>
          <Grid item xs={12} sm={12} md={4}>
            <CTextField
              helperText={translate("specifyTitle")}
              label={"title"}
              name="name"
              onChange={handleChange}
              required
              value={program.name}
              disabled={disabled}
            />
          </Grid>
          <Grid item container xs={12} sm={6} md={4}>
            <KeyboardDateTimePicker
              className={classes.timePicker}
              disablePast={!startDateIsOlder24Hours()}
              label={translate("startDate")}
              value={program.startDate}
              name="startDate"
              onChange={(date) => handleDateChange(date, "startDate")}
              variant="outlined"
              minDateMessage={translate("minimalDate")}
              disabled={disabled}
            />
          </Grid>
          <Grid item container xs={12} sm={6} md={4}>
            <KeyboardDateTimePicker
              className={classes.timePicker}
              disablePast={!endDateIsOlder24Hours()}
              label={translate("endDate")}
              value={program.endDate}
              name="endDate"
              onChange={(date) => handleDateChange(date, "endDate")}
              variant="outlined"
              minDateMessage={translate("minimalDate")}
              disabled={disabled}
            />
          </Grid>
          <Grid item container xs={12} sm={6} md={4}>
            <CTextField
              multiline
              rows={5}
              helperText={translate("specifyDescription")}
              label={"description"}
              name="description"
              onChange={handleChange}
              value={program.description || ""}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </MuiPickersUtils>
      <ProgramDetails
        className={classes.programDetails}
        programId={program.id}
        disableActions={disabled}
        setProgramDetails={setProgramDetails}
      />
    </FullScreenDialog>
  );
};

export default withRouter(CreateEditProgram);
