import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import translate from "i18nProvider/translate";
import { injectIntl } from "react-intl";
import { DateFormater } from "helpers";

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
  },
});

function Row(props) {
  const {
    row,
    subHeaders = [],
    firstHeadersFields = [],
    subHeadersFields = [],
    subTableName = "",
    subTableDataFieldName = "",
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        {firstHeadersFields.map((field, i) =>
          row[field] ? (
            <CustomCell item={row[field]} field={field} key={i} />
          ) : (
            <TableCell key={i}></TableCell>
          )
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                {translate(subTableName)}
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {subHeaders.map((header, i) => (
                      <TableCell key={i}>{translate(header)}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row[subTableDataFieldName].map((row, f) => (
                    <TableRow key={f}>
                      {subHeadersFields.map((field, i) => (
                        <TableCell key={i}>{row[field]}</TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CustomCell = ({ item, field }) => {
  return (
    <TableCell style={{ fontSize: '0.95rem' }}>
      {field.toUpperCase().includes("DATE")
        ? DateFormater.formatDateAsDDMMYYYYhhmm(item)
        : item}
    </TableCell>
  );
};

function CollapsibleTable({
  firstHeaders = [],
  subHeaders = [],
  data = [],
  firstHeadersFields = [],
  subHeadersFields = [],
  subTableName = "",
  subTableDataFieldName = "",
}) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            {firstHeaders.map((header, i) => (
              <TableCell key={i}>{translate(header)}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, i) => (
            <Row
              key={i}
              row={row}
              firstHeadersFields={firstHeadersFields}
              subHeaders={subHeaders}
              subHeadersFields={subHeadersFields}
              subTableName={subTableName}
              subTableDataFieldName={subTableDataFieldName}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CollapsibleTable.propTypes = {
  firstHeaders: PropTypes.array.isRequired,
  subHeaders: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  firstHeadersFields: PropTypes.array.isRequired,
  subHeadersFields: PropTypes.array.isRequired,
  subTableDataFieldName: PropTypes.string.isRequired,
};

export default injectIntl(CollapsibleTable);
