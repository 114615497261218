import translate from "i18nProvider/translate";

const paymentMethods = Object.freeze({
  CASH: "cash",
  BANK_TRANSACTION: "bankTransaction",
  CREDIT_CARD: "creditCard",
  DEBIT_CARD: "debitCard",
});

const transactionsTypes = Object.freeze({
  TITHES: "tithes",
  OFFERINGS: "offerings",
  DONATIONS: "donations",
  OUTGOING: "outgoing",
});

const rolesEnum = Object.freeze({
  SECRETARY: "secretary",
  COUNCIL_PRESIDENT: "councilPresident",
  GROUP_LEADER: "groupLeader",
});

const statusEnum = Object.freeze({
  ACTIVE: "active",
  ADMONISHED: "admonished",
  ASTRAY: "astray",
  INACTIVE: "inactive",
});

const allInlEnums = {
  ...paymentMethods,
  ...transactionsTypes,
  ...rolesEnum,
  ...statusEnum,
};

const handleString = (value) => {
  if (value.includes(",")) return handleCommaSeparatedValues(value);
  if (allInlEnums[value]) return translate(allInlEnums[value]);
  return value;
};

const handleCommaSeparatedValues = (value) => {
  const values = value.split(",");
  for (let i = 0; i < values.length; i++) {
    values[i] = values[i].replace(/\s/g, "");
    values[i] = handleString(values[i]);
  }
  return values.join(", ");
};

export const translateEnum = (value) => {
  const typeValue = typeof value;
  let translated = value;

  switch (typeValue) {
    case "string":
      translated = handleString(value);
      break;
    default:
      translated = value;
  }

  return translated;
};

export default translateEnum;
