import React, { useState, useEffect } from "react";
import {
  FullScreenDialog,
  CTextField,
  Select,
  MuiPickersUtils,
  CKeyboardDatePicker,
} from "components";
import { Grid, Box } from "@material-ui/core";
import { Member } from "models";
import { StatusService, MembersService } from "services";
import { connect } from "react-redux";
import translate from "i18nProvider/translate";
import { withRouter } from "react-router-dom";
import { baptizedOptions } from "helpers";
import { getUserChurchAccess } from "auth/rulesHandler";

const MembersDialog = ({
  opentDialgo,
  setOpenDialgo,
  memberToEdit,
  clearMemberToEdit,
  dispatch,
  status,
  match,
}) => {
  const [member, setMember] = useState(new Member());
  const CHURCH_ID = match.params.churchId;
  const { canWrite } = getUserChurchAccess("CHURCH_MEMBERS",CHURCH_ID );

  const fetchStatus = React.useCallback(async () => {
    await dispatch(new StatusService().getStatus());
  }, [dispatch]);

  useEffect(() => {
    const formatMember = () => {
      const upMember = { ...memberToEdit };
      upMember.status = upMember.status.id;
      setMember(upMember);
    };

    fetchStatus();
    if (memberToEdit.id) {
      formatMember();
    }
  }, [memberToEdit.id, memberToEdit, fetchStatus]);

  const handleChange = (event) => {
    event.target.setCustomValidity("");
    setMember({
      ...member,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickSave = () => {
    if (member.joiningDate === "") {
      member.joiningDate = new Date();
    }
    const memberInfo = {
      ...member,
      email: member.email ? member.email : null,
      personalId: member.personalId ? member.personalId : null,
      status: Number(member.status),
    };

    if (memberToEdit.id) {
      dispatch(
        new MembersService(CHURCH_ID).updateMember(memberToEdit.id, memberInfo)
      );
    } else {
      dispatch(new MembersService(CHURCH_ID).create(memberInfo));
    }
    clearMemberToEdit();
    setOpenDialgo(false);
  };

  const handleDateChange = (date, name) => {
    setMember({
      ...member,
      [name]: date,
    });
  };


  return (
    <FullScreenDialog
      title={translate("membersformTitle")}
      handleClose={() => setOpenDialgo(false)}
      open={opentDialgo}
      onSubmit={canWrite ? handleClickSave : null}
      saveBtnLabel={memberToEdit.id ? "edit" : "create"}
      actionsDisabled={!canWrite}
    >
      <Box style={{ width: "90%", maxWidth: "1000px", margin: "0 auto" }}>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label="firstname"
              name="firstName"
              onChange={handleChange}
              value={member.firstName}
              disabled={!canWrite}
              required
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label="lastname"
              name="lastName"
              onChange={handleChange}
              value={member.lastName}
              disabled={!canWrite}
              required
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label="identification"
              name="personalId"
              onChange={handleChange}
              value={member.personalId || ""}
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              type="email"
              label="email"
              name="email"
              onChange={handleChange}
              value={member.email || ""}
              onInvalid={(e) =>
                e.target.setCustomValidity(translate("writeValidEmail"))
              }
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label="phoneNumber1"
              name="phone"
              onChange={handleChange}
              type="number"
              value={member.phone}
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <CTextField
              label={"phoneNumber2"}
              name="phone2"
              onChange={handleChange}
              type="number"
              value={member.phone2}
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Select
              label="status"
              onChange={handleChange}
              required
              value={member.status || ""}
              options={status}
              name="status"
              disabled={!canWrite}
            />
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Select
              label="baptized"
              onChange={handleChange}
              value={member.baptized}
              options={baptizedOptions}
              name="baptized"
              disabled={!canWrite}
            />
          </Grid>
          <MuiPickersUtils>
            <Grid item lg={4} md={4} xs={12}>
              <CKeyboardDatePicker
                label="baptizedDate"
                value={member.baptismDate}
                onChange={handleDateChange}
                disabled={!canWrite || String(member.baptized) === "false"}
                name="baptismDate"
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <CKeyboardDatePicker
                label="joiningDate"
                value={member.joiningDate}
                onChange={handleDateChange}
                name="joiningDate"
                disabled={!canWrite}
              />
            </Grid>
            <Grid item lg={4} md={4} xs={12}>
              <CKeyboardDatePicker
                label="dateOfBirth"
                value={member.birthday}
                onChange={handleDateChange}
                name="birthday"
                disabled={!canWrite}
              />
            </Grid>
          </MuiPickersUtils>
        </Grid>
      </Box>
    </FullScreenDialog>
  );
};

const mapStateToProps = (state) => ({
  status: state.status,
});

export default withRouter(connect(mapStateToProps)(MembersDialog));
