import React, { useState } from "react";
import {
  Container,
  CardMedia,
  makeStyles,
  Box,
  Avatar,
  Button,
  TextField,
  Card
} from "@material-ui/core/";

import userImg from "assets/images/user.jfif";
import { UsersService, SignUpService } from "services";
import store from "../../redux/store";
import translate from "i18nProvider/translate";
import { showAlertError, showAlertInfo } from "../../redux/slices/alertsSlice";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    width: "100%",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  avatar: {
    width: "150px",
    height: "150px",
  },
  userPhoto: {
    width: "100%",
    height: "auto",
  },
  card: {
    padding: "15px",
    marginTop: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const SignInView = ({ match, history }) => {
  const classes = useStyles();

  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleSignUp = async (e) => {
    e.preventDefault();
    const reqId = match.params.reqId;
    const user = { password: credentials.password, confirmPassword: credentials.confirmPassword, reqId };
    if (user.password !== user.confirmPassword) {
      store.dispatch(showAlertError(translate('bothPasswordDidNotMatch')));
      return;
    }

    try {
      const isUserPath = match.path.startsWith('/users/');
      const isUserPwdChangePath = match.path.startsWith('/users/change-password');

      if (isUserPwdChangePath) {
        await new UsersService()
          .changePassword(user)
          .then(resp => {
            console.log('RESP: ', resp);
            if(resp.error) {
              store.dispatch(showAlertError(translate(resp.error.message)));
            } else {
              store.dispatch(showAlertInfo(translate('passwordChangedSuccessfully')));
              history.replace("/sign-in");
            }
          });
      }
      else if(isUserPath) {
        await new SignUpService()
          .registerUser(user)
          .then(resp => {
            console.log('RESP: ', resp);
            if(resp.error) {
              store.dispatch(showAlertError(translate(resp.error.message)));
            } else {
              store.dispatch(showAlertInfo(translate('readyToSignIn')));
              history.replace("/sign-in");
            }
          });
      } else {
        await new UsersService()
          .registerUser(reqId, user)
          .then(() => history.replace("/sign-in"))
          .catch(error => {
            store.dispatch(showAlertError(translate(error.message)));
          });
      }
    } catch (error) {
      console.log('ER: ', error);
    }
  };

  const handleFieldsOnChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setCredentials(oldValues => ({
      ...oldValues,
      [name]: value,
    }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.card}>
        <Avatar className={classes.avatar}>
          <CardMedia>
            <img className={classes.userPhoto} src={userImg} alt="user" />
          </CardMedia>
        </Avatar>
        <div className={classes.paper}>
          <form className={classes.form} onSubmit={handleSignUp}>
            <Box mb={1}>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                name="password"
                label={translate("password")}
                type="password"
                autoComplete="false"
                onChange={handleFieldsOnChange}
              />
            </Box>
            <TextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="confirmPassword"
              label={translate("confirmPassword")}
              type="password"
              autoComplete="false"
              onChange={handleFieldsOnChange}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={
                credentials.password === "" ||
                credentials.confirmPassword === "" ||
                credentials.password !== credentials.confirmPassword
              }
            >
              {translate("submit")}
            </Button>
          </form>
        </div>
        {/* <Box mt={8}>
          <Copyright />
        </Box> */}
      </Card>
    </Container>
  );
};

export default SignInView;


// export default SignUp;
