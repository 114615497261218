import React from "react";
import InputIcon from "@material-ui/icons/Input";
import { IconButton } from "@material-ui/core";
import { removeRefreshToken, removeToken } from "auth";
import { Confirm } from "components";
import translate from "i18nProvider/translate";
import { withRouter } from "react-router-dom";

const SignOutMenu = ({ history }) => {
  const handleClickSignOut = async () => {
    const result = await Confirm.show({
      message: translate("signOut"),
    });
    if (result) {
      removeToken();
      removeRefreshToken();
      history.replace("/sign-in");
    }
  };

  return (
    <IconButton color="inherit" onClick={handleClickSignOut}>
      <InputIcon />
    </IconButton>
  );
};

export default withRouter(SignOutMenu);
