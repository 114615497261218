import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { Fab } from "@material-ui/core";

import { SearchFilter } from "components";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from "@material-ui/icons/Info";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { injectIntl } from "react-intl";
import translate from "i18nProvider/translate";
import { authorize } from "auth";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap-reverse",
  },

  searchInput: {
    width: "100%",
    maxWidth: 450,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  iconBtn: {
    cursor: "pointer",
    color: "#00bfa5",
    backgroundColor: "#ffffff",
    marginRight: 10,
  },
}));

const UsersToolbar = injectIntl(
  ({
    onDeleteBtnClicked = () => {},
    onEditBtnClick = () => {},
    dataToSearch = [],
    setDataOnSearch = () => {},
    keysToSearch = [],
    showEdit = false,
    showDelete = false,
  }) => {
    const classes = useStyles();
    const canWrite = authorize("USERS", "canWrite");
    const canDelete = authorize("USERS", "canDelete");

    return (
      <div className={classes.row}>
        <div className={classes.searchInput}>
          <SearchFilter
            dataToSearch={dataToSearch}
            setDataOnSearch={setDataOnSearch}
            keysToSearch={keysToSearch}
          />
        </div>
        <div style={{ marginLeft: "auto" }}>
          {showEdit && (
            <Fab
              title={translate(canWrite ? "editUser" : "viewUser")}
              onClick={onEditBtnClick}
              size="small"
              className={classes.iconBtn}
            >
              {canWrite && <EditIcon style={{ color: "#FFD600" }} />}
              {!canWrite && <InfoIcon style={{ color: "#2196F3" }} />}
            </Fab>
          )}

          {canDelete && showDelete && (
            <Fab
              title={
                showEdit
                  ? translate("deleteUser")
                  : translate("deleteUserRequest")
              }
              onClick={onDeleteBtnClicked}
              size="small"
              className={classes.iconBtn}
            >
              <DeleteIcon style={{ color: "#DC004E" }} />
            </Fab>
          )}
        </div>
      </div>
    );
  }
);

UsersToolbar.propTypes = {
  className: PropTypes.string,
};

export default UsersToolbar;
