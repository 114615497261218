import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { CollapsibleTable } from "components";
import ProgramsFilter from "./ProgramsFilter";
import { ReportsService } from "services";
import { withRouter } from "react-router-dom";
import { DateFormater } from "helpers";

const firstHeaders = ["name", "description", "startDate", "endDate"];
const subHeaders = ["detailName", "member"];
const firstHeadersFields = firstHeaders;
const subHeadersFields = ["programPartName", "memberName"];
const subTableName = "programDetails";
const subTableDataFieldName = "programDetails";

const ProgramsReports = () => {
  const {churchId, groupId} = useParams();
  const [programs, setPrograms] = React.useState([]);

  const getProgramsReport = useCallback(async () => {
    try {
      const currentDate = new Date();
      
      let pr;
      
      if (groupId) {
        pr = await new ReportsService(churchId).getGroupProgramsReport(groupId, {
          programStartDate: DateFormater.format(
            DateFormater.minusDays(currentDate, 30),
            "YYYY-MM-DD"
          ),
          programEndDate: DateFormater.format(currentDate, "YYYY-MM-DD"),
          programId: "",
          jrxmlFilename: "",
          groupId: groupId ? groupId : "",
        });
      } else {
        pr = await new ReportsService(churchId).getProgramsReport({
          programStartDate: DateFormater.format(
            DateFormater.minusDays(currentDate, 30),
            "YYYY-MM-DD"
          ),
          programEndDate: DateFormater.format(currentDate, "YYYY-MM-DD"),
          programId: "",
          jrxmlFilename: "",
          groupId: groupId ? groupId : "",
        });
      }
      if (Array.isArray(pr)) setPrograms(pr);
    } catch (err) {
      console.error(err);
    }
  }, [churchId, groupId]);
  
  React.useEffect(() => {
    getProgramsReport();
  }, [getProgramsReport]);

  return (
    <Grid container justifyContent="center">
      <ProgramsFilter
        churchId={churchId}
        setFilteredPrograms={setPrograms}
        groupId={groupId}
      />
      <Grid item xs={12} style={{ marginTop: "15px" }}>
        <CollapsibleTable
          data={programs}
          firstHeaders={firstHeaders}
          subHeaders={subHeaders}
          firstHeadersFields={firstHeadersFields}
          subHeadersFields={subHeadersFields}
          subTableName={subTableName}
          subTableDataFieldName={subTableDataFieldName}
        />
      </Grid>
    </Grid>
  );
};

export default withRouter(ProgramsReports);
