import { createSlice } from "@reduxjs/toolkit";

const loadingState = {
  show: false,
};

const loadingSlice = createSlice({
  name: "loading",
  initialState: loadingState,
  reducers: {
    showLoading(state, action) {
      state.show = true;
    },
    hideLoading(state, action) {
      state.show = false;
    },
  },
});

export const { showLoading, hideLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
