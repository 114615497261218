import store from "redux/store";

const pastorRules = [
  "members:read",
  "members:write",
  "users:read",
  "users:write",
  "programs:write",
  "programs:read",
  "churchTransactions:read",
  "groups:read",
  "groups:write",
  "churchesSidebar:read",
  "settings:read",
  "home:read",
  "tithes:read",
  "tithes:write",
  "settings:read",
  "reports:read",
  "reports:write",
];

const adminRules = [
  "church_admin",
  ...pastorRules
];

const transactionsWriteRule = (groupId) => {
  const { groups = [] } = store.getState().session.user;
  if (groups.find((group) => group.id === Number(groupId))) return true;
  return false;
};

const rules = {
  CHURCH_ADMIN: {
    static: adminRules,
  },
  PASTOR: {
    static: pastorRules,
  },

  SECRETARY: {
    static: pastorRules,
  },

  GROUP_LEADER: {
    static: [
      "groups:read",
      "programs:write",
      "programs:read",
      "transactions:read",
      "groupsSidebar:read",
      "home:read",
      "programs:read",
      "settings:read",
      "reports:read",
    ],
    dynamic: {
      "groupTransactions:write": ({ groupId }) =>
        transactionsWriteRule(groupId),
    },
  },

  COUNCIL_PRESIDENT: ["tithes:read", "tithes:write", "reports:read"],
};

export default rules;
