import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { DynamicCard, ProgramPart } from "components";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";

import { ProgramDetail } from "models";

import AddIcon from "@material-ui/icons/Add";
import CreateEditProgramDetail from "./components/CreateEditProgramDetail";
import ConfirmContentToDelete from "./components/ConfirmContentToDelete";
import { Confirm } from "components";
import translate from "i18nProvider/translate";
import { ProgramsService, MembersService } from "services";
import { withRouter } from "react-router-dom";
import { refreshListAfterEdit } from "helpers";
import { showAlertSuccess } from "redux/slices/alertsSlice";
import store from "redux/store";
import { useFetch } from "hooks/useFetch";

const useStyles = makeStyles((theme) => ({
  cardContent: {
    paddingTop: 0,
  },
  modalContent: {
    padding: theme.spacing(3),
  },
  cancelBtn: {
    marginLeft: 10,
  },
  programPartActionArea: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  addHeaderIcon: {
    fontSize: 28,
  },
  confirmDialogHeader: {
    padding: "20px 24px 8px 24px",
    "& > div > span": {
      color: "red",
      textAlign: "center",
    },
  },
  fab: {
    cursor: "pointer",
    borderRadius: "50% 50%",
    width: "26px",
    height: "26px !important",
    backgroundColor: "#00bfa5",
    fontSize: "14px",
    color: "white",
    boxShadow:
      "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
  },
}));

const ProgramDetails = ({
  className,
  programId,
  match,
  disableActions,
  setProgramDetails,
}) => {
  const classes = useStyles();
  const { data: programPartList, fetchRoot: fetchProgramParts } = useFetch({
    rootUrl: "/program-parts",
    defaultValue: [],
  });

  const CHURCH_ID = match.params.churchId;
  const GROUP_ID = match.params.groupId;
  const programsService = new ProgramsService(CHURCH_ID);

  const [openCreateEdit, setOpenCreateEdit] = useState(false);
  const [detail, setDetail] = useState(new ProgramDetail());
  const [details, setDetails] = useState([]);

  useEffect(() => {
    fetchProgramParts();
  }, []);

  useEffect(() => {
    const detailsMap = details.map(det => ({id: det.id, memberId: det.memberId.id.toString(), programPartId: det.programPartId.id.toString()}));
    setProgramDetails(detailsMap);
  },[details]);

  const onAddBtnClick = () => {
    if (disableActions) return;
    setDetail(new ProgramDetail());
    setOpenCreateEdit(true);
  };

  const onEditBtnClick = (index) => {
    if (disableActions) return;
    let det = details[index];
    if (det.id) {
      setDetail(det);
    } else {
      setDetail({ ...det, id: -1 });
    }
    setOpenCreateEdit(true);
  };

  const onRemoveBtnClick = async (index) => {
    if (disableActions) return;
    let det = details[index];
    if (det) {
      if (await confirmDeleteDetail(det)) {
        deleteDetail(det);
      }
    }
  };

  const deleteDetail = async (det) => {
    try {
      if (det.id > 0) {
        if (GROUP_ID) {
          await programsService.deleteGroupProgramDetail(
            GROUP_ID,
            programId,
            det.id
          );
        } else {
          await programsService.deleteChurchProgramDetail(programId, det.id);
        }
      }
      removeDeletedFromList(det);
      store.dispatch(showAlertSuccess(translate("detailSuccDeleted")));
    } catch (error) {
      console.log(error);
    }
  };

  const removeDeletedFromList = (det) => {
    if (det.id > 0  ) {
      const filtered = details.filter((d) => {
        return d.id !== det.id;
      });
      setDetails(filtered);
    } else {
      const index = details.indexOf(det);
      if (index !== -1) {
        const filtered = details.filter((d) => {
          return d.id !== det.id;
        });
        setDetails(filtered);
      }
    }
  };

  const confirmDeleteDetail = async (det) => {
    const myComponent = <ConfirmContentToDelete detail={det} />;
    const result = await Confirm.show({
      message: translate("warningDeleteDetail"),
      content: myComponent,
    });

    return result;
  };

  const addCreatedToDetailsList = (created) => {
    setDetails([...details, created]);
  };

  const addEditedToDetailsList = (edited) => {
    const list = refreshListAfterEdit(edited, details);
    setDetails(list);
    setDetail(new ProgramDetail());
  };
  const getProgramPartDetails = React.useCallback(async () => {
    try {
      if (programId) {
        let fetchedDetails;
        if (GROUP_ID) {
          fetchedDetails = await new ProgramsService(
            CHURCH_ID
          ).getGroupProgramDetailsByProgramId(GROUP_ID, programId);
        } else {
          fetchedDetails = await new ProgramsService(
            CHURCH_ID
          ).getChurchProgramDetailsByProgramId(programId);
        }

        fetchedDetails = fetchedDetails.sort((a, b) => {
          return a.id - b.id;
        });
        setDetails(
          fetchedDetails.map((item) => {
            return {
              programPartId: {
                id: item.programPartId,
                name: item.programPartName,
              },
              memberId: { id: item.memberId, name: item.memberName },
              id: item.id,
            };
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  }, [programId, CHURCH_ID, GROUP_ID]);

  const headerAction = () => (
    <AddIcon
      className={classes.fab}
      style={{ opacity: disableActions ? "0.3" : "1" }}
      onClick={onAddBtnClick}
    />
  );

  /// remove later
  const fetchMembers = React.useCallback(async () => {
    if (GROUP_ID) {
      store.dispatch(
        new MembersService(CHURCH_ID).getMembersByGroupId(GROUP_ID)
      );
    } else {
      store.dispatch(new MembersService(CHURCH_ID).getMembersByChurchId());
    }
  }, [CHURCH_ID, GROUP_ID]);

  React.useEffect(() => {
    getProgramPartDetails();
    // getProgramParts();
  }, [getProgramPartDetails]);

  useEffect(() => {
    /// remove later
    fetchMembers();
  }, [fetchMembers]);
  return (
    <>
      <DynamicCard
        classNameCard={className}
        classNameCardContent={classes.cardContent}
        headerAction={headerAction()}
        headerTitle={translate("programDetails")}
      >
        <Grid container spacing={2}>
          {details.map((det, i) => (
            <Grid key={`det${i}`} item xs={12} sm={4} md={3} lg={2}>
              <ProgramPart
                disableActions={disableActions}
                member={det.memberId}
                programPart={det.programPartId}
                onEditClick={() => onEditBtnClick(i)}
                onRemoveClick={() => onRemoveBtnClick(i)}
              />
            </Grid>
          ))}
        </Grid>
      </DynamicCard>
      {openCreateEdit && (
        <CreateEditProgramDetail
          details={details}
          open={openCreateEdit}
          setOpen={setOpenCreateEdit}
          detailToEdit={detail}
          programId={programId}
          addCreatedToDetailsList={addCreatedToDetailsList}
          addEditedToDetailsList={addEditedToDetailsList}
          programParts={programPartList}
        />
      )}
    </>
  );
};

ProgramDetails.propTypes = {
  details: PropTypes.array,
  className: PropTypes.string,
  onAddDetailEvent: PropTypes.func,
  onRemoveEvent: PropTypes.func,
  onEditDetailEvent: PropTypes.func,
};

export default withRouter(ProgramDetails);
