import { createSlice } from "@reduxjs/toolkit";
import { getChurchMembersCountMap } from "tools/sessionStorageUtils";

const sessionState = { rules: [], shouldUpdateToken: false };

const sessionSlice = createSlice({
  name: "session",
  initialState: sessionState,
  reducers: {
    setSession(state, action) {
      const membersCountCache = getChurchMembersCountMap();
      const churches = action.payload.user?.churches;
      if (churches && membersCountCache) {
        churches.forEach((church) => {
          let count = membersCountCache.get(church.id);
          if (count) {
            church.members += count;
          }
        });
      }
      return action.payload;
    },
    removeSession(state, action) {
      return {};
    },
    addRules(state, action) {
      state.rules = action.payload;
    },
    setShouldUpdateToken(state, action) {
      state.shouldUpdateToken = action.payload;
    },
    addChurchAccess(state, action) {
      if (state.churchAccess) {
        state.churchAccess.push(action.payload);
      } else {
        state.churchAccess = [action.payload];
      }
    }
  },
});

export const { setSession, removeSession, addRules, setShouldUpdateToken, addChurchAccess } = sessionSlice.actions;

export default sessionSlice.reducer;
