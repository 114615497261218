import React, { useEffect, useState } from "react";
import {
  Container,
  CardMedia,
  makeStyles,
  Box,
  Avatar,
  Button,
  TextField,
  Card,
  Grid,
  Link,
} from "@material-ui/core/";

import userImg from "assets/images/user.jfif";
import { decodeToken, getGlobalRules, updateTokens } from "../../auth";
import { setAuthorizationToken } from "tools/api";
import { setSession } from "../../redux/slices/sessionSlice";
import store from "../../redux/store";
import translate from "i18nProvider/translate";
import { useFetch } from "hooks/useFetch";
import { InvalidCredentialsError } from "tools/errors";
import { showAlertError } from "redux/slices/alertsSlice";
import { hideLoading, showLoading } from "redux/slices/loadingSlice";
import { CHURCH_MEMBERS_COUNT_KEY } from "tools/sessionStorageUtils";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    width: "100%",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  avatar: {
    width: "150px",
    height: "150px",
  },
  userPhoto: {
    width: "100%",
    height: "auto",
  },
  card: {
    padding: "15px",
    marginTop: "25px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const SignInView = ({ history }) => {
  const classes = useStyles();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const { isLoading, error, data, fetchRoot } = useFetch({
    rootUrl: "/oauth/token",
    initialValue: {},
  });

  useEffect(() => {
    if (data.access_token) {
      updateTokens(data);
      setAuthorizationToken(data.access_token);
      const decoded = decodeToken(data.access_token);
      if (decoded) {
        sessionStorage.removeItem(CHURCH_MEMBERS_COUNT_KEY);
        const rules = getGlobalRules(decoded.authorities);
        store.dispatch(setSession({ ...decoded, rules: rules }));
        store.dispatch(hideLoading());
        window.location = "/";
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      store.dispatch(hideLoading());

      if (error instanceof InvalidCredentialsError) {
        dispatchAlertError("invalidCredentials");
      } else {
        dispatchAlertError("theActionCouldNotBeComplete");
      }
    }
  }, [error]);

  const dispatchAlertError = (errorStr) => {
    store.dispatch(showAlertError(translate(errorStr)));
  };

  const handleClickLogIn = async (e) => {
    e.preventDefault();

    const body = `username=${encodeURIComponent(
      credentials.username
    )}&password=${encodeURIComponent(
      credentials.password
    )}&grant_type=password`;
    const Authorization =
      "Basic " +
      window.btoa(
        process.env.REACT_APP_TOKEN_AUTH_NAME +
          ":" +
          process.env.REACT_APP_TOKEN_AUTH_PASSWORD
      );
    const configs = {
      method: "POST",
      body,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization,
      },
    };

    store.dispatch(showLoading());
    fetchRoot(configs);
  };

  const handleFieldsOnChange = (e) => {
    const {
      target: { name, value },
    } = e;

    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      <Card className={classes.card}>
        <Avatar className={classes.avatar}>
          <CardMedia>
            <img className={classes.userPhoto} src={userImg} alt="user" />
          </CardMedia>
        </Avatar>
        <div className={classes.paper}>
          <form className={classes.form} onSubmit={handleClickLogIn}>
            <Box mb={3}>
              <TextField
                variant="outlined"
                margin="dense"
                required
                fullWidth
                label={translate("username")}
                autoFocus
                name="username"
                onChange={handleFieldsOnChange}
              />
            </Box>
            <TextField
              variant="outlined"
              margin="dense"
              required
              fullWidth
              name="password"
              label={translate("password")}
              type="password"
              autoComplete="current-password"
              onChange={handleFieldsOnChange}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {translate("signIn")}
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  {translate("forgotPassword")}
                </Link>
              </Grid>
            </Grid> */}
            <Grid container>
              <Grid item xs>
                <Link
                  href="/users/request-password-change"
                  style={{ fontSize: 14 }}
                >
                  {translate("forgotPassword")}
                </Link>
              </Grid>
              <Grid item>
                <Link href="/create-account" style={{ fontSize: 14 }}>
                  {translate("signUp")}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        {/* <Box mt={8}>
          <Copyright />
        </Box> */}
      </Card>
    </Container>
  );
};

export default SignInView;
