import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/styles";
import { DynamicTable, Confirm } from "components";
import { AdminToolbar, CreateEditChurch } from "./components";
import translate from "i18nProvider/translate";
import { connect } from "react-redux";
import { ChurchesService } from "services";
import { Box, TableCell } from "@material-ui/core";
import { Church } from "models";
import {
  People as PeopleIcon,
  AccountCircle as AccountCircleIcon,
} from "@material-ui/icons";
import { Link } from "react-router-dom";

const headers = [
  translate("active"),
  translate("name"),
  translate("email"),
  translate("phone"),
  translate("address"),
  translate("dateCreated"),
];

const fields = ["name", "email", "phone", "address", "dateCreated"];

const useStyles = makeStyles((theme) => ({
  content: {
    padding: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      margin: "0px auto",
      maxWidth: "1500px",
    },
  },
  appBar: {
    position: "relative",
    marginBottom: theme.spacing(2),
  },

  membersIcon: {
    marginRight: "10px",
  },
  iconsCell: {
    minWidth: 94,
  },
}));

const AdminView = ({ churches, dispatch }) => {
  const classes = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filteredChurches, setFilteredChurches] = useState(churches);
  const [openCreateEdit, setOpenCreateEdit] = useState(false);
  const [churchToEdit, setChurchToEdit] = useState(new Church());

  const fetchChurches = React.useCallback(() => {
    dispatch(new ChurchesService().getAllChurches());
  }, [dispatch]);

  useEffect(() => {
    setFilteredChurches(churches);
  }, [churches]);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const handleRowClick = (church) => {
    setChurchToEdit(church);
    setOpenCreateEdit(true);
  };

  const handleChangeChurchStatus = async (e, item) => {
    const result = await Confirm.show({
      message: translate("toggleChurch"),
    });
    if (result) {
      dispatch(new ChurchesService().toggleChurchStatus(item));
    }
  };

  const CustomIconsCell = ({ churchId }) => {
    return (
      <TableCell className={classes.iconsCell}>
        <Link
          to={`/admin/churches/${churchId}/members`}
          className={classes.membersIcon}
        >
          <PeopleIcon color="primary" />
        </Link>
        <Link to={`/admin/churches/${churchId}/users`}>
          <AccountCircleIcon color="primary" />
        </Link>
      </TableCell>
    );
  };

  useEffect(() => {
    fetchChurches();
  }, [fetchChurches]);

  return (
    <Box className={classes.content}>
      <AdminToolbar
        className={classes.appBar}
        setDataOnSearch={setFilteredChurches}
        dataToSearch={churches}
        keysToSearch={fields}
        onAddBtnClicked={() => {
          setChurchToEdit({ active: true });
          setOpenCreateEdit(true);
        }}
      />
      <DynamicTable
        data={filteredChurches}
        headers={headers}
        fields={fields}
        selectedItem={churchToEdit}
        count={filteredChurches.length}
        page={page}
        rowsPerPage={rowsPerPage}
        handleRowClick={handleRowClick}
        handlePageChange={handlePageChange}
        handleRowsPerPageChange={handleRowsPerPageChange}
        showActiveCheckBox={true}
        handleToggleActive={handleChangeChurchStatus}
        CustomIconsCell={CustomIconsCell}
      />
      {openCreateEdit && (
        <CreateEditChurch
          open={openCreateEdit}
          setOpen={setOpenCreateEdit}
          dispatch={dispatch}
          churchToEdit={churchToEdit}
        />
      )}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  churches: state.churches,
});

export default connect(mapStateToProps)(AdminView);
