import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { AppBar, Toolbar, IconButton, Menu, MenuItem } from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreVert";
import { LanguagesMenu, SignOutMenu } from "components";
import { getToken } from "auth";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backgroundColor: "#fff",
  },
  flexGrow: {
    flexGrow: 1,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    color: theme.palette.icon,
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  homeTitle: {
    color: "white",
  },
  brandWrapper: {
    height: 70,
  },
  toolbar: {
    paddingLeft: 0,
    boxShadow: "#929292 0px 3px 6px",
  },
}));

const Topbar = (props) => {
  const { className, session, match } = props;

  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = "primary-search-account-menu-mobile";

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <LanguagesMenu callback={() => handleMobileMenuClose()} />
      </MenuItem>
      {getToken() && (
        <MenuItem>
          <SignOutMenu />
        </MenuItem>
      )}
    </Menu>
  );

  return (
    <div>
      <AppBar
        // {...rest}
        className={clsx(classes.root, className)}
        color="primary"
        position="fixed"
      >
        <Toolbar className={classes.toolbar}>
          <RouterLink
            className={classes.brandWrapper}
            to={
              session.authorities && session.authorities.includes("SUPER_ADMIN")
                ? "/admin/churches"
                : "/"
            }
          >
            <img src="https://church-en-linea.s3.amazonaws.com/images/cel-logo.png" alt="no img available" width={230} style={{ marginTop: 2, marginLeft: 15 }} />
          </RouterLink>
          <div className={classes.flexGrow} />

          {session.authorities &&
            session.authorities.includes("SUPER_ADMIN") &&
            match.url !== "/admin/churches" && (
              <RouterLink to="/admin/churches">
                <IconButton style={{ color: "white" }} title="back">
                  <KeyboardBackspaceIcon />
                </IconButton>
              </RouterLink>
            )}
          <div className={classes.sectionDesktop}>
            <LanguagesMenu />
            {getToken() && <SignOutMenu />}
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
};

const mapStateToProps = (state) => ({
  session: state.session,
});

export default withRouter(connect(mapStateToProps)(Topbar));
