import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import store from "redux/store";
import PropTypes from "prop-types";

const MuiPickersUtils = ({ children }) => {
  return (
    <MuiPickersUtilsProvider
      locale={store.getState().language === "es-es" ? deLocale : enLocale}
      utils={DateFnsUtils}
    >
      {children}
    </MuiPickersUtilsProvider>
  );
};

MuiPickersUtils.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MuiPickersUtils;
