import translate from "i18nProvider/translate";

export const baptizedOptions = [
  {
    value: true,
    label: translate("yes"),
  },
  {
    value: false,
    label: "No",
  },
];

export const membersTablefields = [
  "firstName",
  "lastName",
  "personalId",
  "phone",
  "phone2",
  "email",
  "status",
  "joiningDate",
];

export const membersTableHeaders = [
  "firstname",
  "lastname",
  "personalId",
  "phone",
  "phone2",
  "email",
  "status",
  "joiningDate",
];

export const transactionTableFields = [
  "id",
  "amount",
  "paymentMethod",
  "transactionDesc",
  "memberName",
  "createdDate",
];
export const transactionTableHeaders = [
  "id",
  "amount",
  "paymentMethod",
  "transactionType",
  "member",
  "createdDate",
];

export const paymentMethods = [
  { value: "CASH", label: "CASH" },
  {
    value: "BANK_TRANSACTION",
    label: "BANK_TRANSACTION",
  },
];

export const transactionsTypes = [
  { value: "TITHES", label: "TITHES" },
  { value: "OFFERINGS", label: "OFFERINGS" },
  { value: "DONATIONS", label: "DONATIONS" },
  { value: "OUTGOING", label: "OUTGOING" },
];
