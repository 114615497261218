import React from "react";
import { useHistory } from "react-router-dom";
import { Box, Grid, TextField } from "@material-ui/core";
import { FullScreenDialog } from "components";
import { useFormik } from "formik";
import translate from "i18nProvider/translate";

const RoleForm = ({ onSubmit }) => {
  const history = useHistory();
  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { name: "" },
    onSubmit: onSubmit,
    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = translate("fieldIsRequired");
      }
      return errors;
    },
  });

  return (
    <FullScreenDialog
      title={translate("roleForm")}
      handleClose={() => history.goBack()}
      open={true}
      onSubmit={handleSubmit}
      saveBtnLabel={false ? "edit" : "create"}
    >
      <Box>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              required
              name="name"
              label={translate("name")}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              error={errors.name}
            />
          </Grid>
          {/* <Grid item xs={12} sm={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={church.active}
                  onChange={handleChangeActive}
                  name="active"
                />
              }
              label="Active"
            />
          </Grid> */}
        </Grid>
      </Box>
    </FullScreenDialog>
  );
};

export default RoleForm;
