import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { FullScreenDialog } from "components";
import { Church } from "models";
import translate from "i18nProvider/translate";
import { ChurchesService } from "services";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";

const CreateEditChurch = injectIntl(({ dispatch }) => {
  const [open, setOpen] = useState(false);
  const [church, setChurch] = useState(new Church());
  const history = useHistory();

  const handleInputChange = (e) => {
    setChurch({
      ...church,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeActive = (e) => {
    setChurch({
      ...church,
      active: e.target.checked,
    });
  };

  const handleFormSubmit = () => {
    if (church.id) {
      handleClickEditChurch();
    } else {
      handleClickCreateChurch();
    }
  };

  const handleClickEditChurch = () => {
    dispatch(new ChurchesService().editChurch(church));
    history.goBack();
  };

  const handleClickCreateChurch = () => {
    dispatch(new ChurchesService().create(church));
    history.goBack();
  };

  useEffect(() => setOpen(true), []);

  return open ? (
    <FullScreenDialog
      title={translate("churchForm")}
      handleClose={() => history.goBack()}
      open={open}
      onSubmit={handleFormSubmit}
      saveBtnLabel={false ? "edit" : "create"}
    >
      <Box>
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              required
              name="name"
              label={translate("name")}
              onChange={handleInputChange}
              value={church.name}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              required
              name="email"
              type="email"
              label={translate("email")}
              onChange={handleInputChange}
              value={church.email}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              required
              name="phone"
              type="numeric"
              label={translate("phone")}
              onChange={handleInputChange}
              value={church.phone}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              required
              name="address"
              label={translate("address")}
              onChange={handleInputChange}
              value={church.address}
            />
          </Grid>
          {church.id && (
            <Grid item xs={12} sm={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={church.active}
                    onChange={handleChangeActive}
                    name="active"
                  />
                }
                label="Active"
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </FullScreenDialog>
  ) : null;
});

const mapStateToProps = (state) => ({
  church: state.church,
  shouldUpdateToken: state.shouldUpdateToken,
});

export default connect(mapStateToProps)(CreateEditChurch);
