import { LOCALES } from "../constants";

export default {
  [LOCALES.SPANISH]: {
    //home
    addNewChurch: "Agregar Iglesia",
    noResourcesFound: "Aun no te han asignado un role.",
    refresh: "Actualizar",
    contactLeader: "Favor contacta a tu lider para que te asignen roles",

    //signIn
    username: "Usuario",
    password: "Contraseña",
    signIn: "Iniciar sesion",
    forgotPassword: "Olvidaste tu Contraseña?",
    signUp: "No tienes cuenta? Registrarse",

    //create-account
    completeChurchInfo: "Completa la información de tu iglesia",
    completePersonalInfo: "Completa tu información personal",
    completePaymentMethod: "Completa tu metodo de pago",
    churchInfo: "Información de la Iglesia",
    personalInfo: "Información Personal",
    paymentInfo: "Información del metodo de pago",
    cardNumber: "Numero de la targeta",
    expirationDate: "Fecha de expiración",
    cvv: "CVV",
    cardInformation: "Información de la targeta",
    country: "Pais",
    zipCode: "Codigo Zip",
    confirmPassword: "Confirmar contraseña",
    confirmationLinkSent:
      "Le hemos enviado un enlace de confirmacion a su correo electronico",
    submit: "Enviar",
    emailAlreadyHasUserRequest:
      "Este email ya tiene una solicitud de usuario, revise la bandeja de entrada de su E-mail",
    bothPasswordDidNotMatch: "Las contraseñas no coinciden",
    readyToSignIn: "Listo! Ya puedes ingresar con tu email y contraseña",

    //sideBarNav
    appTitle: "Church En Linea",
    members: "Miembros",
    users: "Usuarios",
    programs: "Programas",
    transactions: "Transacciones",
    reports: "Reportes",
    settings: "Configuracion",

    //members table
    firstname: "Nombre",
    lastname: "Apellido",
    personalId: "Identificacion",
    phone: "Telefono",
    phone2: "Telefono 2",
    email: "Correo",
    status: "Estado",
    joiningDate: "Fecha de Ingreso",

    //members
    membersformTitle: "Formulario de Miembros",
    identification: "Identificacion",
    emailAddress: "Correo Electronico",
    phoneNumber1: "Numero telefonico 1",
    phoneNumber2: "Numero telefonico 2",
    baptized: "Bautizado",
    baptizedDate: "Fecha de Bautismo",
    dateOfBirth: "Fecha de Nacimiento",
    member: "Miembro",
    memberSuccessfullyCreated: "Miembro creado con exito",
    memberSuccessfullyEdited: "Miembro editado con exito",
    confirmDeleteMember: "Estas seguro que quieres eliminar este miembro ?",
    memberSuccessfullyDeleted: "Miembro eliminado con exito!",
    memberNotDeleted: "El miembro no pudo ser eliminado eliminado",
    memberAlreadyHasUser: "Este usuario ya fue requerido",
    mustAddEmailToMember:
      "Tienes que agregar un email a este miembro para poder crear un usuario",
    memberAlreadyRegisteredWithEmail:
      "Se ha encontrado un miembro con este E-mail",
    memberHasUserAlreadyTitle: "El miembro ya tiene usuario",
    memberHasUserAlreadyBody:
      "Este email tiene un usuario asignado en nuestra plataforma, desea agregarlo como usuario de su iglesia?",

    //UserFom
    usersFormTitle: "Formulario de usuarios",
    roleRequired: "Debes seleccionar al menos un role",
    requestUser: "Solicitar Usuario",
    requestSent: "Solicitud de usuario enviada con exito!",
    userConfirmationPrefix: "Para solicitar un usuario a ",
    userConfirmationSuffix: " debe hacer click en Solicitar Usuario",

    //Users View
    registeredUsers: "Usuarios Registrados",
    requestedUsers: "Usuarios Solicitados",
    toggleUserActive: "Estas seguro que deseas desactivar este usuario ?",
    toggleUserInactive: "Estas seguro que deseas activar este usuario ?",
    userSuccessfullyEdited: "Usuario editado con exito",
    editUser: "Editar usuario",
    deleteUser: "Eliminar usuario",
    userSuccessfullyDeleted: "Usuario eliminado con exito!",
    confirmDeleteUser: "Estas seguro de que deseas eliminar este usuario ?",
    confirmDeleteUserRequest:
      "Estas seguro de que deseas eliminar esta solicitud ?",
    userRequestSuccesfullyDeleted: "Solicitud de usuario eliminada!",
    deleteUserRequest: "Eliminar solicitud de usuario",
    userAddedSuccessfully: "El usuario se agrego exitosamente!",

    //validations
    filloutfield: "Favor completar este campo",
    writeValidEmail: "Favor escribir un email valido",
    selectItem: "Favor seleccionar un elemento de la lista",
    minimalDate: "La fecha no puede ser anterior al dia de hoy",

    //Groups
    groupsFormTitle: "Formulario de Grupos",
    addNewGroup: "Agregar un nuevo grupo",
    backToMembers: "Volver a Grupos",
    churchMembers: "Miembros de la iglesia",
    removeMembers: "Remover los miembros seleccionados",
    specifyName: "Favor especificar el nombre",
    specifyLeader: "Favor especificar el lider",
    specifyDescription: "Favor especificar la description",
    groupImage: "Imagen del grupo",
    leader: "Lider",
    removeSelectedMembers: "Remover los miembros seleccionados",
    addNewMember: "Agregar un nuevo miembro",
    addNewMembers: "Agregar nuevos miembros",
    editMember: "Editar este miembro",
    removeUserRequest: "Remover solicitud de usuario",
    confirmRemoveMember: "Estas seguro que deseas remover este miembro ?",
    memberSuccessfullyAdded: "Miembro agregado con exito",
    groupSuccessfullyCreated: "Grupo creado con exito",
    groupSuccessfullyEdited: "Grupo editado con exito",
    groupName: "Nombre del Grupo",
    groupSuccessfullyDeleted: "Grupo eliminado con exito!",
    confirmDeleteGroup: "Estas seguro de que quieres eliminar este grupo ?",

    //transactions
    cash: "Efectivo",
    creditCard: "Tarjeta de Credito",
    debitCard: "Tarjeta de Debito",
    bankTransaction: "Transaccion Bancaria",
    deposit: "Deposito",
    tithes: "Diezmos",
    offerings: "Ofrendas",
    donations: "Donaciones",
    outgoing: "Gastos",
    amount: "Cantidad",
    paymentMethod: "Metodo de pago",
    transactionType: "Tipo de transaccion",
    date: "Fecha",
    addNewTransaction: "Agregar nueva transaccion",
    transactionsForm: "Formulario de transacciones",
    transactionId: "ID de la transaccion",
    specifyAmount: "Favor de especificar la cantidad",
    transactionDescription: "Description de la tran",
    confirmTransaction: "Los siguientes datos son correctos ?",
    specifyPaymentMethod: "Favor de especificar el metodo de pago",
    specifyTransactionType: "Favor de especificar el tipo de transaccion",
    validateTransactionDate:
      "No se pueden modificar transacciones con mas de 24 de antiguedad",
    transactionSuccessfullyCreated: "Transaccion creada con exito",
    transactionSuccessfullyEdited: "Transaccion editada con exito",
    transactionDetails: "Detalles de la transaccion",
    specifyTransactionDetails: "Especificar los detalles de la transaccion",
    transactionDate: "Fecha de la transaccion",

    //settings
    createProgranPart: "Crear Parte de Programa",
    editProgramPart: "Editar Parte de Programa",
    programParts: "Partes de Programa",
    manageProgramParts: "Gestionar Partes de Programa",
    addNewProgramPart: "Agregar nueva parte de Programa",
    programPartSuccessfullyCreated: "Parte de programa agregada con exito",
    programPartSuccessfullyEdited: "Parte de programa editada con exito",

    //access manager
    role: "Role",
    roleAccessManager: "Manejo de acceso de roles",
    accessManagement: "Manejo de acceso",
    ProgramPart: "Parte de Programa",
    modules: "Modulos",
    accessTypes: "Tipos de acceso",
    addModule: "Agregar modulo",
    readOnly: "Solo lectura",
    readAndWrite: "Lectura y escritura",
    fullAccess: "Acceso completo",
    addRole: "Agregar Role",
    editRole: "Editar Role",
    roleForm: "Formulario de Role",
    readAccess: "Acceso de Lectura",
    writeAccess: "Acceso de Escritura",
    deleteAccess: "Acceso a Borrar",
    executeAccess: "Acceso a Ejecutar",
    roleSavedSuccessfully: "Role guardado exitosamente",
    roleDeletedSuccessfully: "Role borrado exitosamente",
    confirmRoleDelete: "Estas seguro de borrar este role?",
    needToSelectModule: "Debe seleccionar un modulo al menos",
    notAuthorizedError: "No estas autorizado para ver este recurso",

    //modules
    CHURCH_MEMBERS: "MIEMBROS DE IGLESIA",
    MEMBERS: "MIEMBROS",
    USERS: "USUARIOS",
    CHURCH_USERS: "USUARIOS DE IGLESIA",
    PROGRAMS: "PROGRAMAS",
    CHURCH_PROGRAMS: "PROGRAMAS DE IGLESIA",
    PROGRAM_PARTS: "PARTES DEL PROGRAMA",
    TRANSACTIONS: "TRANSACTIONES",
    CHURCH_TRANSACTIONS: "TRANSACTIONES DE IGLESIA",
    GROUP_MEMBERS: "MIEMBROS DE GRUPO",
    GROUP_PROGRAMS: "PROGRAMAS DE GRUPO",
    GROUP_MANAGEMENT: "MANEJO DE GRUPO",
    GROUP_LEADERS: "LIDERES DE GRUPO",
    GROUP_REPORTS: "REPORTES DE GRUPO",
    GROUP_TRANSACTIONS: "TRANSACCIONES DE GRUPO",
    ACCESS_MANAGEMENT: "MANEJO DE ACCESO",
    CHURCH_MANAGEMENT: "MANEJO DE IGLESIA",
    CHURCHES: "IGLESIAS",
    CHURCH_LEADERS: "LIDERES DE IGLESIA",
    CHURCH_REPORTS: "REPORTES DE IGLESIA",

    //programs
    addProgram: "Crear Programa",
    editProgram: "Editar Programa",
    specifyTitle: "Favor de especificar el titulo",
    title: "Titulo",
    startDate: "Fecha de inicio",
    endDate: "Fecha de fin",
    programSuccessfullyCreated: "Programa creado con exito",
    programSuccessfullyEdited: "Programa editado con exito",
    programDate: "Fecha del Programa",
    detailName: "Nombre del detalle ",
    programName: "Nombre del programa",

    //program details
    addNewProgramDetail: "Agregar nuevo detalle al programa",
    addProgramDetail: "Agregar detalle al programa",
    editProgramDetail: "Editar detalle del programa",
    programDetails: "Detalles del Programa",
    programPart: "Parte de Programa",
    programDetailSuccCreated: "Detalle de Programa creado con exito",
    programDetailSuccEdited: "Detalle de Programa editado con exito",
    warningDeleteDetail: "Esta seguro de que deseas eliminar este detalle ?",
    detailSuccDeleted: "Detalle eliminado con exito",
    detailAlreadyExist: "Este detalle de programa ya existe",

    //Actions
    save: "Guardar",
    create: "Crear",
    edit: "Editar",
    remove: "Remover",
    manage: "Manejar",
    add: "Agregar",
    accept: "Aceptar",
    search: "Buscar",
    next: "Siguiente",
    back: "Atras",
    finish: "Finalizar",
    confirm: "Confirmar",

    //church
    church: "Iglesia",
    toggleChurch: "Estas seguro que deseas desactivar esta iglesia ?",
    churchSeccessfullyCreated: "Iglesia creada con exito",
    churchSeccessfullyEdited: "Iglesia editada con exito",
    churchForm: "Formulario de Iglesia",

    //churchCard
    churchImage: "Imagen de la iglesia",

    //sidebar
    myChurches: "MIS IGLESIAS",
    myGroups: "MIS GRUPOS",

    //======== Reports =====
    downloadData: "Descargar Datos",
    filters: "Filtros",
    clear: "Limpiar",
    applyFilters: "Aplicar Filtros",
    //--- members--
    joiningDateRange: "Rango de Fecha de Ingreso",
    baptizeRange: "Rango de Bautismo",

    //common
    onlineChurch: "Iglesia En Linea",
    churches: "Iglesias",
    groups: "Grupos",
    goTo: "Ir a",
    cancel: "Cancelar",
    yes: "Si",
    group: "Grupo",
    user: "Usuario",
    active: "Activo",
    name: "Nombre",
    address: "Direccion",
    description: "Descripcion",
    dateCreated: "Fecha de creacion",
    successfullyCreated: "{entity} creado(a) con exito",
    successfullyEdited: "{entity}  editado(a) con exito",
    successfullyDeleted: "{entity} eliminado(a) con exito",
    signOut: " Estas seguro que quieres terminar la sesion ?",
    all: "TODOS",
    roles: "Roles",
    id: "ID",
    delete: "Eliminar",
    viewInfo: "Ver Info",
    assignLeaders: "Asignar Lideres",
    leaders: "Lideres",
    addLeaders: "Agregar Lideres",
    createdDate: "Fecha de Creacion",

    //Roles
    secretary: "Secretaria/o",
    councilPresident: "Presidente de consejo",
    groupLeader: "Lider de grupo",

    //status
    admonished: "Amonestado",
    astray: "Descariado",
    inactive: "Inactivo",

    //Forgot password
    requestPasswordChangeEmailSent:
      "Te hemos enviado un email con un enlace para que puedas cambiar tu clave",
    passwordChangedSuccessfully: "Tu clave ha sido cambiada exitosamente",

    // ==============HTTP MESSAGE=============
    USER_NOT_FOUND: "No tenemos usuario registrado con este email",
    USER_DISABLED: "Su acceso ha sido temporalmente desactivado",
    BAD_CREDENTIALS: "Usuario o Contraseña incorrectos",
    PASSWORD_LENGTH_IS_TOO_SHORT:
      "La Contraseña debe ser de al menos 6 caracteres",
    "Bad credentials": "Usuario o Contraseña incorrectos",
    pageNotFound: "Pagina no encontrada",
    invalidCredentials: "Usuario o Contraseña incorrectos",
    theActionCouldNotBeComplete: "La accion no pudo ser completada",

    // ================ Form Errors ===============
    fieldIsRequired: "Campo requerido",
  },
};
