import React from "react";
import { TextField } from "@material-ui/core";
import { translateEnum } from "helpers";
import PropTypes from "prop-types";
import translate from "i18nProvider/translate";

const Select = ({
  label = "",
  onChange = () => {},
  value,
  options = [],
  name = "",
  optionLabelExpression = null,
  ...rest
}) => {
  const handleInvalidSelect = (e) => {
    e.target.setCustomValidity(translate("selectItem"));
  };

  return (
    <TextField
      fullWidth
      label={translate(label)}
      margin="dense"
      name={name}
      onChange={onChange}
      select
      SelectProps={{ native: true }}
      value={value}
      variant="outlined"
      onInvalid={handleInvalidSelect}
      {...rest}
    >
      <option value="" />
      {options.map((option, index) => (
        <option
          key={`select-${option.id ? option.id : option.value}-${index}`}
          value={option.id ? option.id : option.value}
        >
          {optionLabelExpression
            ? // eslint-disable-next-line
              eval(optionLabelExpression)
            : translateEnum(option.name ? option.name : option.label)}
        </option>
      ))}
    </TextField>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default Select;
