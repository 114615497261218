import React from "react";
import { Link as RouterLink, withRouter } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import {
  AppBar,
  Toolbar,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import { LanguagesMenu, SignOutMenu } from "components";

import HomeIcon from "@material-ui/icons/Home";

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none",
    backgroundColor: "#fff",
  },
  flexGrow: {
    flexGrow: 1,
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
    color: theme.palette.icon,
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    color: theme.palette.icon,
  },

  toolbar: {
    paddingLeft: 0,
    boxShadow: "#929292 0px 3px 6px",
    color: theme.palette.icon,
    display: "flex",
    flexDirection: "row",
  },
  brandWrapper: {
    height: 70,
  },
  iconButton: {
    padding: "7px",
    [theme.breakpoints.up("sm")]: {
      padding: "12px",
    },
  },
  homeIcon: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
}));

const Topbar = (props) => {
  const { className, onSidebarOpen, match } = props;
  const classes = useStyles();

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const mobileMenuId = "primary-search-account-menu-mobile";

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
      color="inherit"
    >
      <MenuItem>
        <LanguagesMenu />
      </MenuItem>
      <MenuItem>
        <SignOutMenu />
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar className={clsx(classes.root, className)}>
        <Toolbar className={classes.toolbar}>
          <RouterLink className={classes.brandWrapper} to="/">
            <img src="https://church-en-linea.s3.amazonaws.com/images/cel-logo.png" alt="no img available" width={230} style={{ marginTop: 2, marginLeft: 15 }} />
          </RouterLink>
          <div className={classes.homeIcon}>
            {!match.url.includes("home") && (
              <RouterLink to="/">
                <IconButton color="primary">
                  <HomeIcon />
                </IconButton>
              </RouterLink>
            )}
          </div>
          <div className={classes.flexGrow} />

          <div className={classes.sectionDesktop}>
            <LanguagesMenu />
            <SignOutMenu />
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              className={classes.iconButton}
            >
              <MoreIcon />
            </IconButton>
          </div>
          <Hidden lgUp>
            <IconButton
              className={classes.iconButton}
              onClick={onSidebarOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default withRouter(Topbar);
