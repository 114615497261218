import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Fab } from "@material-ui/core";
import { SearchFilter } from "components";
import { PersonAddRounded, InfoRounded } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import translate from "i18nProvider/translate";
import DeleteIcon from "@material-ui/icons/DeleteOutline";
import { getUserChurchAccess } from "auth/rulesHandler";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },

  iconBtn: {
    cursor: "pointer",
    color: "#00bfa5",
    backgroundColor: "#ffffff",
    marginRight: 10,
  },
}));

const MembersToolbar = ({
  className,
  onViewMember = () => {},
  onAddBtnClicked = () => {},
  onCreateUserBtnClicked = () => {},
  onDeleteBtnClicked = () => {},
  editMode = false,
  setDataOnSearch = () => {},
  dataToSearch = [],
  keysToSearch = [],
  ...rest
}) => {
  const classes = useStyles();
  const {churchId} = useParams();
  
  const { canRead, canDelete, canWrite } = getUserChurchAccess("CHURCH_MEMBERS", churchId);

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        {editMode && canRead && !canWrite && (
          <Fab
            color="primary"
            title={translate("viewInfo")}
            onClick={onViewMember}
            size="small"
            className={classes.iconBtn}
          >
            <InfoRounded />
          </Fab>
        )}

        {editMode && canWrite && (
          <Fab
            color="primary"
            title={translate("requestUser")}
            onClick={onCreateUserBtnClicked}
            size="small"
            className={classes.iconBtn}
          >
            <PersonAddRounded />
          </Fab>
        )}

        {canWrite && (
          <Fab
            title={
              editMode ? translate("editMember") : translate("addNewMember")
            }
            onClick={onAddBtnClicked}
            color="primary"
            size="small"
            className={classes.iconBtn}
          >
            {editMode && <EditIcon style={{ color: "#FFD600" }} />}
            {!editMode && <AddIcon />}
          </Fab>
        )}

        {editMode && canDelete && (
          <Fab
            title={translate("delete")}
            onClick={onDeleteBtnClicked}
            size="small"
            style={{ color: "#DC004E" }}
            className={classes.iconBtn}
          >
            <DeleteIcon />
          </Fab>
        )}
      </div>
      <div className={classes.row}>
        <SearchFilter
          dataToSearch={dataToSearch}
          setDataOnSearch={setDataOnSearch}
          keysToSearch={keysToSearch}
        />
      </div>
    </div>
  );
};

MembersToolbar.propTypes = {
  className: PropTypes.string,
  onAddBtnClicked: PropTypes.func.isRequired,
  onCreateUserBtnClicked: PropTypes.func.isRequired,
  showCreateUserBtn: PropTypes.bool,
  editMode: PropTypes.bool,
};

export default MembersToolbar;
