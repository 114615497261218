import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import { DynamicTable } from "components";
import { transactionTableFields, transactionTableHeaders } from "helpers";
import TransactionsFilter from "./TransactionsFilter";
import { withRouter } from "react-router-dom";
import { MembersService, ReportsService } from "services";
import { connect } from "react-redux";
import { DateFormater } from "helpers";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 15,
  },
}));

const TransactionsReports = ({ dispatch, members, language }) => {
  const classes = useStyles();

  const {churchId, groupId} = useParams();
  const [transactions, setTransactions] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(event.target.value);
  };

  const getTransactions = React.useCallback(async () => {
    const currentDate = new Date();
    let trans = [];
    try {
      if (groupId) {
        trans = await new ReportsService(churchId).getGroupTransactionsReport(groupId, {
          tranStartDate: DateFormater.format(
            DateFormater.minusDays(currentDate, 30),
            "YYYY-MM-DD"
          ),
          tranEndDate: DateFormater.format(currentDate, "YYYY-MM-DD"),
          transactionType: "",
          paymentMethod: "",
          memberId: "",
          jrxmlFilename: ""
        });
      } else {
        trans = await new ReportsService(churchId).getTransactionsReport({
          tranStartDate: DateFormater.format(
            DateFormater.minusDays(currentDate, 30),
            "YYYY-MM-DD"
          ),
          tranEndDate: DateFormater.format(currentDate, "YYYY-MM-DD"),
          transactionType: "",
          paymentMethod: "",
          memberId: "",
          groupId: "",
          jrxmlFilename: ""
        });
      }
      setTransactions(trans);
    } catch (error) {
      console.error(error);
    }
  }, [churchId, groupId]);

  const getMembers = React.useCallback(() => {
    if (members.length <= 0) {
      if (groupId) {
        dispatch(new MembersService(churchId).getMembersByGroupId(groupId));
      } else {
        dispatch(new MembersService(churchId).getMembersByChurchId());
      }
    }
  }, [dispatch, churchId, members]);

  React.useEffect(() => {
    getTransactions();
    getMembers();
  }, [getTransactions, getMembers]);

  return (
    <Grid container justifyContent="center">
      <TransactionsFilter
        members={members}
        setTransactions={setTransactions}
        churchId={churchId}
        groupId={groupId}
        language={language}
      />
      <Grid item xs={12} className={classes.tableContainer}>
        <DynamicTable
          headers={transactionTableHeaders}
          fields={transactionTableFields}
          data={transactions}
          count={transactions.length}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  members: state.members,
  language: state.language,
});

TransactionsReports.propTypes = {};

export default withRouter(connect(mapStateToProps)(TransactionsReports));
