import React from "react";
import { makeStyles } from "@material-ui/styles";
import { Tabs, Tab } from "@material-ui/core";
import PropTypes from "prop-types";
import { RegisteredUsers, RequestedUsers } from "./components";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import HowToRegIcon from "@material-ui/icons/HowToReg";
import translate from "i18nProvider/translate";
import { injectIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  tab: {
    flexGrow: 1,
    fontSize: 12,
    minHeight: "68px !important",
  },
  tabPanel: {
    padding: "10px 16px",
    display: "flex",
    // justifyContent: 'center',
  },
  tabs: {
    background: "white",
    width: "100%",
    paddingTop: "10px",
  },
}));

const UsersView = injectIntl(({ match }) => {
  const CHURCH_ID = match.params.churchId;

  const classes = useStyles();
  const [view, setView] = React.useState(0);

  const handleChange = (event, newVal) => {
    setView(newVal);
  };

  return (
    <div>
      <Tabs
        value={view}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        className={classes.tabs}
      >
        <Tab
          label={translate("registeredUsers")}
          icon={<HowToRegIcon />}
          className={classes.tab}
        />
        <Tab
          label={translate("requestedUsers")}
          icon={<GroupAddIcon />}
          className={classes.tab}
        />
      </Tabs>

      <div className={classes.tabPanel}>
        <TabPanel view={view} churchId={CHURCH_ID} />
      </div>
    </div>
  );
});

function TabPanel({ view, churchId }) {
  switch (view) {
    case 0:
      return <RegisteredUsers churchId={churchId} />;
    case 1:
      return <RequestedUsers churchId={churchId} />;
    default:
      return "";
  }
}

UsersView.propTypes = {
  className: PropTypes.string,
};

export default UsersView;
