import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Box,
} from "@material-ui/core";

const DynamicCard = (props) => {
  const {
    headerAction,
    headerTitle = "",
    subheader = "",
    classNameHeader,
    classNameCard,
    classNameCardContent,
    cardActionArea,
    children,
  } = props;

  return (
    <Card className={classNameCard}>
      <CardHeader
        action={headerAction}
        title={headerTitle}
        subheader={subheader}
        className={classNameHeader}
      />
      <CardContent className={classNameCardContent}>{children}</CardContent>

      <CardActions>{cardActionArea}</CardActions>
    </Card>
  );
};

DynamicCard.propTypes = {
  headerTitle: PropTypes.string,
  subheader: PropTypes.string,
  headerAction: PropTypes.any,
  cardActionArea: PropTypes.any,
  classNameCard: PropTypes.string,
  classNameCardContent: PropTypes.string,
  classNameHeader: PropTypes.string,
  cardMediaProps: PropTypes.any,
};

export default DynamicCard;
