/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { List, ListItem, Button, colors } from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  MonetizationOn as MoneyIcon,
  EventNote as EventNoteIcon,
  Equalizer as EqualizerIcon,
  People as PeopleIcon,
  Accessibility as AccessibilityIcon
} from "@material-ui/icons";
import translate from "i18nProvider/translate";

const useStyles = makeStyles((theme) => ({
  root: {},
  rootItem: {
    display: "inline-block",
    width: "100%",
    textAlign: "left",
  },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    justifyContent: "space-between",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  truncateText: {
    display: "flex",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main,
    },
    backgroundColor: "#BDBDBD",
    "& div.label": {
      color: theme.palette.primary.main,
    },
  },
  nested: {
    paddingLeft: theme.spacing(3),
    paddingTop: 3,
    paddingBottom: 3,
  },
  nestedButton: {
    display: "flex",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium,
    color: colors.blueGrey[700],
  },
}));

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

const SidebarNavGroups = (props) => {
  const { groupAccess, className, onClose, variant, ...rest } = props;

  const classes = useStyles();

  const [currentMenu, setCurrentMenu] = React.useState("");

  const handleClick = (menu) => {
    setCurrentMenu(menu === currentMenu ? "" : menu);
  };

  const handleSubMenuClick = () => {
    if (variant === "temporary") {
      onClose();
    }
  };

  const authorize = (roles, moduleName) =>
    roles.some((role) => role.moduleName === moduleName);

  const groupOptions = (churchId, groupId, roles) => {
    const options = [];

    if (authorize(roles, "GROUP_MEMBERS")) {
      options.push({
        label: translate("members"),
        ref: `/churches/${churchId}/groups/${groupId}/view-members`,
        icon: <PeopleIcon />,
      });
    }

    if (authorize(roles, "GROUP_PROGRAMS")) {
      options.push({
        label: translate("programs"),
        ref: `/churches/${churchId}/groups/${groupId}/programs`,
        icon: <EventNoteIcon />,
      });
    }

    if (authorize(roles, "GROUP_TRANSACTIONS")) {
      options.push({
        label: translate("transactions"),
        ref: `/churches/${churchId}/groups/${groupId}/transactions`,
        icon: <MoneyIcon />,
      });
    }

    if (authorize(roles, "GROUP_REPORTS")) {
      options.push({
        label: translate("reports"),
        ref: `/churches/${churchId}/groups/${groupId}/reports`,
        icon: <EqualizerIcon />,
      });
    }

    if (authorize(roles, "GROUP_LEADERS")) {
      options.push({
        label: translate("leaders"),
        ref: `/churches/${churchId}/groups/${groupId}/leaders`,
        icon: <AccessibilityIcon />,
      });
    }

    return options;
  };

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {groupAccess.map((access, i) => {
        const { group, roles } = access;
        const open = currentMenu === group.name || groupAccess.length === 1;
        return (
          <div key={i}>
            <ListItem className={classes.item} disableGutters>
              <Button
                //activeClassName={classes.active}
                className={classes.button}
                onClick={() => handleClick(group.name)}
                title={group.name}
              >
                <div className={classes.rootItem}>
                  <div className={classes.truncateText}>
                    <span style={{ marginRight: "10px" }}>{group.icon}</span>
                    {group.name}
                  </div>
                </div>
                {open ? <ExpandLess /> : <ExpandMore />}
              </Button>
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {groupOptions(group.churchId, group.id, roles).map(
                  (option, i) => (
                    <ListItem className={classes.nested} key={i}>
                      <Button
                        onClick={handleSubMenuClick}
                        //activeClassName={classes.active}
                        className={classes.nestedButton}
                        component={CustomRouterLink}
                        to={option.ref}
                      >
                        <div className={classes.icon}>{option.icon}</div>
                        <div className="label">{option.label}</div>
                      </Button>
                    </ListItem>
                  )
                )}
              </List>
            </Collapse>
          </div>
        );
      })}
    </List>
  );
};

SidebarNavGroups.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired,
};

export default SidebarNavGroups;
