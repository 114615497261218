import axios from "axios";
import { showAlertError, showAlertWarning } from "../redux/slices/alertsSlice";
import { showLoading, hideLoading } from "../redux/slices/loadingSlice";
import store from "../redux/store";
import translate from "i18nProvider/translate";
import { removeToken } from "auth/tokenHandler";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export function makeCallFunction(axiosInstance) {
  return async function (method = "get", url, payload, config) {
    store.dispatch(showLoading());
    const res = await axiosInstance({
      method,
      url: url,
      data: payload,
      ...config,
    });

    store.dispatch(hideLoading());
    return res && res.data;
  };
}

export const call = makeCallFunction(request);

export function setAuthorizationToken(token) {
  if (token) {
    request.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete request.defaults.headers.common["Authorization"];
  }
}

function dispatchAlertError(errorStr) {
  store.dispatch(showAlertError(translate(errorStr)));
}

function dispatchAlertWarning(errorStr) {
  store.dispatch(showAlertWarning(translate(errorStr)));
}

request.interceptors.response.use(
  function (response) {
    return response;
  },
  function (e) {
    if (e.response.status === 403) {
      //eslint-disable-next-line
      location.href = `/not-found`;
      return;
    }

    const { error } = e.response.data;

    if (error) {
      const { message } = error;
      if (message === "invalid_token" || message === "token_expired") {
        removeToken();
        //eslint-disable-next-line
        location.href = `/`;
      } else {
        dispatchAlertError("theActionCouldNotBeComplete");
      }
    } else {
      dispatchAlertError("theActionCouldNotBeComplete");
    }
  }
);
