import React, { useState, useMemo, useCallback } from "react";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Box,
  Divider,
  Card,
} from "@material-ui/core";

import ChurchForm from "./ChurchForm";
import UserForm from "./UserForm";
import translate from "i18nProvider/translate";

import { User, Church } from "models";
import SignUpService from "services/SignUpService";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "94%",
    maxWidth: "600px",
    margin: "20px auto",
    backgroundColor: "#ffffff",
    paddingBottom: "20px",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    // width: "200px",
    width: "100%",
    // marginLeft: "auto",
    display: 'flex',
    justifyContent: 'center'
  },
}));

const COMPLETE_PERSONAL_INFO = "completePersonalInfo";
const COMPLETE_CHURCH_INFO = "completeChurchInfo";
// const COMPLETE_PAYMENT_INFO = "completePaymentMethod";

const EmailMessage = ({msg}) => {
  return (
    <Card style={{ height: '100%', padding: 50, textAlign: 'center' }} >
      <Alert severity="success">{translate(msg)}</Alert>
    </Card>
  );
};

export default function HorizontalLabelPositionBelowStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [churchInfo, setChurchInfo] = useState(new Church());
  const [userInfo, setUserInfo] = useState(new User());
  const [emailMsg, setEmailMsg] = useState(false);
  // const [paymentInfo, setPaymentInfo] = useState(null);

  const steps = useMemo(() => [
    COMPLETE_PERSONAL_INFO
  ], []);

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleSubmit = useCallback(formData => {
    const currStep = steps[activeStep];

    if (currStep === COMPLETE_PERSONAL_INFO) {
      setUserInfo(formData);
    }
    else if (currStep === COMPLETE_CHURCH_INFO) {
      setChurchInfo(formData);
    }

    if(activeStep === steps.length - 1) {
      // const body = JSON.stringify({ user: userInfo, church: churchInfo });
      new SignUpService().signUp(formData)
      .then(resp => {
        if(resp.status === 208) {
          setEmailMsg('emailAlreadyHasUserRequest');
        } else {
          setEmailMsg('confirmationLinkSent');
        }
      })
      .catch(console.log);
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, [activeStep, steps]);

  const renderContent = useCallback(() => {
    const currStep = steps[activeStep];

    const buttonsSection = (
      <Box className={classes.buttons}>
        <Button variant="contained" color="primary" type="submit">
          {activeStep === steps.length - 1 ? translate("submit") : translate("next")}
        </Button>
      </Box>
    );

    if (currStep === COMPLETE_PERSONAL_INFO) {
      return <UserForm initialValues={userInfo} buttonsSection={buttonsSection} handleNext={handleSubmit} />;
    }
    else if (currStep === COMPLETE_CHURCH_INFO) {
      return <ChurchForm initialValues={churchInfo} buttonsSection={buttonsSection} handleNext={handleSubmit} />;
    }
    // else if (currStep === COMPLETE_PAYMENT_INFO) {
    //   return <PaymentForm paymentInfo={paymentInfo} setPaymentInfo={setPaymentInfo} />;
    // }

    return "Unknown stepIndex";
  }, [activeStep]);

  return emailMsg ? <EmailMessage msg={emailMsg} /> : (
    <Card className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, idx) => (
          <Step key={`step-${idx}`}>
            <StepLabel>{translate(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box>
        {activeStep === steps.length ? (
          <Box>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        ) : (
          <Box sx={{ padding: 20 }}>
            <Divider />
            {renderContent()}
          </Box>
        )}
      </Box>
    </Card>
  );
}
