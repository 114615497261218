import moment from "moment";
import store from "redux/store";
import { LOCALES } from "i18nProvider";

class DateFormater {
  static isEnglish = () => {
    return store.getState().language === LOCALES.ENGLISH;
  };

  static format = (date, format) => {
    return moment(date).format(format);
  };

  static formatDateAsDDMMYYYY = (date) => {
    return DateFormater.format(date, "DD-MM-YYYY");
  };

  static formatDateAsMMDDYYYY = (date) => {
    return DateFormater.format(date, "MM-DD-YYYY");
  };

  static formatDateAsDDMMYYYYhhmm = (date) => {
    const format = DateFormater.isEnglish()
      ? "MM-DD-YYYY hh:mm"
      : "DD-MM-YYYY hh:mm";
    return DateFormater.format(date, format);
  };

  static fromNow = (date) => {
    return moment(date).fromNow();
  };

  static minusDays = (date, days) => {
    return moment(date).subtract(days, 'days');
  };

  // for date pickers
  static getCurrentLanguagetFormat() {
    return DateFormater.isEnglish() ? "MM/dd/yyyy" : "dd/MM/yyyy";
  }
}

export default DateFormater;
