import React, { useState } from "react";
import {
  IconButton,
  Popover,
  Button,
  makeStyles,
  Box,
} from "@material-ui/core";
import { Tune, CloudDownload } from "@material-ui/icons";
import translate from "i18nProvider/translate";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: 15,
  },
  filterIcon: {
    fontSize: 32,
  },
  popover: {
    "& > div": {
      display: "flex",
      justifyContent: "center",
      padding: 12,
    },
  },
  buttonClear: {
    marginRight: "7px",
    background: "#E0E0E0",
  },
}));

const ReportsFilter = ({
  onFilterBtnClick = () => {},
  children,
  onClearBtnClick = () => {},
  onPrintPdfBtnClick = () => {},
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleApplyFilterClick = () => {
    onFilterBtnClick();
    setAnchorEl(null);
  };

  const handleClickClear = () => {
    onClearBtnClick();
    setAnchorEl(null);
  };

  return (
    <Box style={{ marginLeft: "auto" }}>
      <Box>
        <IconButton
          title={translate("downloadData")}
          onClick={onPrintPdfBtnClick}
        >
          <CloudDownload className={classes.filterIcon} />
        </IconButton>
        <IconButton
          title={translate("filters")}
          onClick={(event) => setAnchorEl(event.currentTarget)}
        >
          <Tune className={classes.filterIcon} />
        </IconButton>
      </Box>
      <Popover
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        className={classes.popover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box>
          {children}
          <Box mt={5} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              className={classes.buttonClear}
              onClick={handleClickClear}
            >
              {translate("clear")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleApplyFilterClick}
            >
              {translate("applyFilters")}
            </Button>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
};

export default ReportsFilter;
