import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Fab } from "@material-ui/core";

import { SearchFilter } from "components";
import { PersonAddRounded } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import translate from "i18nProvider/translate";
import DeleteIcon from "@material-ui/icons/DeleteOutline";

const useStyles = makeStyles((theme) => ({
  row: {
    height: "42px",
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  spacer: {
    flexGrow: 1,
  },

  iconBtn: {
    cursor: "pointer",
    color: "#00bfa5",
    backgroundColor: "#ffffff",
    marginRight: 10,
  },
}));

const Toolsbar = ({
  className,
  onAddBtnClicked = () => {},
  onCreateBtnClicked = () => {},
  onDeleteBtnClicked = () => {},
  editMode = false,
  setDataOnSearch = () => {},
  dataToSearch = [],
  keysToSearch = [],
  ...rest
}) => {
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />

        <Fab
          title={editMode ? translate("editRole") : translate("addRole")}
          onClick={onAddBtnClicked}
          color="primary"
          size="small"
          className={classes.iconBtn}
        >
          {editMode ? <EditIcon style={{ color: "#FFD600" }} /> : <AddIcon />}
        </Fab>

        {editMode && (
          <Fab
            color="primary"
            title={translate("delete")}
            onClick={onDeleteBtnClicked}
            size="small"
            style={{ color: "#DC004E" }}
            className={classes.iconBtn}
          >
            <DeleteIcon />
          </Fab>
        )}
      </div>
      <div className={classes.row}>
        <SearchFilter
          dataToSearch={dataToSearch}
          setDataOnSearch={setDataOnSearch}
          keysToSearch={keysToSearch}
        />
      </div>
    </div>
  );
};

Toolsbar.propTypes = {
  className: PropTypes.string,
  onAddBtnClicked: PropTypes.func.isRequired,
  onCreateBtnClicked: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
};

export default Toolsbar;
