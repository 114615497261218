import React, { useState } from "react";
import {
  ReportsFilter,
  Select,
  MuiPickersUtils,
  CustomAutocomplete,
} from "components";
import { KeyboardDatePicker } from "@material-ui/pickers";
import store from "redux/store";
import PropTypes from "prop-types";
import { paymentMethods, transactionsTypes, DateFormater } from "helpers";
import translate from "i18nProvider/translate";
import { Grid, Box, makeStyles } from "@material-ui/core";
import { ReportsService } from "services";
import { LOCALES } from "i18nProvider";
import { showLoading, hideLoading } from "redux/slices/loadingSlice";

const useStyles = makeStyles((theme) => ({
  datesBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));

const defaultFilters = {
  tranStartDate: DateFormater.format(
    DateFormater.minusDays(new Date(), 30),
    "YYYY-MM-DD"
  ),
  tranEndDate: DateFormater.format(new Date(), "YYYY-MM-DD"),
  paymentMethod: "",
  transactionType: "",
  jrxmlFilename: "",
  memberId: "",
};

const TransactionsFilter = ({
  members = [],
  setTransactions,
  churchId,
  groupId,
  language,
}) => {
  const classes = useStyles();
  const [filterData, setFilterData] = useState(defaultFilters);
  const [selectedMember, setSelectedMember] = useState(null);

  const onFilterBtnClick = async (filters = null, member = {}) => {
    const selectedM = member ? selectedMember : null;
    try {
      store.dispatch(showLoading());
      let res;
      
      if (groupId) {
        res = await new ReportsService(churchId).getGroupTransactionsReport(groupId, {
          ...filters,
          groupId: groupId ? groupId : "",
          memberId: selectedM ? selectedM.id : "",
        });
      } else {
        res = await new ReportsService(churchId).getTransactionsReport({
          ...filters,
          groupId: groupId ? groupId : "",
          memberId: selectedM ? selectedM.id : "",
        });
      }
      if (Array.isArray(res)) setTransactions(res);
    } catch (error) {
      console.error(error);
    } finally {
      store.dispatch(hideLoading());
    }
  };

  const onPrintPdfBtnClick = async () => {
    try {
      store.dispatch(showLoading());
      let res;

      if (groupId) {
        res = await new ReportsService(churchId).getGroupTransactionsReport(groupId, {
          ...filterData,
          groupId: groupId ? groupId : "",
          memberId: selectedMember ? selectedMember.id : "",
          jrxmlFilename:
            language === LOCALES.ENGLISH
              ? "transactions_english"
              : "transactions_spanish",
        });
      } else {
        res = await new ReportsService(churchId).getTransactionsReport({
          ...filterData,
          memberId: selectedMember ? selectedMember.id : "",
          groupId: groupId ? groupId : "",
          jrxmlFilename:
            language === LOCALES.ENGLISH
              ? "transactions_english"
              : "transactions_spanish",
        });
      }
      const file = new Blob([res], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      console.error(error);
    } finally {
      store.dispatch(hideLoading());
    }
  };

  const onClearBtnClick = () => {
    setFilterData(defaultFilters);
    setSelectedMember(null);
    onFilterBtnClick(defaultFilters, null);
  };

  const handleSelectChange = (e) => {
    setFilterData({
      ...filterData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDates = (date, fieldName) => {
    setFilterData({
      ...filterData,
      [fieldName]: DateFormater.format(date, "YYYY-MM-DD"),
    });
  };

  const isNotEmptySt = (date) => {
    return date !== "" ? date : null;
  };

  return (
    <ReportsFilter
      onFilterBtnClick={() => onFilterBtnClick(filterData)}
      onClearBtnClick={() => onClearBtnClick()}
      onPrintPdfBtnClick={() => onPrintPdfBtnClick()}
    >
      <MuiPickersUtils>
        <Box mt={3} style={{ width: "100%", maxWidth: "500px" }}>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} xs={12}>
              <Select
                label="paymentMethod"
                onChange={handleSelectChange}
                value={filterData.paymentMethod || ""}
                options={paymentMethods}
                name="paymentMethod"
              />
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Select
                label="transactionType"
                onChange={handleSelectChange}
                value={filterData.transactionType || ""}
                options={transactionsTypes}
                name="transactionType"
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Box className={classes.datesBox}>
                <Box>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    label={translate("transactionDate")}
                    format={DateFormater.getCurrentLanguagetFormat()}
                    emptyLabel={translate("startDate")}
                    value={isNotEmptySt(filterData.tranStartDate)}
                    onChange={(date) => handleDates(date, "tranStartDate")}
                  />
                </Box>
                <Box>
                  <KeyboardDatePicker
                    fullWidth
                    margin="normal"
                    label={translate("transactionDate")}
                    emptyLabel={translate("endDate")}
                    format={DateFormater.getCurrentLanguagetFormat()}
                    value={isNotEmptySt(filterData.tranEndDate)}
                    onChange={(date) => handleDates(date, "tranEndDate")}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <CustomAutocomplete
                selectedItem={selectedMember}
                setSelectedItem={setSelectedMember}
                options={members}
                label="member"
                name="member"
              />
            </Grid>
          </Grid>
        </Box>
      </MuiPickersUtils>
    </ReportsFilter>
  );
};

TransactionsFilter.propTypes = {
  members: PropTypes.array.isRequired,
  churchId: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
};

export default TransactionsFilter;
