import { call } from "../tools/api";

class SignUpService {
    checkRequest = async (reqId) => {
        const path = `/signup/check-request?requestId=${reqId}`;
        return await call("get", path);
    };

    signUp = async (user) => {
        const path = `/signup`;
        const headers = {
            'Content-Type': 'application/json'
        };
        return await call("post", path, user, { headers });
    };

    registerUser = async (user) => {
        const path = `/signup/users/register`;
        return await call("post", path, user);
    };
}

export default SignUpService;