import React from "react";
import { ReportsFilter, MuiPickersUtils, CustomAutocomplete } from "components";
import { Box, makeStyles } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";
import translate from "i18nProvider/translate";
import { ReportsService } from "services";
import store from "redux/store";
import { LOCALES } from "i18nProvider";
import { DateFormater } from "helpers/";
import { showLoading, hideLoading } from "redux/slices/loadingSlice";

const useStyles = makeStyles((theme) => ({
  datesBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
}));

const startDefaultDate = DateFormater.format(
  DateFormater.minusDays(new Date(), 30),
  "YYYY-MM-DD"
);
const untilDefaultDate = DateFormater.format(new Date(), "YYYY-MM-DD");

const filters = {
  programStartDate: startDefaultDate,
  programEndDate: untilDefaultDate,
};

const GroupsFilter = ({ churchId, setFilteredPrograms, groupId }) => {
  const [filterData, setFilterData] = React.useState(filters);
  const [selectedProgram, setSelectedProgram] = React.useState(null);
  const [programs, setPrograms] = React.useState([]);

  const isNotEmptySt = (date) => {
    return date !== "" ? date : null;
  };

  const handleDates = (date, fieldName) => {
    setFilterData({
      ...filterData,
      [fieldName]: DateFormater.format(date, "YYYY-MM-DD"),
    });
  };

  const onFilter = async (clear = false, print = null) => {
    const datesFilter = clear ? filters : filterData;
    try {
      store.dispatch(showLoading());
      let pg;
      if (groupId) {
        pg = await new ReportsService(churchId).getGroupProgramsReport(groupId, {
          ...datesFilter,
          programId:
            (selectedProgram !== null) & (clear === false)
              ? selectedProgram.id
              : "",
          jrxmlFilename: print ? print : "",
        });
      } else {
        pg = await new ReportsService(churchId).getProgramsReport({
          ...datesFilter,
          programId:
            (selectedProgram !== null) & (clear === false)
              ? selectedProgram.id
              : "",
          jrxmlFilename: print ? print : "",
          groupId: groupId ? groupId : "",
        });
      }

      if (Array.isArray(pg)) {
        setFilteredPrograms(pg);
      } else {
        const file = new Blob([pg], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    } catch (err) {
      console.error(err);
    } finally {
      store.dispatch(hideLoading());
    }
  };

  const onClearBtnClick = () => {
    setFilterData({ ...filters });
    setSelectedProgram(null);
    onFilter(true);
  };

  const onPrintPdfBtnClick = () => {
    onFilter(
      false,
      store.getState().language === LOCALES.ENGLISH
        ? "programs_english"
        : "programs_spanish"
    );
  };

  const classes = useStyles();
  return (
    <MuiPickersUtils>
      <ReportsFilter
        onFilterBtnClick={onFilter}
        onClearBtnClick={onClearBtnClick}
        onPrintPdfBtnClick={onPrintPdfBtnClick}
      >
        <CustomAutocomplete
          selectedItem={selectedProgram}
          setSelectedItem={setSelectedProgram}
          options={programs}
          label="programName"
          name="name"
          optionLabel="name"
        />

        <Box className={classes.datesBox}>
          <Box mr={2}>
            <KeyboardDatePicker
              fullWidth
              margin="normal"
              label={translate("programDate")}
              format={DateFormater.getCurrentLanguagetFormat()}
              emptyLabel={translate("startDate")}
              value={isNotEmptySt(filterData.programStartDate)}
              onChange={(date) => handleDates(date, "programStartDate")}
            />
          </Box>
          <Box>
            <KeyboardDatePicker
              fullWidth
              margin="normal"
              label={translate("programDate")}
              emptyLabel={translate("endDate")}
              format={DateFormater.getCurrentLanguagetFormat()}
              value={isNotEmptySt(filterData.programEndDate)}
              onChange={(date) => handleDates(date, "programEndDate")}
            />
          </Box>
        </Box>
      </ReportsFilter>
    </MuiPickersUtils>
  );
};

export default GroupsFilter;
