import { call } from "../tools/api";
import {
  addGroups,
  addGroup,
  updateGroup,
  deleteGroup,
} from "redux/slices/groupsSlice";
import { showAlertSuccess } from "../redux/slices/alertsSlice";
import translate from "i18nProvider/translate";

/**
 * @class
 */
class GroupsService {
  constructor(churchId) {
    this.basePath = `/churches/${churchId}/groups`;
  }

  getGroupsByChurch = () => async (dispatch) => {
    try {
      const res = await call("get", this.basePath);
      dispatch(addGroups(res));
    } catch (error) {
      console.log(error);
    }
  };

  getGroupLeaders = async (churchId) => {
    const path = `churches/${churchId}/users/group-leaders`;
    return await call("get", path);
  };

  getMembersNotInGroup = async (groupId) => {
    return await call("get", `${this.basePath}/${groupId}/members-not-in`);
  };

  getMembersByGroup = async (groupId) => {
    return await call("get", `${this.basePath}/${groupId}/members`);
  };

  addMembers = (groupId, membersIds) => async (dispatch) => {
    try {
      await call("post", `${this.basePath}/${groupId}/members`, membersIds);
      dispatch(showAlertSuccess(translate("memberSuccessfullyAdded")));
    } catch (error) {
      console.log(error);
    }
  };

  deleteMembers = async (groupId, membersIds) => {
    return await call(
      "delete",
      `${this.basePath}/${groupId}/members`,
      membersIds
    );
  };

  create = (group) => async (dispatch) => {
    try {
      const res = await call("post", this.basePath, group);
      dispatch(addGroup(res));
      dispatch(showAlertSuccess(translate("groupSuccessfullyCreated")));
    } catch (error) {
      console.log(error);
    }
  };

  update = (group) => async (dispatch) => {
    try {
      const res = await call("put", `${this.basePath}/${group.id}`, group);
      dispatch(updateGroup(res));
      dispatch(showAlertSuccess(translate("groupSuccessfullyEdited")));
    } catch (error) {
      console.log(error);
    }
  };

  deleteGroupById = (groupId) => async (dispatch) => {
    try {
      await call("delete", `${this.basePath}/${groupId}`);
      dispatch(deleteGroup(groupId));
      dispatch(showAlertSuccess(translate("groupSuccessfullyDeleted")));
    } catch (error) {
      console.log(error);
    }
  };
}

export default GroupsService;
