import { createSlice } from "@reduxjs/toolkit";

const membersSlice = createSlice({
  name: "members",
  initialState: [],
  reducers: {
    getMembers(state, action) {
      return action.payload;
    },
    addMember(state, action) {
      state.push(action.payload);
    },
    updateMember(state, action) {
      const index = state.findIndex(
        (member) => member.id === action.payload.id
      );
      if (index > -1) {
        state[index] = action.payload;
      }
    },
    deleteMember(state, action) {
      const index = state.findIndex((member) => member.id === action.payload);
      if (index > -1) {
        state = state.splice(index, 1);
      }
    },
  },
});

export const {
  getMembers,
  addMember,
  updateMember,
  deleteMember,
} = membersSlice.actions;

export default membersSlice.reducer;
