import React from "react";

import { IconButton, MenuItem, Menu } from "@material-ui/core";
import store from "redux/store";
import { setLanguage } from "redux/slices/languageSlice";
import { LOCALES } from "i18nProvider";
import TranslateIcon from "@material-ui/icons/Translate";
import { lSlanguageName } from "config/config";

export default function LanguagesMenu({callback}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickChageLanguage = (language) => {
    store.dispatch(setLanguage(language));
    localStorage.setItem(lSlanguageName, language);
    setAnchorEl(null);
    if(callback) {
      callback();
    }
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <TranslateIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleClickChageLanguage(LOCALES.SPANISH)}>
          Spanish
        </MenuItem>
        <MenuItem onClick={() => handleClickChageLanguage(LOCALES.ENGLISH)}>
          English
        </MenuItem>
      </Menu>
    </div>
  );
}
