import { call } from "../tools/api";
/**
 * @class
 */
class TransactionsService {
  constructor(churchId) {
    this.churchId = churchId;
    this.basePath = `/churches/${this.churchId}`;
  }

  getTransactions = async () => {
    return await call("get", `/churches/${this.churchId}/transactions`);
  };

  createTransaction = async (transaction) => {
    const { groupId } = transaction;
    const groupPath = `/groups/${groupId}`;

    return await call("post", `${this.basePath}${groupId ? groupPath : ''}/transactions`, transaction);
  };

  editTransaction = async (transaction) => {
    const { groupId } = transaction;
    const groupPath = `/groups/${groupId}`;

    return await call("put", `${this.basePath}${groupId ? groupPath : ''}/transactions/${transaction.id}`, transaction);
  };

  getGroupTransactions = async (groupId) => {
    return await call(
      "get",
      `/churches/${this.churchId}/groups/${groupId}/transactions`
    );
  };
}

export default TransactionsService;
