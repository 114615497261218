import React from "react";
import { Box, Typography } from "@material-ui/core";
import translate from "i18nProvider/translate";

const ConfirmContentToDelete = ({ detail }) => {
  const { programPartId, memberId } = detail;
  return (
    <Box mt={3}>
      <Box>
        <Typography>
          {translate("programPart")} {`: ${programPartId.name}`}{" "}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography>
          {translate("member")} {`: ${memberId.name}`}{" "}
        </Typography>
      </Box>
    </Box>
  );
};

export default ConfirmContentToDelete;
