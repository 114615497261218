import React, { useState } from "react";
import { SearchInput } from "components";
import translate from "i18nProvider/translate";

const SearchFilter = ({
  setDataOnSearch = () => {},
  dataToSearch = [],
  keysToSearch = [],
}) => {
  const [searchValue, setSearchValue] = useState("");

  const handleSearch = (event) => {
    const auxDataSearch = [...dataToSearch];
    let text = event.target.value;
    text = text.toLowerCase();
    const filtered = auxDataSearch.filter(function (o) {
      return keysToSearch.some(function (k) {
        if (o[k]) {
          return o[k].toString().toLowerCase().indexOf(text) !== -1;
        }
        return false;
      });
    });

    setDataOnSearch(filtered);
    setSearchValue(event.target.value);
  };

  return (
    <SearchInput
      placeholder={translate("search")}
      value={searchValue}
      onChange={handleSearch}
    />
  );
};

export default SearchFilter;
